import GET_END_OF_LIFE_CATEGORIES_WITH_ITEM_COUNT_BY_USER_ID from '@/graphql/queries/GetEndOfLifeCategoriesWithItemCountByUserId';
import GET_END_OF_LIFE_ITEMS_BY_CATEGORY_AND_USER_ID from '@/graphql/queries/GetEndOfLifeItemsByCategoryIdAndUserId';
import DELETE_END_OF_LIFE_ITEM from '@/graphql/mutations/DeleteEndOfLifeItem';

async function revealEndOfLifeItem(endOfLifeItem, dataLayout) {
  return await window.$nuxt.$sensitive.EndOfLifeItem[dataLayout].reveal(
    endOfLifeItem.id,
    endOfLifeItem
  );
}

export const state = () => ({
  endOfLifeItemsByCategory: {},
  endOfLifeCategoryItemCounts: {},
});

export const getters = {
  endOfLifeItemsByCategory: (state) => state.endOfLifeItemsByCategory,
  endOfLifeCategoryItemCounts: (state) => state.endOfLifeCategoryItemCounts,
};

export const mutations = {
  SET_END_OF_LIFE_ITEMS(state, data) {
    state.endOfLifeItemsByCategory = data;
  },
  SET_END_OF_LIFE_CATEGORY_ITEM_COUNTS(state, data) {
    state.endOfLifeCategoryItemCounts = data;
  },
};

export const actions = {
  async getEndOfLifeCategoryItemCounts({ rootState, commit }) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_END_OF_LIFE_CATEGORIES_WITH_ITEM_COUNT_BY_USER_ID,
      variables: {
        userId: rootState.userId,
      },
    });
    const endOfLifeCategoryItemCounts =
      data.getEndOfLifeCategoriesWithItemCountByUserId.endOfLifeCategories.reduce(
        (acc, category) => ({
          ...acc,
          [category.id]: category.count,
        }),
        {}
      );
    commit('SET_END_OF_LIFE_CATEGORY_ITEM_COUNTS', endOfLifeCategoryItemCounts);
  },
  increaseEndOfLifeCategoryItemCount({ commit, state }, categoryId) {
    const endOfLifeCategoryCounts = { ...state.endOfLifeCategoryCounts };
    endOfLifeCategoryCounts[categoryId]++;
    commit('SET_END_OF_LIFE_CATEGORY_ITEM_COUNTS', endOfLifeCategoryCounts);
  },
  decreaseEndOfLifeCategoryItemCount({ commit, state }, categoryId) {
    const endOfLifeCategoryCounts = { ...state.endOfLifeCategoryCounts };
    endOfLifeCategoryCounts[categoryId]--;
    commit('SET_END_OF_LIFE_CATEGORY_ITEM_COUNTS', endOfLifeCategoryCounts);
  },
  async getEndOfLifeItemsByCategoryId(
    { commit, state, rootState },
    { categoryId, dataLayout }
  ) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_END_OF_LIFE_ITEMS_BY_CATEGORY_AND_USER_ID,
      fetchPolicy: 'no-cache',
      variables: {
        categoryId,
        userId: rootState.userId,
      },
    });
    const endOfLifeItems = {
      ...state.endOfLifeItemsByCategory,
      [categoryId]: await Promise.all(
        data.getEndOfLifeItemsByCategoryIdAndUserId?.endOfLifeItems.map(
          (item) => revealEndOfLifeItem(item, dataLayout)
        )
      ),
    };
    commit('SET_END_OF_LIFE_ITEMS', endOfLifeItems);
  },
  async createEndOfLifeItemWithSensitiveData(
    { commit, dispatch, state },
    { userId, itemData, categoryId, dataLayout, fileIds }
  ) {
    const { $sensitive } = window.$nuxt;
    const endOfLifeItem = await $sensitive.EndOfLifeItem[dataLayout].create(
      userId,
      {
        userId,
        data: itemData,
        foreignCollection: 'end_of_life_item',
        categoryId,
        fileIds,
      }
    );
    const endOfLifeItems = {
      ...state.endOfLifeItemsByCategory,
      [categoryId]: [
        ...state.endOfLifeItemsByCategory[categoryId],
        await revealEndOfLifeItem(endOfLifeItem, dataLayout),
      ],
    };
    commit('SET_END_OF_LIFE_ITEMS', endOfLifeItems);
    dispatch('increaseEndOfLifeCategoryItemCount', categoryId);
  },
  async updateEndOfLifeItemWithSensitiveData(
    { commit, state },
    { userId, itemId, categoryId, dataLayout, itemData, fileIds }
  ) {
    const { $sensitive } = window.$nuxt;
    const updatedEndOfLifeItem = await $sensitive.EndOfLifeItem[
      dataLayout
    ].update(userId, itemId, {
      id: itemId,
      categoryId,
      userId,
      data: itemData,
      fileIds,
    });
    const updatedEndOfLifeItemIndex = state.endOfLifeItemsByCategory[
      categoryId
    ].findIndex((item) => item.id === itemId);

    const endOfLifeItems = {
      ...state.endOfLifeItemsByCategory,
      [categoryId]: [
        ...state.endOfLifeItemsByCategory[categoryId].slice(
          0,
          updatedEndOfLifeItemIndex
        ),
        await revealEndOfLifeItem(updatedEndOfLifeItem, dataLayout),
        ...state.endOfLifeItemsByCategory[categoryId].slice(
          updatedEndOfLifeItemIndex + 1
        ),
      ],
    };
    commit('SET_END_OF_LIFE_ITEMS', endOfLifeItems);
  },
  async deleteEndOfLifeItem(
    { commit, state, dispatch },
    { categoryId, itemId }
  ) {
    await this.app.apolloProvider.defaultClient.mutate({
      mutation: DELETE_END_OF_LIFE_ITEM,
      variables: {
        id: itemId,
      },
    });

    const deletedEndOfLifeItemIndex = state.endOfLifeItemsByCategory[
      categoryId
    ].findIndex((item) => item.id === itemId);
    const endOfLifeItems = {
      ...state.endOfLifeItemsByCategory,
      [categoryId]: [
        ...state.endOfLifeItemsByCategory[categoryId].slice(
          0,
          deletedEndOfLifeItemIndex
        ),
        ...state.endOfLifeItemsByCategory[categoryId].slice(
          deletedEndOfLifeItemIndex + 1
        ),
      ],
    };
    dispatch('decreaseEndOfLifeCategoryItemCount', categoryId);
    commit('SET_END_OF_LIFE_ITEMS', endOfLifeItems);
  },
};
