import GET_INVITES_QUERY from '@/graphql/queries/GetInvites';
import GET_PUBLIC_INVITE_QUERY from '@/graphql/queries/GetPublicInvite';
import GET_ACTIVE_INVITES_FOR_RECIPIENT from '@/graphql/queries/GetActiveInvitesForRecipient';

export default {
  async getPublicInviteCode({ state, commit, rootState }, hasFirstName) {
    if (!state.publicInviteCode && hasFirstName) {
      const {
        data: {
          getPublicInvite: { code },
        },
      } = await this.app.apolloProvider.defaultClient.query({
        query: GET_PUBLIC_INVITE_QUERY,
        variables: {
          userId: rootState.userId,
        },
      });
      commit('setPublicInviteCode', code || null);
    }
  },
  async getActiveInvitesForRecipient({ commit, rootGetters }) {
    if (!rootGetters.userId) {
      commit('setActiveInvites', []);
      return [];
    }
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_ACTIVE_INVITES_FOR_RECIPIENT,
      variables: {
        userId: rootGetters.userId,
      },
    });
    const activeInvites = data?.getActiveInvitesForRecipient ?? [];
    commit('setActiveInvites', activeInvites);
    return activeInvites;
  },
  async getInvites({ commit, rootGetters }) {
    if (!rootGetters.userId) {
      commit('setInvites', []);
      return [];
    }
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_INVITES_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        userId: rootGetters.userId,
      },
    });
    const invites = data?.getInvites ?? [];
    commit('setInvites', invites);
    return invites;
  },
};
