import gql from 'graphql-tag';
import CartProduct from './CartProduct';

export default gql`
  fragment CartItem on CartItem {
    id
    ownerId
    product {
      ...CartProduct
    }
    createdAt
  }
  ${CartProduct}
`;
