import gql from 'graphql-tag';

export default gql`
  fragment AccountInterest on AccountInterest {
    id
    userId
    leadIdentifier {
      country
      region
      brand
    }
    product
    createdAt
  }
`;
