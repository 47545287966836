import gql from 'graphql-tag';

export default gql`
  mutation setPrivacyVaultTarget(
    $resourceId: ID!
    $resourceCollection: ResourceTargetCollection!
    $targetId: ID!
    $targetCollection: PrivacyVaultTargetCollection!
    $userId: ID
  ) {
    setPrivacyVaultTarget(
      privacyVaultAssignPayload: {
        resourceTarget: {
          targetId: $resourceId
          targetCollection: $resourceCollection
        }
        privacyVaultTarget: {
          targetId: $targetId
          targetCollection: $targetCollection
        }
      }
      userId: $userId
    )
  }
`;
