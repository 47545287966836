import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';

export default function ({ redirect }) {
  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        if (error.message === 'Not Authorised!') {
          redirect('/log-out');
        }
      });
    }
  });

  const routeLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        route: window.location.pathname.replace(
          /[\da-f]{8}\b-[\da-f]{4}\b-[\da-f]{4}\b-[\da-f]{4}\b-[\da-f]{12}/i,
          ':id:'
        ),
      },
    }));
    return forward(operation);
  });

  return {
    httpEndpoint: process.env.APOLLO_HTTP_ENDPOINT,
    cache: new InMemoryCache(),
    link: ApolloLink.from([routeLink, errorLink]),
    httpLinkOptions: {
      credentials: 'include',
    },
  };
}
