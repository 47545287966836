import { metaArrayToObject } from '../utilities';

import GET_BENEFICIARIES_QUERY from '~/graphql/queries/GetBeneficiaries';
import GET_GIFTS_QUERY from '~/graphql/queries/GetGifts';
import GET_WILL_QUERY from '~/graphql/queries/GetWill';

async function getPreference(client, willId) {
  const will = await client.query({
    query: GET_WILL_QUERY,
    fetchPolicy: 'no-cache',
    variables: {
      id: willId,
    },
  });
  const meta = will?.data?.getWill?.will?.meta;
  return metaArrayToObject(meta).notify_charities;
}

async function hasCharitiesInEstate(client, willId) {
  const {
    data: { getBeneficiaries },
  } = await client.query({
    query: GET_BENEFICIARIES_QUERY,
    fetchPolicy: 'no-cache',
    variables: {
      willId,
    },
  });

  return getBeneficiaries?.some(
    (beneficiary) =>
      beneficiary.charity !== null ||
      beneficiary.backup?.some((backup) => backup.charity !== null)
  );
}

async function hasCharitiesInGifts(client, willId) {
  const {
    data: { getGifts: gifts },
  } = await client.query({
    query: GET_GIFTS_QUERY,
    fetchPolicy: 'no-cache',
    variables: {
      willId,
    },
  });

  return gifts?.some((gift) => gift.charity !== null);
}

function setRedirectablePages(
  userHasSubmittedWill,
  showEstate,
  showGifts,
  userPreference
) {
  const redirectedPages = [];
  if (!userHasSubmittedWill) {
    if (showEstate) {
      redirectedPages.push('estate');
    }
    if (
      (showGifts && !showEstate) ||
      (showGifts && showEstate && userPreference === false)
    ) {
      redirectedPages.push('gifts');
    }
  }
  return redirectedPages;
}

export async function getShouldShowCharityConsent(ctx) {
  const willId = ctx.store?.getters?.willId;
  const isMasquerading = ctx.store?.admin?.masquerade?.getters?.isMasquerading;
  if (!willId || isMasquerading) return false;

  const shouldShowCharityConsent = {
    modal: false,
    page: [],
  };

  const client = ctx.app.apolloProvider.defaultClient;
  const userIsConsumer = ctx.store.getters.role === 'CONSUMER';

  const checkData = !!client && userIsConsumer;
  if (!checkData) {
    return false;
  }

  const userPreference = await getPreference(client, willId);
  const showEstate = await hasCharitiesInEstate(client, willId);
  const showGifts = await hasCharitiesInGifts(client, willId);

  if (showEstate || showGifts) {
    const userHasSubmittedWill = !['IN_PROGRESS', 'ARCHIVED'].includes(
      ctx.store.getters.willStatus
    );
    shouldShowCharityConsent.modal =
      userHasSubmittedWill && userPreference == null;
    shouldShowCharityConsent.page = setRedirectablePages(
      userHasSubmittedWill,
      showEstate,
      showGifts,
      userPreference
    );
  }

  ctx.store.commit(
    'ruach/shouldShowCharityConsentPage',
    shouldShowCharityConsent.page
  );
  ctx.store.commit(
    'ruach/shouldShowCharityConsentModal',
    shouldShowCharityConsent.modal
  );
}

export default (ctx, inject) => {
  inject(
    'updateShouldShowCharityConsent',
    async () => await getShouldShowCharityConsent(ctx)
  );
  getShouldShowCharityConsent(ctx);
};
