import gql from 'graphql-tag';

export default gql`
  mutation CardlessCheckout(
    $userId: ID!
    $products: [Product!]
    $productsWithCustomPricesToken: String
    $expectedCostInCents: Int!
    $discountCode: String
  ) {
    cardlessCheckout(
      userId: $userId
      products: $products
      productsWithCustomPricesToken: $productsWithCustomPricesToken
      expectedCostInCents: $expectedCostInCents
      discountCode: $discountCode
    ) {
      success
      message
    }
  }
`;
