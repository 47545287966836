import { metaArrayToObject } from '@/utilities';
import { error } from '@/mixins/apollo';

import GET_POA_META from '@/graphql/queries/GetPoaMeta';
import UPDATE_POA_META from '@/graphql/mutations/UpdatePoaMeta';

function getPoaMeta(category) {
  return {
    query: GET_POA_META,
    update(data) {
      if (data.poa) {
        const metaForCategory = data.poa.meta.filter(
          (metaItem) => metaItem.category === category
        );
        return metaArrayToObject(metaForCategory);
      }
      return {};
    },
    error,
  };
}

const poaMedicalMeta = getPoaMeta('MEDICAL');
const poaFinancialMeta = getPoaMeta('FINANCIAL');

function updatePoaMeta(poaId, { key, value, category }) {
  return updatePoaMetas(poaId, [{ key, value, category }]);
}

function updatePoaMetas(poaId, metaArray) {
  return {
    mutation: UPDATE_POA_META,
    variables: {
      poaId,
      meta: metaArray,
    },
    error,
  };
}

const queries = {
  poaFinancialMeta,
  poaMedicalMeta,
};

const mutations = {
  updatePoaMeta,
  updatePoaMetas,
};

export { queries, mutations };
