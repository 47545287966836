import Cookies from 'js-cookie';

function removeLegacyCookie() {
  Cookies.remove('apollo-token', {
    path: '/',
  });
  Cookies.remove('apollo-token-staging', {
    path: '/',
  });
}

export { removeLegacyCookie };
