import gql from 'graphql-tag';

export default gql`
  query getProductPrices(
    $productCodes: [Product!]!
    $userId: ID!
    $discountCode: String
  ) {
    getProductPrices(
      productCodes: $productCodes
      discountCode: $discountCode
      userId: $userId
    ) {
      productCode
      productPriceSlug
      basePrice
      discountedPrice
    }
  }
`;
