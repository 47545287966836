import gql from 'graphql-tag';
import Pet from '../fragments/Pet';

export default gql`
  mutation removePet($id: ID!, $willId: ID!) {
    removePet(id: $id, willId: $willId) {
      ...Pet
    }
  }
  ${Pet}
`;
