import gql from 'graphql-tag';

export default gql`
  query getPartnershipAffiliateFeatures($slug: String!) {
    getPartnershipAffiliateFeatures(slug: $slug) {
      dashboardEnabled
      willEnabled
      powerOfAttorneyEnabled
      endOfLifeEnabled
      giftEnabled
      onboardingNeedsAssessmentEnabled
      willTierTwoEnabled
      affiliateUserIdEnabled
      affiliateUserIdFieldName
      couponCodeEnabled
      couponCodeFieldName
    }
  }
`;
