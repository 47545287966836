import gql from 'graphql-tag';
import Institution from '../fragments/Institution';

export default gql`
  query getInstitutions(
    $limit: Int
    $offset: Int
    $customOrder: InstitutionsCustomOrder
    $searchQuery: String
  ) {
    getInstitutions(
      limit: $limit
      offset: $offset
      customOrder: $customOrder
      searchQuery: $searchQuery
    ) {
      ...Institution
    }
  }
  ${Institution}
`;
