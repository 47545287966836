import gql from 'graphql-tag';
import Executor from '../fragments/Executor';

export default gql`
  mutation removeExecutor($id: ID!, $willId: ID!) {
    removeExecutor(id: $id, willId: $willId) {
      ...Executor
    }
  }
  ${Executor}
`;
