export const state = () => ({
  showOver60Banner: false,
  showBlackFridayBanner: false,
});

export const getters = {
  showOver60Banner: (state, _getters, _rootState, rootGetters) => {
    const isOver60Product =
      rootGetters['product-prices/willSlug'] === 'will_sixty_plus';
    const hasNotPurchasedWill =
      !rootGetters['orders/purchasedProductCodes'].includes('WILL');

    return (isOver60Product && hasNotPurchasedWill) || state.showOver60Banner;
  },
  showBlackFridayBanner: (state, _getters, _rootState, rootGetters) => {
    const isInBlackFriday =
      rootGetters['product-prices/willSlug']?.includes('black_friday');
    const hasNotPurchasedWill = !rootGetters[
      'orders/purchasedProductCodes'
    ].find((code) => ['WILL', 'WILL_TIER_TWO'].includes(code));

    return (
      (isInBlackFriday && hasNotPurchasedWill) || state.showBlackFridayBanner
    );
  },
};
