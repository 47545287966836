import gql from 'graphql-tag';
import Charity from './Charity';

export default gql`
  fragment Cause on Cause {
    id
    displayName
    shortDescription
    longDescription
    displayImage
    supersededBy
    precededBy
    slug
    active
    readOnly
    priority
    effectiveAt
    expireAt
    segment {
      country
    }
    charities {
      ...Charity
    }
  }
  ${Charity}
`;
