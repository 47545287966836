import gql from 'graphql-tag';
import Charity from './Charity';
import GiftMeta from './GiftMeta';
import DirectoryPerson from './DirectoryPerson';

export default gql`
  fragment Gift on Gift {
    id
    meta {
      ...GiftMeta
    }
    recipientDirectoryPerson {
      ...DirectoryPerson
    }
    charity {
      ...Charity
    }
  }
  ${Charity}
  ${GiftMeta}
  ${DirectoryPerson}
`;
