import SET_SUBSCRIPTION_AUTO_RENEW from '@/graphql/mutations/SetSubscriptionAutoRenew';

export default {
  setSubscription({ commit }, status) {
    commit('setSubscription', status);
  },
  async setAutoRenew({ rootGetters, commit }, autoRenew) {
    const {
      data: { setSubscriptionAutoRenew },
    } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: SET_SUBSCRIPTION_AUTO_RENEW,
      variables: {
        userId: rootGetters.userId,
        autoRenew,
      },
    });
    commit('setSubscription', setSubscriptionAutoRenew);
  },
};
