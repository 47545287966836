import gql from 'graphql-tag';

export default gql`
  fragment User on User {
    id
    email
    role
    verified
    twoFactorEnabled
    latest_referral_coupon
    person_id
    affiliateId
    affiliate_user_id
  }
`;
