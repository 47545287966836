import {
  VAULT_ITEM_KEY,
  VAULT_ITEM_TYPE,
  ONBOARDING_STEP,
} from '@/utilities/constants';

export default () => ({
  currentStepKey: ONBOARDING_STEP.NAME,
  steps: {
    [ONBOARDING_STEP.NAME]: {
      slug: '/onboarding/user/name',
      title: 'pages.onboarding.newUser.name.headline',
      subtitle: 'pages.onboarding.newUser.name.description',
      cta: 'forms.buttons.next',
      image: 'onboarding__step-1.svg',
    },
    [ONBOARDING_STEP.DOB]: {
      slug: '/onboarding/user/dob',
      title: 'pages.onboarding.newUser.dob.headline',
      subtitle: 'pages.onboarding.newUser.dob.description',
      cta: 'forms.buttons.next',
      image: 'onboarding__step-2.svg',
    },
    [ONBOARDING_STEP.COUNTRY]: {
      slug: '/onboarding/user/country',
      title: 'pages.onboarding.newUser.country.headline',
      cta: 'forms.buttons.next',
      image: 'onboarding__step-3.svg',
    },
    [ONBOARDING_STEP.FAMILY]: {
      slug: '/onboarding/user/family',
      title: 'pages.onboarding.newUser.family.headline',
      subtitle: 'pages.onboarding.newUser.family.description',
      cta: 'forms.buttons.next',
      vaultItems: [
        {
          id: 'childWithPreviousPartner',
          label:
            'pages.onboarding.newUser.family.vaultItems.hasChildWithPreviousPartner.label',
          icon: '/img/icons/family.svg',
          key: VAULT_ITEM_KEY.SITUATION_FAMILY_CHILD,
          type: VAULT_ITEM_TYPE.HAS_CHILD_WITH_PREVIOUS_PARTNER,
          createIfDoesNotExist: true,
        },
        {
          id: 'upcomingChangeOfMaritalStatus',
          label:
            'pages.onboarding.newUser.family.vaultItems.hasUpComingChangeOfMaritalStatus.label',
          icon: '/img/icons/broken-heart.svg',
          key: VAULT_ITEM_KEY.SITUATION_FAMILY_PARTNER,
          type: VAULT_ITEM_TYPE.HAS_UPCOMING_CHANGE_OF_MARITAL_STATUS,
          createIfDoesNotExist: true,
        },
        {
          id: 'exclusionOfFamilyMemberFromWill',
          label:
            'pages.onboarding.newUser.family.vaultItems.exclusionOfFamilyMemberFromWill.label',
          icon: '/img/icons/block.svg',
          key: VAULT_ITEM_KEY.DECISION_EXCLUSION_DEATH,
          type: VAULT_ITEM_TYPE.ESTATE_BENEFICIARY,
        },
        {
          id: 'protectDependentWithDisability',
          label:
            'pages.onboarding.newUser.family.vaultItems.protectDependentWithDisability.label',
          icon: '/img/icons/heart.svg',
          key: VAULT_ITEM_KEY.SITUATION_FAMILY_OTHER,
          type: VAULT_ITEM_TYPE.HAS_FAMILY_MEMBER_WITH_DISABILITY,
          createIfDoesNotExist: true,
        },
        {
          id: 'familyNoneOfThese',
          label: 'pages.onboarding.newUser.family.vaultItems.noneOfThese.label',
          clearAnotherItems: true,
          icon: '/img/icons/arrow.svg',
        },
      ],
    },
    [ONBOARDING_STEP.ASSETS]: {
      slug: '/onboarding/user/assets',
      title: 'pages.onboarding.newUser.assets.headline',
      subtitle: 'pages.onboarding.newUser.assets.description',
      cta: 'forms.buttons.next',
      vaultItems: [
        {
          id: 'assetsOver1_5M',
          label:
            'pages.onboarding.newUser.assets.vaultItems.assetsOver1_5M.label',
          icon: '/img/icons/ownership.svg',
          key: VAULT_ITEM_KEY.SITUATION_FINANCIAL_ASSETS_SIZE,
          type: VAULT_ITEM_TYPE.HAS_SIGNIFICANT_EXCLUDING_PRIMARY_RESIDENCE,
          createIfDoesNotExist: true,
        },
        {
          id: 'ownAssetsOverseas',
          label:
            'pages.onboarding.newUser.assets.vaultItems.ownAssetsOverseas.label',
          icon: '/img/icons/earth.svg',
          key: VAULT_ITEM_KEY.SITUATION_FINANCIAL_ASSETS_LOCATION,
          type: VAULT_ITEM_TYPE.HAS_INTERNATIONAL,
          createIfDoesNotExist: true,
        },
        {
          id: 'ownBusiness',
          label: 'pages.onboarding.newUser.assets.vaultItems.ownBusiness.label',
          icon: '/img/icons/business.svg',
          key: VAULT_ITEM_KEY.SITUATION_FINANCIAL_ASSET_TYPE,
          type: VAULT_ITEM_TYPE.IS_BUSINESS_OWNER,
          createIfDoesNotExist: true,
        },
        {
          id: 'selfManagedSuperFund',
          label:
            'pages.onboarding.newUser.assets.vaultItems.selfManagedSuperFund.label',
          icon: '/img/icons/profit.svg',
          key: VAULT_ITEM_KEY.SITUATION_FINANCIAL_ASSET_TYPE,
          type: VAULT_ITEM_TYPE.IS_SELF_MANAGED_PENSION_FUND_OWNER,
          createIfDoesNotExist: true,
        },
        {
          id: 'interestInFamilyTrust',
          label:
            'pages.onboarding.newUser.assets.vaultItems.interestInFamilyTrust.label',
          icon: '/img/icons/distribute.svg',
          key: VAULT_ITEM_KEY.SITUATION_FINANCIAL_ASSET_TYPE,
          type: VAULT_ITEM_TYPE.HAS_FAMILY_TRUST_INTEREST,
          createIfDoesNotExist: true,
        },
        {
          id: 'assetsNoneOfThese',
          label: 'pages.onboarding.newUser.assets.vaultItems.noneOfThese.label',
          clearAnotherItems: true,
          icon: '/img/icons/arrow.svg',
        },
      ],
    },
    [ONBOARDING_STEP.FINISH]: {
      slug: '/onboarding/user/finish',
      cta: 'forms.buttons.finish',
    },
    [ONBOARDING_STEP.INTRODUCTION]: {
      slug: '/onboarding/introduction',
    },
  },
  payload: {
    firstName: null,
    middleName: null,
    lastName: null,
    dateOfBirth: null,
    country: null,
    affiliateUserId: null,
    couponCode: null,
  },
  selectedVaultItemsIds: [],
  loading: false,
});
