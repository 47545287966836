import gql from 'graphql-tag';
import PlanFlag from '../fragments/PlanFlag';

export default gql`
  mutation actionPlanFlag($id: ID!, $action: PlanFlagAction!) {
    actionPlanFlag(id: $id, action: $action) {
      ...PlanPlanFlag
    }
  }
  ${PlanFlag}
`;
