import gql from 'graphql-tag';
import Tool from '../fragments/Tool';

export default gql`
  query getTools($ownerId: ID!) {
    tools(ownerId: $ownerId) {
      ...Tool
    }
  }
  ${Tool}
`;
