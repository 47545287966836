import GET_ORDERS from '@/graphql/queries/GetOrders';
import GET_SUBSCRIPTION_QUERY from '@/graphql/queries/GetSubscription';

import { pollUntilTruthy } from '@/utilities';

export default {
  async getOrders({ rootGetters, commit }) {
    const userId = rootGetters.userId;
    if (!userId) {
      return;
    }
    const { data } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_ORDERS,
      variables: {
        userId,
      },
    });
    commit('setOrders', data?.getOrders ?? []);
  },
  setIsProcessingOrder({ commit }, isProcessingOrder) {
    commit('setIsProcessingOrder', isProcessingOrder);
  },
  checkPurchasedItems({ dispatch, rootGetters, getters }, purchasedItems) {
    const userId = rootGetters.userId;
    return pollUntilTruthy(async () => {
      await dispatch('getOrders');
      const latestOrder = getters.latestOrder;
      if (latestOrder?.status !== 'SUCCESSFUL') {
        return;
      }
      const products = latestOrder?.orderItems.map((item) => item.product);
      if (
        products.some((code) =>
          ['SUBSCRIPTION', 'UNLOCK', 'RECENT_UNLOCK'].includes(code)
        )
      ) {
        await pollUntilTruthy(async () => {
          const { data } = await this.app.apolloProvider.defaultClient.query({
            fetchPolicy: 'no-cache',
            query: GET_SUBSCRIPTION_QUERY,
            variables: {
              userId,
            },
          });
          return data?.getSubscription?.expiresAt > Date.now();
        });
      }
      return purchasedItems.every((purchasedItem) =>
        products.includes(purchasedItem)
      );
    });
  },
};
