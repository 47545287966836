import { metaArrayToObject } from '@/utilities';

import GET_ATTORNEYS from '@/graphql/queries/GetAttorneys';
import GET_POA_INCLUSIONS from '@/graphql/queries/GetPoaInclusions';
import GET_POA_META from '@/graphql/queries/GetPoaMeta';
import GET_POA_STATUS from '@/graphql/queries/GetPoaStatus';

export default {
  async getStatus({ dispatch, getters: { poaId } }) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_POA_STATUS,
      variables: {
        id: poaId,
      },
    });
    await dispatch('setStatus', data.poa.status);
  },
  setStatus({ commit, dispatch }, status) {
    commit('setStatus', status);
    dispatch('progress/setPoaProgress', null, { root: true });
  },
  setPoaId({ commit }, id) {
    commit('setPoaId', id);
  },
  getAllPoaRelatedData({ dispatch }) {
    return Promise.all([
      dispatch('getStatus'),
      dispatch('getData'),
      dispatch('getAttorneys'),
      dispatch('getInclusions'),
    ]);
  },
  async getData({ dispatch, getters: { poaId } }) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_POA_META,
      variables: {
        poaId,
      },
    });
    await dispatch('setData', data.poa);
  },
  setData({ commit, dispatch }, value) {
    if (!value) return false;
    let meta = {};
    value.meta.forEach((entry) => {
      const category = entry.category.toLowerCase();
      const data = metaArrayToObject([entry]);
      meta = {
        ...meta,
        [category]: {
          ...meta[category],
          ...data,
        },
      };
    });
    const data = {
      ...value,
      meta,
    };
    commit('setData', data);
    dispatch('progress/setPoaProgress', null, { root: true });
  },
  async getAttorneys({ dispatch, getters: { poaId } }) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_ATTORNEYS,
      variables: {
        poaId,
      },
    });
    await dispatch('setAttorneys', data.poa);
  },
  setAttorneys({ commit, dispatch }, values) {
    const attorneys = {
      financial: {
        primary: [],
        secondary: [],
      },
      medical: {
        primary: [],
        secondary: [],
      },
    };
    values.attorneys.forEach((entry) => {
      const power = entry.power.toLowerCase();
      const role = entry.role.toLowerCase();
      attorneys[power][role].push(entry.id);
    });
    commit('setAttorneys', attorneys);
    dispatch('progress/setPoaProgress', null, { root: true });
  },
  async getInclusions({ dispatch, getters: { poaId } }) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_POA_INCLUSIONS,
      variables: {
        poaId,
      },
    });
    await dispatch('setInclusions', data.poa);
  },
  setInclusions({ commit, dispatch }, values) {
    const inclusions = values.inclusions.map((entry) => entry.id);
    commit('setInclusions', inclusions);
    dispatch('progress/setPoaProgress', null, { root: true });
  },
};
