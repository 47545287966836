import gql from 'graphql-tag';
import Will from '../fragments/Will';

export default gql`
  mutation updateWillIsPrimaryEstateSplitEvenly(
    $id: ID!
    $isPrimaryEstateSplitEvenly: Boolean!
  ) {
    updateWillIsPrimaryEstateSplitEvenly(
      id: $id
      isPrimaryEstateSplitEvenly: $isPrimaryEstateSplitEvenly
    ) {
      success
      message
      will {
        ...Will
      }
    }
  }
  ${Will}
`;
