import gql from 'graphql-tag';
import User from '../fragments/User';

export default gql`
  mutation masquerade($id: String!) {
    masquerade(id: $id) {
      success
      message
      user {
        ...User
      }
    }
  }
  ${User}
`;
