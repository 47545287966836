import gql from 'graphql-tag';
import AssetMeta from './AssetMeta';

export default gql`
  fragment Asset on Asset {
    id
    meta {
      ...AssetMeta
    }
  }
  ${AssetMeta}
`;
