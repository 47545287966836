import gql from 'graphql-tag';
import Cause from '../fragments/Cause';

export default gql`
  query getCausesByUserSegment($userId: ID!) {
    getCausesByUserSegment(userId: $userId) {
      ...Cause
    }
  }
  ${Cause}
`;
