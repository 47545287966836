import { mapActions } from 'vuex';

import ADD_BENEFICIARY_MUTATION from '@/graphql/mutations/AddBeneficiary';
import GET_BENEFICIARIES_QUERY from '@/graphql/queries/GetBeneficiaries';
import REMOVE_BENEFICIARY_MUTATION from '@/graphql/mutations/RemoveBeneficiary';
import UPDATE_BENEFICIARY_MUTATION from '@/graphql/mutations/UpdateBeneficiary';
import { objectToMetaArray } from '@/utilities';
import {
  charityToAnalyticsCharityParams,
  causeToAnalyticsCauseParams,
} from '@/utilities/charity';
import { error } from '@/mixins/apollo';

export default {
  name: 'MixinsApolloBeneficiaries',
  apollo: {
    beneficiaries: {
      prefetch: false,
      fetchPolicy: 'no-cache',
      query: GET_BENEFICIARIES_QUERY,
      variables() {
        return {
          willId: this.willId,
        };
      },
      update: (data) => {
        return data?.getBeneficiaries || [];
      },
      skip() {
        return !this.token || !this.willId;
      },
      error,
    },
  },
  data() {
    return {
      beneficiaries: [],
    };
  },
  watch: {
    async beneficiaries(beneficiaries) {
      // Enrich directory persons and set beneficiaries in store
      const enrichedBeneficiaries = await this.setBeneficiaries(beneficiaries);

      beneficiaries.forEach((_, index) => {
        beneficiaries[index] = enrichedBeneficiaries[index];
      });
    },
  },
  computed: {
    backupQueue() {
      return this.beneficiaries.filter(
        (beneficiary) => beneficiary.directoryPerson
      );
    },
    hasBeneficiaries() {
      return this.beneficiaries && this.beneficiaries.length > 0;
    },
  },
  methods: {
    ...mapActions('will', ['setBeneficiaries']),
    async addBeneficiary(
      data,
      distribution,
      meta = [],
      type = 'PRIMARY',
      backupId
    ) {
      if (distribution !== null) {
        distribution = this.formatDistribution(distribution);
      }
      meta = objectToMetaArray(meta);

      const variables = {
        willId: this.willId,
        userId: this.userId,
        charityId: null,
        directoryPersonId: null,
        causeId: null,
        distribution,
        meta,
        type,
        backupId,
      };

      if (data.firstName) {
        variables.directoryPersonId = data.id;
      } else if (data.charities) {
        variables.causeId = data.id;
      } else {
        variables.charityId = data.id;
      }

      await this.$apollo.mutate({
        mutation: ADD_BENEFICIARY_MUTATION,
        variables,
      });
    },
    addBackupBeneficiary(parentBeneficiary, data, distribution, meta = []) {
      return this.addBeneficiary(
        data,
        distribution,
        meta,
        'BACKUP',
        parentBeneficiary.id
      );
    },
    formatDistribution(distribution) {
      return parseFloat(parseFloat(distribution).toFixed(2)).toString();
    },
    getBeneficiaryByCharity(charity) {
      return this.beneficiaries.find(
        (beneficiary) =>
          beneficiary.charity && beneficiary.charity.id === charity.id
      );
    },
    getBackupBeneficiaryByCharity(parentBeneficiary, charity) {
      parentBeneficiary.backup = parentBeneficiary.backup
        ? parentBeneficiary.backup
        : [];
      return parentBeneficiary.backup.find(
        (beneficiary) =>
          beneficiary.charity && beneficiary.charity.id === charity.id
      );
    },
    getBeneficiaryByContact(contact) {
      return this.beneficiaries.find(
        (beneficiary) => beneficiary?.directoryPerson?.id === contact.id
      );
    },
    getBackupBeneficiaryByContact(parentBeneficiary, contact) {
      parentBeneficiary.backup = parentBeneficiary.backup
        ? parentBeneficiary.backup
        : [];
      return parentBeneficiary.backup.find(
        (beneficiary) => beneficiary?.directoryPerson?.id === contact.id
      );
    },
    getBeneficiaryByCause(cause) {
      return this.beneficiaries.find(
        (beneficiary) => beneficiary?.cause?.id === cause.id
      );
    },
    getBackupBeneficiaryByCause(parentBeneficiary, cause) {
      parentBeneficiary.backup = parentBeneficiary.backup
        ? parentBeneficiary.backup
        : [];
      return parentBeneficiary.backup.find(
        (beneficiary) => beneficiary?.cause?.id === cause.id
      );
    },
    isBeneficiary(entity) {
      return !!(
        this.getBeneficiaryByCharity(entity) ||
        this.getBeneficiaryByContact(entity)
      );
    },
    async removeAllBeneficiaries() {
      await Promise.all(
        this.beneficiaries.map(async (beneficiary) => {
          await this.removeAllBackupBeneficiaries(beneficiary);
          return this.removeBeneficiary(beneficiary);
        })
      );
    },
    async removeAllBackupBeneficiaries(parentBeneficiary) {
      parentBeneficiary.backup = parentBeneficiary.backup
        ? parentBeneficiary.backup
        : [];
      await Promise.all(
        parentBeneficiary.backup.map((beneficiary) => {
          return this.removeBeneficiary(beneficiary);
        })
      );
    },
    async removeBeneficiary(beneficiary) {
      if (!beneficiary) {
        return this.beneficiaries;
      }
      await this.$apollo.mutate({
        mutation: REMOVE_BENEFICIARY_MUTATION,
        variables: {
          id: beneficiary.id,
          willId: this.willId,
        },
      });
      await this.refetchBeneficiaries();
    },
    async removeBackupBeneficiary(beneficiary) {
      await this.removeBeneficiary(beneficiary);
    },
    async removeBeneficiaryByCharity(charity) {
      const beneficiary = this.getBeneficiaryByCharity(charity);

      if (beneficiary) {
        await this.removeBeneficiary(beneficiary);
      }
    },
    async removeBackupBeneficiaryByCharity(parentBeneficiary, charity) {
      parentBeneficiary.backup = parentBeneficiary.backup
        ? parentBeneficiary.backup
        : [];
      const beneficiary = this.getBackupBeneficiaryByCharity(
        parentBeneficiary,
        charity
      );

      if (beneficiary) {
        await this.removeBeneficiary(beneficiary);
      }
    },
    async removeBeneficiaryByContact(contact) {
      const beneficiary = this.getBeneficiaryByContact(contact);

      if (beneficiary) {
        await this.removeBeneficiary(beneficiary);
      }
    },
    async removeBackupBeneficiaryByContact(parentBeneficiary, contact) {
      parentBeneficiary.backup = parentBeneficiary.backup
        ? parentBeneficiary.backup
        : [];
      const beneficiary = this.getBackupBeneficiaryByContact(
        parentBeneficiary,
        contact
      );

      if (beneficiary) {
        await this.removeBeneficiary(beneficiary);
      }
    },
    async updateBeneficiaryCore(
      beneficiary,
      distribution,
      isBackupEstateSplitEvenly
    ) {
      await this.$apollo.mutate({
        mutation: UPDATE_BENEFICIARY_MUTATION,
        variables: {
          id: beneficiary.id,
          distribution:
            distribution === null
              ? null
              : this.formatDistribution(distribution),
          isBackupEstateSplitEvenly,
          meta: objectToMetaArray(beneficiary.meta),
          willId: this.willId,
          userId: this.userId,
        },
      });
    },
    async updateBeneficiary(
      beneficiary,
      distribution,
      isBackupEstateSplitEvenly
    ) {
      await this.updateBeneficiaryCore(
        beneficiary,
        distribution,
        isBackupEstateSplitEvenly
      );
      await this.refetchBeneficiaries();
    },
    async updateBeneficiaryMeta(beneficiary) {
      await this.updateBeneficiary(
        beneficiary,
        beneficiary.distribution,
        beneficiary.isBackupEstateSplitEvenly
      );
    },
    async updateBackupBeneficiary(beneficiary, distribution) {
      await this.updateBeneficiary(beneficiary, distribution);
    },
    async refetchBeneficiaries() {
      await this.$apollo.queries.beneficiaries.refetch();
    },
    sendBeneficiariesAddedEvent() {
      const selectedContacts = this.contacts.filter((contact) =>
        this.selectedContacts.includes(contact.id)
      );
      const selectedCharities = this.charities.filter((charity) =>
        this.selectedContacts.includes(charity.id)
      );
      const selectedCauses = this.beneficiaries.reduce(
        (causes, beneficiary) => {
          if (beneficiary.cause) {
            causes.push(beneficiary.cause);
          }
          return causes;
        },
        []
      );

      const charitiesParams = selectedCharities.map(
        charityToAnalyticsCharityParams
      );
      const flatCharitiesParams = charitiesParams.reduce((acc, charity) => {
        acc[`charity_${charity.charity_name.toLowerCase()}_selected`] = true;
        return acc;
      }, {});

      const causeParams = selectedCauses.map(causeToAnalyticsCauseParams);
      const flatCauseParams = causeParams.reduce((acc, cause) => {
        acc[`cause_${cause.cause_slug.toLowerCase()}_selected`] = true;
        return acc;
      }, {});

      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'add_beneficiaries',
        props: {
          individuals: selectedContacts.length,
          charities: charitiesParams,
          causes: causeParams,
          ...flatCauseParams,
          ...flatCharitiesParams,
        },
      });
    },
  },
};
