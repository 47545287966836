import gql from 'graphql-tag';
import EndOfLifeItem from '../fragments/EndOfLifeItem';

export default gql`
  query getEndOfLifeItemsByCategoryIdAndUserId($categoryId: ID!, $userId: ID!) {
    getEndOfLifeItemsByCategoryIdAndUserId(
      categoryId: $categoryId
      userId: $userId
    ) {
      endOfLifeItems {
        ...EndOfLifeItem
      }
    }
  }
  ${EndOfLifeItem}
`;
