import gql from 'graphql-tag';
import Beneficiary from '../fragments/Beneficiary';

export default gql`
  mutation removeBeneficiary($id: ID!, $willId: ID!) {
    removeBeneficiary(id: $id, willId: $willId) {
      ...Beneficiary
    }
  }
  ${Beneficiary}
`;
