import gql from 'graphql-tag';
import AccountInterest from '../fragments/AccountInterest';

export default gql`
  mutation createAccountInterest(
    $userId: ID!
    $country: CountryIsoCode!
    $region: Region
    $product: AccountInterestProduct!
    $brand: String
  ) {
    createAccountInterest(
      accountInterest: {
        userId: $userId
        leadIdentifier: { country: $country, region: $region, brand: $brand }
        product: $product
      }
    ) {
      ...AccountInterest
    }
  }
  ${AccountInterest}
`;
