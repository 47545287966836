import gql from 'graphql-tag';
import Partnership from '../fragments/Partnership';
import PartnershipContact from '../fragments/PartnershipContact';

export default gql`
  query GetPartnership($id: ID!) {
    getPartnership(id: $id) {
      ...Partnership
      contacts {
        ...PartnershipContact
      }
    }
  }
  ${Partnership}
  ${PartnershipContact}
`;
