import cookies from 'js-cookie';
import CREATE_BULK_EXTERNAL_IDENTIFIER_MUTATION from '@/graphql/mutations/CreateBulkExternalIdentifier';
import GET_EXTERNAL_IDENTIFIER_ANALYTIC_IDENTIFIERS_QUERY from '@/graphql/queries/GetExternalIdentifierAnalyticIdentifiers';

const ANALYTIC_COOKIES = [
  '_fbc',
  '_fbp',
  '_ttp',
  'ttclid',
  '_ga',
  '_gcl_aw',
  '_cfclick',
];

export default {
  async createAnalyticIdentifiers({ rootGetters }) {
    const analyticCookies = Object.entries(cookies.get())
      .filter(([key, _value]) => ANALYTIC_COOKIES.includes(key))
      .map(([key, value]) => ({
        ownerId: rootGetters.userId,
        key,
        value,
        type: 'ANALYTIC',
      }));
    await this.app.apolloProvider.defaultClient.mutate({
      mutation: CREATE_BULK_EXTERNAL_IDENTIFIER_MUTATION,
      variables: {
        identifiers: analyticCookies,
      },
    });
  },
  async getAnalyticIdentifiers({ rootGetters }) {
    const {
      data: { getExternalIdentifierAnalyticIdentifiers: identifiers },
    } = await this.app.apolloProvider.defaultClient.query({
      query: GET_EXTERNAL_IDENTIFIER_ANALYTIC_IDENTIFIERS_QUERY,
      variables: {
        ownerId: rootGetters.userId,
      },
    });
    identifiers.forEach((identifier) =>
      cookies.set(identifier.key, identifier.value)
    );
  },
};
