import GET_PRODUCTS from '@/graphql/queries/Products';

export default {
  async getProducts({ rootState, _, commit }) {
    const {
      data: { products },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_PRODUCTS,
      variables: {
        userId: rootState.userId,
      },
    });
    commit('setProducts', products);
  },
};
