import Vue from 'vue';
import Skyflow from 'skyflow-js';
import GET_PRIVACY_VAULT_ACCESS_TOKEN from '@/graphql/mutations/GetPrivacyVaultAccessToken';
import GET_PRIVACY_VAULT_TARGET from '@/graphql/mutations/GetPrivacyVaultTarget';
import SET_PRIVACY_VAULT_TARGET from '@/graphql/mutations/SetPrivacyVaultTarget';

const vaultMeta = {
  id: process.env.SKYFLOW_ID,
  url: process.env.SKYFLOW_URL,
};

const tokenisedRegexString = 'token\\|[a-f0-9-]{36}';

const PrivacyVaultService = {
  vaultClient: undefined,
  apiClient: undefined,
  masqueradeUserId: undefined,
  setMasqueradeUserId(masqueradeUserId) {
    this.masqueradeUserId = masqueradeUserId;
    this.vaultClient = undefined;
  },
  getVaultClient() {
    if (!this.vaultClient) {
      this.vaultClient = Skyflow.init({
        vaultID: vaultMeta.id,
        vaultURL: vaultMeta.url,
        getBearerToken: this.getAccessToken.bind(this),
      });
    }
    return this.vaultClient;
  },
  async getAccessToken() {
    try {
      const {
        data: {
          getPrivacyVaultAccessToken: { accessToken },
        },
      } = await this.apiClient.query({
        query: GET_PRIVACY_VAULT_ACCESS_TOKEN,
        variables: {
          userId: this.masqueradeUserId,
        },
        fetchPolicy: 'no-cache',
      });
      return accessToken;
    } catch (error) {
      throw new Error(`Something went wrong: ${error}`);
    }
  },
  async revealByPrivacyVaultTarget({
    privacyVaultTarget: { targetId, targetCollection },
    redactionType = Skyflow.RedactionType.PLAIN_TEXT,
  }) {
    const vaultClient = this.getVaultClient();
    const vaultQuery = {
      records: [
        {
          ids: [targetId],
          table: targetCollection.toLowerCase(),
          redaction: redactionType,
        },
      ],
    };
    const { records } = await vaultClient.getById(vaultQuery);
    return Object.fromEntries(
      Object.entries(records[0].fields).filter(
        ([key]) => !['owner_id', 'foreign_id', 'id'].includes(key)
      )
    );
  },
  async revealByResourceTarget({
    resourceTarget: { resourceId, resourceCollection },
    redactionType = Skyflow.RedactionType.PLAIN_TEXT,
  }) {
    const privacyVaultTarget = await this.getPrivacyVaultTargetByResourceTarget(
      {
        resourceTarget: { resourceId, resourceCollection },
      }
    );

    if (privacyVaultTarget) {
      return await this.revealByPrivacyVaultTarget({
        privacyVaultTarget,
        redactionType,
      });
    }
  },
  async save({
    privacyVaultTargetCollection,
    ownerId,
    resourceTargetId,
    record,
  }) {
    const vaultClient = this.getVaultClient();
    const pretokenisedFields = {};
    const filteredFields = {};
    Object.entries(record).forEach(([key, value]) => {
      if (this.isTokenised(value)) {
        pretokenisedFields[key] = value;
      } else {
        filteredFields[key] = value;
      }
    });
    const vaultQuery = {
      records: [
        {
          table: privacyVaultTargetCollection.toLowerCase(),
          fields: {
            ...filteredFields,
            owner_id: ownerId,
            foreign_id: resourceTargetId,
          },
        },
      ],
    };
    const method = {
      tokens: true,
      upsert: [
        {
          table: privacyVaultTargetCollection.toLowerCase(),
          column: 'foreign_id',
        },
      ],
    };
    const { records } = await vaultClient.insert(vaultQuery, method);
    const { skyflow_id: privacyVaultTargetId, ...fields } = records[0].fields;
    const tokenisedFields = this.tokenise(fields);
    return {
      privacyVaultTarget: {
        targetId: privacyVaultTargetId,
        targetCollection: privacyVaultTargetCollection,
      },
      tokens: {
        ...pretokenisedFields,
        ...tokenisedFields,
      },
    };
  },
  async getPrivacyVaultTargetByResourceTarget({
    resourceTarget: { resourceId, resourceCollection },
  }) {
    const {
      data: { getPrivacyVaultTarget },
    } = await this.apiClient.query({
      query: GET_PRIVACY_VAULT_TARGET,
      fetchPolicy: 'no-cache',
      variables: {
        resourceId,
        resourceCollection,
        userId: this.masqueradeUserId,
      },
    });
    return getPrivacyVaultTarget?.privacyVaultTarget;
  },
  async setPrivacyVaultTarget({
    resourceTarget: { resourceId, resourceCollection },
    privacyVaultTarget: { targetId, targetCollection },
  }) {
    const {
      data: { setPrivacyVaultTarget },
    } = await this.apiClient.mutate({
      mutation: SET_PRIVACY_VAULT_TARGET,
      variables: {
        resourceId,
        resourceCollection,
        targetId,
        targetCollection,
        userId: this.masqueradeUserId,
      },
    });
    return setPrivacyVaultTarget;
  },
  isTokenised(str) {
    return new RegExp(`^${tokenisedRegexString}$`).test(str);
  },
  hasTokenisedData(data) {
    return new RegExp(tokenisedRegexString).test(JSON.stringify(data));
  },
  tokenise(obj) {
    const tokenisedEntries = Object.entries(obj).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [key, value.map((element) => `token|${element}`)];
      } else if (typeof value === 'object') {
        return [key, this.tokenise(value)];
      } else {
        return [key, `token|${value}`];
      }
    });
    return Object.fromEntries(tokenisedEntries);
  },
};

export default (ctx) => {
  PrivacyVaultService.apiClient = ctx.app.apolloProvider.defaultClient;
  const privacyVaultServicePlugin = {
    install() {
      Vue.prototype.$privacyVaultService = PrivacyVaultService;
    },
  };
  Vue.use(privacyVaultServicePlugin);
  return PrivacyVaultService;
};
