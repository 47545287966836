import gql from 'graphql-tag';
import EndOfLifeItem from '../fragments/EndOfLifeItem';

export default gql`
  mutation CreateEndOfLifeItem(
    $id: ID!
    $categoryId: ID!
    $userId: ID!
    $data: JSONObject!
    $fileIds: [String]
  ) {
    createEndOfLifeItem(
      endOfLifeItemData: {
        id: $id
        categoryId: $categoryId
        userId: $userId
        data: $data
        fileIds: $fileIds
      }
    ) {
      endOfLifeItem {
        ...EndOfLifeItem
      }
    }
  }
  ${EndOfLifeItem}
`;
