export default {
  setOrders(state, data) {
    state.orders = [...data].sort(
      (a, b) => Number(b.createdAt) - Number(a.createdAt)
    );
  },
  setIsProcessingOrder(state, data) {
    state.isProcessingOrder = data;
  },
};
