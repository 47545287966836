import gql from 'graphql-tag';
import Pet from '../fragments/Pet';

export default gql`
  mutation updatePet($id: ID!, $meta: [MetaInput!]!, $willId: ID!) {
    updatePet(id: $id, meta: $meta, willId: $willId) {
      ...Pet
    }
  }
  ${Pet}
`;
