import gql from 'graphql-tag';
import Asset from '../fragments/Asset';

export default gql`
  query getAssets($willId: ID!) {
    getAssets(willId: $willId) {
      ...Asset
    }
  }
  ${Asset}
`;
