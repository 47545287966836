import gql from 'graphql-tag';
import PlanFlag from '../fragments/PlanFlag';

export default gql`
  query getPlanFlagsByPlanId($planId: ID!) {
    getPlanFlagsByPlanId(planId: $planId) {
      ...PlanPlanFlag
    }
  }
  ${PlanFlag}
`;
