export const getFileName = (path) => {
  let fileName = path.split('/').pop();
  const fileNameSplit = fileName.split('.');
  fileName = `${fileNameSplit[0]}.${fileNameSplit[fileNameSplit.length - 1]}`;
  return fileName;
};

export const makePartnerLogoUrl = (path) => {
  const fileName = getFileName(path);
  return (
    process.env.STATIC_SAFEWILL_COM__URL +
    '/images/partnerships/logos/' +
    fileName
  );
};

export const makePartnerLandingUrl = (path) => {
  const fileName = getFileName(path);
  return (
    process.env.STATIC_SAFEWILL_COM__URL +
    '/images/partnerships/landing/' +
    fileName
  );
};

export const makeCauseLogoUrl = (path) => {
  const fileName = getFileName(path);
  return process.env.STATIC_SAFEWILL_COM__URL + '/images/causes/' + fileName;
};

export default (_ctx, inject) => {
  inject('makePartnerLogoUrl', makePartnerLogoUrl);
  inject('makePartnerLandingUrl', makePartnerLandingUrl);
  inject('makeCauseLogoUrl', makeCauseLogoUrl);
  inject('staticUrl', process.env.STATIC_SAFEWILL_COM__URL);
};
