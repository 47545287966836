import gql from 'graphql-tag';
import DirectoryPerson from '../fragments/DirectoryPerson';

export default gql`
  mutation CreateTokenisedDirectoryPerson(
    $input: TokenisedDirectoryPersonInput!
    $notify: Boolean
  ) {
    createTokenisedDirectoryPerson(input: $input, notify: $notify) {
      ...DirectoryPerson
    }
  }
  ${DirectoryPerson}
`;
