import { TOOL_TYPE } from '@/utilities/constants';

export default {
  allTools: (state) => state.tools,
  getToolById: (state) => (id) => state.tools.find((tool) => tool.id === id),
  getToolByType: (state) => (type) =>
    state.tools.find((tool) => tool.type === type),
  onboardingNeedsAssessmentTool: (state) =>
    state.tools.find(
      (tool) => tool.type === TOOL_TYPE.ONBOARDING_NEEDS_ASSESSMENT
    ),
  willHelpAssessmentTool: (state) =>
    state.tools.find((tool) => tool.type === TOOL_TYPE.WILL_HELP_ASSESSMENT),
};
