import gql from 'graphql-tag';
import Liability from '../fragments/Liability';

export default gql`
  mutation updateLiability($id: ID!, $meta: [MetaInput!]!, $willId: ID!) {
    updateLiability(id: $id, meta: $meta, willId: $willId) {
      ...Liability
    }
  }
  ${Liability}
`;
