import gql from 'graphql-tag';
import Coupon from '../fragments/Coupon';

export default gql`
  mutation updateCoupon(
    $id: ID!
    $description: String!
    $active: Boolean!
    $partnershipId: ID
  ) {
    updateCoupon(
      id: $id
      description: $description
      active: $active
      partnershipId: $partnershipId
    ) {
      ...Coupon
    }
  }
  ${Coupon}
`;
