import { mapActions } from 'vuex';

import GET_WILL_QUERY from '@/graphql/queries/GetWill';
import UPDATE_WILL_MUTATION from '@/graphql/mutations/UpdateWill';
import UPDATE_WILL_ESTATE_SPLIT from '@/graphql/mutations/UpdateWillIsPrimaryEstateSplitEvenly';
import { error, user } from '@/mixins/apollo';
import { metaArrayToObject, objectToMetaArray } from '@/utilities';

const metaDefaults = {
  name_first: '',
  name_middle: '',
  name_last: '',
  address_street: '',
  address_suburb: '',
  address_state: '',
  address_postcode: '',
  has_alt_name: null,
  alt_name_first: '',
  alt_name_middle: '',
  alt_name_last: '',
  partner_bundle: null,
  phone_number: null,
  has_partner: null,
  has_children: null,
  has_primary_guardian: null,
  has_backup_guardian: null,
  has_pets: null,
  has_pet_guardians: null,
  pet_care_fund: '',
  has_backup_executor: null,
  estate_split: null,
  estate_backup_index: 0,
  executors_option: null,
  professional_executor_terms_agreed_at: null,
  has_gifts: null,
  has_assets: null,
  funeral_skip: null,
  funeral_type: null,
  funeral_location: null,
  funeral_note: null,
  asset_note: null,
  liability_note: null,
  show_welcome_modal: true,
  notify_charities: null,
  charity_in_estate: true,
  charity_in_gifts: true,
  life_insurance_upsell: null,
};

export default {
  name: 'MixinsApolloWill',
  mixins: [user],
  apollo: {
    will: {
      query: GET_WILL_QUERY,
      variables() {
        return {
          id: this.willId,
        };
      },
      update: (data) => data?.getWill?.will,
      result({ data }) {
        if (data?.getWill?.will) {
          this.setWillData(data.getWill.will);
          this.setWillStatus(data.getWill.will.status);
          const invite = data.getWill.will.invite;
          if (invite) {
            if (invite && invite.type === 'PARTNER') {
              this.setInvitedByPartner(true);
            }
          }
        }
      },
      skip() {
        return !this.token || !this.willId;
      },
      fetchPolicy: 'no-cache',
      error,
    },
    willMeta: {
      query: GET_WILL_QUERY,
      variables() {
        return {
          id: this.willId,
        };
      },
      update: (data) =>
        data.getWill && metaArrayToObject(data.getWill.will.meta, metaDefaults),
      skip() {
        return !this.token || !this.willId;
      },
      error,
    },
  },
  data() {
    return {
      UPDATE_WILL_MUTATION,
      UPDATE_WILL_ESTATE_SPLIT,
      will: null,
      willMeta: {},
    };
  },
  computed: {
    UPDATE_WILL_VARS() {
      return {
        id: this.willId,
        meta: this.willMeta ? objectToMetaArray(this.willMeta) : null,
      };
    },
  },
  methods: {
    ...mapActions(['setWillStatus']),
    ...mapActions('checkout', ['setInvitedByPartner']),
    ...mapActions('will', {
      setWillData: 'setData',
    }),
    async updateWillMeta() {
      await this.$apollo.mutate({
        mutation: this.UPDATE_WILL_MUTATION,
        variables: this.UPDATE_WILL_VARS,
      });
    },
    async updateWillIsPrimaryEstateSplitEvenly(isPrimaryEstateSplitEvenly) {
      await this.$apollo.mutate({
        mutation: this.UPDATE_WILL_ESTATE_SPLIT,
        variables: {
          id: this.willId,
          isPrimaryEstateSplitEvenly,
        },
      });
    },
    async refetchWill() {
      await this.$apollo.queries.will.refetch();
      await this.$apollo.queries.willMeta.refetch();
    },
  },
};
