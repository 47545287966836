import gql from 'graphql-tag';

export default gql`
  query getEndOfLifeCategoriesWithItemCountByUserId($userId: ID!) {
    getEndOfLifeCategoriesWithItemCountByUserId(userId: $userId) {
      endOfLifeCategories {
        id
        name
        count
      }
    }
  }
`;
