import { SensitiveModel } from '@/plugins/sensitive/SensitiveModel';

const directoryAddressDefaults = {
  resourceCollection: 'DIRECTORY_ADDRESS',
  privacyTargetCollection: 'DIRECTORY_ADDRESS',
};

export const DirectoryAddress = new SensitiveModel({
  ...directoryAddressDefaults,
  privacyJsonMapping: {
    partnershipConsent: 'partnership_consent',
  },
  jsonMapping: {
    streetAddress: 'street_address',
    extendedAddress: 'extended_address',
    locality: 'locality',
  },
});
