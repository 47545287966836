import gql from 'graphql-tag';
import Will from '../fragments/Will';

export default gql`
  mutation updateWillStatus($id: ID!, $status: WillStatus!) {
    updateWillStatus(id: $id, status: $status) {
      ...Will
    }
  }
  ${Will}
`;
