import { WILL_TIERS_SLICE_TWO_ONE_VARIATION } from '@/utilities/constants';

export default {
  isWillTierTwoUpgradeAvailable: (
    _state,
    _getters,
    _rootState,
    rootGetters
  ) => {
    const userIsAustralian = rootGetters['directory-person/userIsAustralian'];
    const isWillTierTwoAlreadyInCart =
      rootGetters['cart/isInCart']('WILL_TIER_TWO');
    const hasPreviousPurchasedWillTierTwo =
      rootGetters['orders/purchasedProductCodes'].includes('WILL_TIER_TWO');
    const isWillStatusAwaitingApproval =
      rootGetters.willStatus === 'AWAITING_APPROVAL';
    const willTierTwoEnabled =
      rootGetters['application/affiliate/features'].willTierTwoEnabled;

    return (
      willTierTwoEnabled &&
      userIsAustralian &&
      !isWillTierTwoAlreadyInCart &&
      !hasPreviousPurchasedWillTierTwo &&
      !isWillStatusAwaitingApproval
    );
  },

  isWillTierTwoDowngradeAvailable: (
    _state,
    _getters,
    _rootState,
    rootGetters
  ) => {
    const isWillTierTwoAlreadyInCart =
      rootGetters['cart/isInCart']('WILL_TIER_TWO');
    const hasPurchasedWill =
      rootGetters['orders/purchasedProductCodes'].includes('WILL');
    const isWillStatusAwaitingApproval =
      rootGetters.willStatus === 'AWAITING_APPROVAL';

    return (
      isWillTierTwoAlreadyInCart &&
      !hasPurchasedWill &&
      !isWillStatusAwaitingApproval
    );
  },

  isHelpAvailable: (_state, _getters, rootState, rootGetters) => {
    const tool = rootGetters['tool/willHelpAssessmentTool'];
    const isWillTiersSliceThreeEnabled =
      rootState.featureFlags['will-tiers-slice-three'];
    const willTierTwoEnabled =
      rootGetters['application/affiliate/features'].willTierTwoEnabled;
    const userIsAustralian = rootGetters['directory-person/userIsAustralian'];
    const hasSourceWillId = !!rootGetters['will/data']?.sourceWillId;

    return (
      !!tool &&
      isWillTiersSliceThreeEnabled &&
      willTierTwoEnabled &&
      userIsAustralian &&
      !hasSourceWillId
    );
  },

  hasPurchasedWillTierTwo: (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['orders/purchasedProductCodes'].includes(
      'WILL_TIER_TWO'
    );
  },

  hasPurchasedWillProduct: (_state, _getters, _rootState, rootGetters) => {
    const willProducts = ['WILL', 'WILL_TIER_TWO'];
    return rootGetters['orders/purchasedProductCodes'].some((code) =>
      willProducts.includes(code)
    );
  },

  isWillProductInCart: (_state, _getters, _rootState, rootGetters) => {
    return ['WILL', 'WILL_TIER_TWO'].some((productCode) =>
      rootGetters['cart/isInCart'](productCode)
    );
  },

  isLoading: (state) => {
    return state.isLoading;
  },

  isRecommendationPageAvailable: (_state, _getters, rootState, rootGetters) => {
    const isWillTiersSliceTwoOneEnabled =
      rootState.featureFlags['will-tiers-slice-two-one'] !==
      WILL_TIERS_SLICE_TWO_ONE_VARIATION.CONTROL;
    const isWillTiersSliceThreeEnabled =
      rootState.featureFlags['will-tiers-slice-three'];
    const userIsAustralian = rootGetters['directory-person/userIsAustralian'];
    const willTierTwoEnabled =
      rootGetters['application/affiliate/features'].willTierTwoEnabled;
    const hasSourceWillId = !!rootGetters['will/data']?.sourceWillId;
    const isWillTierTwoInCart = rootGetters['cart/isInCart']('WILL_TIER_TWO');
    const onboardingNeedsAssessmentCount = rootGetters[
      'vault-item/onboardingNeedsAssessmentVaultItems'
    ].filter(({ value }) => value).length;
    const helpRequiredCount = rootGetters[
      'vault-item/willHelpAssessmentVaultItems'
    ].filter(({ helpRequired }) => helpRequired).length;

    return (
      userIsAustralian &&
      willTierTwoEnabled &&
      !hasSourceWillId &&
      !isWillTierTwoInCart &&
      ((isWillTiersSliceTwoOneEnabled && onboardingNeedsAssessmentCount > 0) ||
        (isWillTiersSliceThreeEnabled && helpRequiredCount > 0))
    );
  },
};
