import gql from 'graphql-tag';

export default gql`
  query GetPoaMeta($poaId: ID) {
    poa(id: $poaId) {
      id
      meta {
        id
        key
        value
        category
      }
    }
  }
`;
