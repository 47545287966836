import gql from 'graphql-tag';
import Coupon from '../fragments/Coupon';

export default gql`
  query getCoupons(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $customOrder: IGetCouponsCustomOrderInput
    $filters: GetCouponsFilters
  ) {
    getCoupons(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      customOrder: $customOrder
      filters: $filters
    ) {
      ...Coupon
    }
  }
  ${Coupon}
`;
