export default {
  setPublicInviteCode(state, data) {
    state.publicInviteCode = data;
  },
  setActiveInvites(state, data) {
    state.activeInvites = data;
  },
  setInvites(state, data) {
    state.invites = data;
  },
};
