import gql from 'graphql-tag';
import Flag from './Flag';

export default gql`
  fragment PlanPlanFlag on PlanPlanFlag {
    id
    status
    planSegment {
      module
      section
    }
    planId
    flagId
    flag {
      ...PlanFlag
    }
  }
  ${Flag}
`;
