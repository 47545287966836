import { mapActions } from 'vuex';

import ADD_LIABILITY_MUTATION from '@/graphql/mutations/AddLiability';
import GET_LIABILITIES_QUERY from '@/graphql/queries/GetLiabilities';
import REMOVE_LIABILITY_MUTATION from '@/graphql/mutations/RemoveLiability';
import UPDATE_LIABILITY_MUTATION from '@/graphql/mutations/UpdateLiability';
import { error } from '@/mixins/apollo';

export default {
  name: 'MixinsApolloLiabilities',
  apollo: {
    liabilities: {
      query: GET_LIABILITIES_QUERY,
      variables() {
        return {
          willId: this.willId,
        };
      },
      update: (data) => data.getLiabilities,
      result({ data }) {
        this.setLiabilities(data.getLiabilities);
      },
      skip() {
        return !this.token || !this.willId;
      },
      error,
    },
  },
  data() {
    return {
      liabilities: [],
    };
  },
  computed: {
    getLiabilitiesQuery() {
      return {
        query: GET_LIABILITIES_QUERY,
        variables: {
          willId: this.willId,
        },
      };
    },
  },
  methods: {
    ...mapActions('will', ['setLiabilities']),
    isLiability(liability) {
      return !!this.liabilities.find((a) => a.id === liability.id);
    },
    async addLiability(meta) {
      const response = await this.$apollo
        .mutate({
          mutation: ADD_LIABILITY_MUTATION,
          variables: {
            willId: this.willId,
            meta,
          },
          update: (store, { data: { addLiability } }) => {
            const data = store.readQuery(this.getLiabilitiesQuery);
            data.getLiabilities.push(addLiability);

            store.writeQuery({
              ...this.getLiabilitiesQuery,
              data,
            });
          },
        })
        .catch(error);

      return response.data.addLiability;
    },
    async removeLiability(liability) {
      await this.$apollo
        .mutate({
          mutation: REMOVE_LIABILITY_MUTATION,
          variables: {
            id: liability.id,
            willId: this.willId,
          },
          update: (store) => {
            const data = store.readQuery(this.getLiabilitiesQuery);
            const index = data.getLiabilities.findIndex(
              (m) => m.id === liability.id
            );

            if (index !== -1) {
              data.getLiabilities.splice(index, 1);

              store.writeQuery({
                ...this.getLiabilitiesQuery,
                data,
              });
            }
          },
        })
        .catch(error);
    },
    async updateLiability(liability, meta) {
      const response = await this.$apollo
        .mutate({
          mutation: UPDATE_LIABILITY_MUTATION,
          variables: {
            id: liability.id,
            willId: this.willId,
            meta,
          },
        })
        .catch(error);

      return response.data.updateLiability;
    },
  },
};
