const defaultProgress = {
  score: 0,
  target: 99,
  decimal: 0,
};

export default () => ({
  will: {
    complete: false,
    decimal: 0,
    modules: {
      aboutYourself: { ...defaultProgress },
      guardians: { ...defaultProgress },
      estate: { ...defaultProgress },
      gifts: { ...defaultProgress },
      assets: { ...defaultProgress },
      executors: { ...defaultProgress },
      funeral: { ...defaultProgress },
      review: { ...defaultProgress },
    },
  },
  poa: {
    complete: false,
    decimal: 0,
    modules: {
      aboutYourself: { ...defaultProgress },
      financial: { ...defaultProgress },
      medical: { ...defaultProgress },
      review: { ...defaultProgress },
    },
  },
});
