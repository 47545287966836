import gql from 'graphql-tag';
import Executor from '../fragments/Executor';

export default gql`
  query getExecutors($willId: ID!) {
    getExecutors(willId: $willId) {
      ...Executor
    }
  }
  ${Executor}
`;
