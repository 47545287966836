import gql from 'graphql-tag';
import Asset from '../fragments/Asset';

export default gql`
  mutation updateAsset($id: ID!, $meta: [IAssetMeta!]!, $willId: ID!) {
    updateAsset(id: $id, meta: $meta, willId: $willId) {
      ...Asset
    }
  }
  ${Asset}
`;
