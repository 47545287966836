import gql from 'graphql-tag';
import ExternalIdentifier from '../fragments/ExternalIdentifier';

export default gql`
  mutation createBulkExternalIdentifier(
    $identifiers: [ExternalIdentifierInput!]!
  ) {
    createBulkExternalIdentifier(identifiers: $identifiers) {
      ...ExternalIdentifier
    }
  }
  ${ExternalIdentifier}
`;
