export default {
  setPoaId(state, data) {
    state.poaId = data;
  },
  setStatus(state, data) {
    state.status = data;
  },
  setData: (state, data) => {
    state.data = data;
  },
  setAttorneys: (state, data) => {
    state.attorneys = data;
  },
  setInclusions: (state, data) => {
    state.inclusions = data;
  },
};
