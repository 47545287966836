import { metaArrayToObject, formatError, enrichCauseData } from '@/utilities';

import GET_EXECUTORS_QUERY from '@/graphql/queries/GetExecutors';
import GET_BENEFICIARIES_QUERY from '@/graphql/queries/GetBeneficiaries';
import GET_ASSETS_QUERY from '@/graphql/queries/GetAssets';
import GET_LIABILITIES_QUERY from '@/graphql/queries/GetLiabilities';
import GET_GUARDIANS_QUERY from '@/graphql/queries/GetGuardians';
import GET_GIFTS_QUERY from '@/graphql/queries/GetGifts';

import SUBMIT_WILL_MUTATION from '@/graphql/mutations/SubmitWill';
import VERSION_WILL_MUTATION from '@/graphql/mutations/VersionWill';
import UPDATE_WILL_STATUS_MUTATION from '@/graphql/mutations/UpdateWillStatus';

export default {
  getAllWillRelatedData({ dispatch }) {
    return Promise.all([
      dispatch('getExecutorsData'),
      dispatch('getBeneficiariesData'),
      dispatch('getAssetsData'),
      dispatch('getLiabilitiesData'),
      dispatch('getGuardiansData'),
      dispatch('getGiftsData'),
      dispatch('user/pets/getPets', null, { root: true }),
    ]);
  },
  async getExecutorsData(
    { dispatch, rootGetters: { willId } },
    adminWillId = null
  ) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_EXECUTORS_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        willId: adminWillId || willId,
      },
    });
    await dispatch('setExecutors', data.getExecutors);
  },
  async getBeneficiariesData({ dispatch, rootGetters: { willId } }) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_BENEFICIARIES_QUERY,
      variables: {
        willId,
      },
    });
    await dispatch('setBeneficiaries', data.getBeneficiaries);
  },
  async getAssetsData({ dispatch, rootGetters: { willId } }) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_ASSETS_QUERY,
      variables: {
        willId,
      },
    });
    dispatch('setAssets', data.getAssets);
  },
  async getLiabilitiesData({ dispatch, rootGetters: { willId } }) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_LIABILITIES_QUERY,
      variables: {
        willId,
      },
    });
    dispatch('setLiabilities', data.getLiabilities);
  },
  async getGuardiansData({ dispatch, rootGetters: { willId } }) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_GUARDIANS_QUERY,
      variables: {
        willId,
      },
    });
    await dispatch('setGuardians', data.getGuardians);
  },
  async getGiftsData(
    { dispatch, rootGetters: { willId } },
    adminWillId = null
  ) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_GIFTS_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        willId: adminWillId || willId,
      },
    });
    await dispatch('setGifts', data.getGifts);
  },
  setData({ commit, dispatch }, value) {
    const data = {
      ...value,
      meta: metaArrayToObject(value.meta),
    };
    commit('setData', data);
    dispatch('progress/setWillProgress', null, { root: true });
  },
  async enrichDirectoryPersons(
    { rootGetters },
    { entities, key = 'directoryPerson' }
  ) {
    for (const entity of entities) {
      entity[key] = await rootGetters['directory-person/getContactById'](
        entity[key]
      );
    }

    return entities;
  },
  async setGifts({ commit, dispatch }, value) {
    await dispatch('enrichDirectoryPersons', {
      entities: value,
      key: 'recipientDirectoryPerson',
    });

    commit('setGifts', value);
    dispatch('progress/setWillProgress', null, { root: true });

    return value;
  },
  async setPets({ commit, dispatch }, value) {
    await dispatch('enrichDirectoryPersons', {
      entities: value,
      key: 'guardianDirectoryPerson',
    });
    commit('setPets', value);
    dispatch('progress/setWillProgress', null, { root: true });

    return value;
  },
  setAssets({ commit, dispatch }, value) {
    commit('setAssets', value);
    dispatch('progress/setWillProgress', null, { root: true });
  },
  async setBeneficiaries({ commit, dispatch, rootGetters }, values) {
    // Enrich beneficiaries
    const enrichBeneficiary = async (beneficiary) => {
      beneficiary.meta = metaArrayToObject(beneficiary.meta);
      beneficiary.directoryPerson = await rootGetters[
        'directory-person/getContactById'
      ](beneficiary.directoryPerson);

      if (beneficiary.cause) {
        beneficiary.cause = enrichCauseData(beneficiary.cause);
      }
      if (beneficiary.charity) {
        beneficiary.charity.meta = metaArrayToObject(beneficiary.charity.meta);
      }
    };

    for (const beneficiary of values) {
      await enrichBeneficiary(beneficiary);

      if (beneficiary.backup) {
        for (const backup of beneficiary.backup) {
          await enrichBeneficiary(backup);
        }
      }
    }

    commit('setBeneficiaries', values);
    dispatch('progress/setWillProgress', null, { root: true });

    return values;
  },
  setLiabilities({ commit, dispatch }, value) {
    commit('setLiabilities', value);
    dispatch('progress/setWillProgress', null, { root: true });
  },
  async setExecutors({ commit, dispatch }, value) {
    await dispatch('enrichDirectoryPersons', {
      entities: value,
    });
    commit('setExecutors', value);
    dispatch('progress/setWillProgress', null, { root: true });

    return value;
  },
  async setGuardians({ commit, dispatch }, value) {
    await dispatch('enrichDirectoryPersons', {
      entities: value,
    });
    commit('setGuardians', value);
    dispatch('progress/setWillProgress', null, { root: true });

    return value;
  },
  async submitWill({ commit, rootGetters }, skipApproval = false) {
    const { data } = await this.app.apolloProvider.defaultClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: SUBMIT_WILL_MUTATION,
      variables: {
        id: rootGetters.willId,
        skipApproval,
      },
    });

    commit('setWillStatus', data.submitWill.status, { root: true });

    return data.submitWill;
  },
  async versionWill({ commit, rootGetters }) {
    const { data } = await this.app.apolloProvider.defaultClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: VERSION_WILL_MUTATION,
      variables: {
        id: rootGetters.willId,
      },
    });

    commit('setWillId', data.versionWill.id, { root: true });
    commit('setWillStatus', data.versionWill.status, { root: true });

    return data.versionWill;
  },

  async updateWillStatus({ dispatch, rootGetters }, value) {
    try {
      await this.app.apolloProvider.defaultClient.mutate({
        fetchPolicy: 'no-cache',
        mutation: UPDATE_WILL_STATUS_MUTATION,
        variables: {
          id: rootGetters.willId,
          status: value,
        },
      });

      if (rootGetters.willStatus === 'NOT_STARTED') {
        await dispatch(
          'tool/getTools',
          {
            ownerId: rootGetters.userId,
          },
          {
            root: true,
          }
        );
      }
      dispatch('setWillStatus', value, { root: true });
    } catch (error) {
      window.$nuxt.$emit('snackbar', {
        placement: 'top-right',
        type: 'error',
        text: formatError(error.message),
      });
    }
  },
  async downloadWill({ rootGetters, getters: { willFileId } }) {
    if (!rootGetters.verified) {
      return;
    }

    window.$nuxt.$emit('sendTrackingEvent', {
      event: 'download_will',
      props: {
        will_id: rootGetters.willId,
      },
    });

    await window.$nuxt.$downloadFile(willFileId);
  },
};
