import gql from 'graphql-tag';
import CartItem from '../fragments/CartItem';

export default gql`
  query getCartItemsByUserId($userId: ID!) {
    getCartItemsByUserId(userId: $userId) {
      ...CartItem
    }
  }
  ${CartItem}
`;
