import { filterNullish } from '@/utilities';
import { enrichDirectoryPersonData } from '@/utilities/directory-person';

import GET_WILLS_QUERY from '@/graphql/queries/GetWills';

function getWillsFiltersVariables(state) {
  let hasLegalAdviceBooking;
  let hasLegalAdvice;
  let willTier;

  switch (state.submissionsProductAddOnFilter) {
    case 'WITHOUT_LEGAL_ADVICE_BOOKING':
      hasLegalAdviceBooking = false;
      hasLegalAdvice = false;
      break;
    case 'WITH_LEGAL_ADVICE_BOOKING':
      hasLegalAdviceBooking = true;
      hasLegalAdvice = false;
      break;
    case 'WITH_LEGAL_ADVICE':
      hasLegalAdvice = true;
      break;
    case 'WILL':
    case 'WILL_TIER_TWO':
      willTier = state.submissionsProductAddOnFilter;
      break;
    default:
      break;
  }

  return filterNullish({
    archived: state.submissionsStatusFilter
      ? state.submissionsStatusFilter === 'ARCHIVED'
      : undefined,
    status:
      state.submissionsStatusFilter === 'ARCHIVED'
        ? undefined
        : state.submissionsStatusFilter,
    hasLegalAdviceBooking,
    hasLegalAdvice,
    willTier,
    affiliateId: state.submissionsAffiliateId ?? undefined,
  });
}

export const state = () => ({
  submissions: [],
  submissionsLoading: false,
  submissionsPageSize: 10,
  submissionsPageIndex: 0,
  submissionsStatusFilter: null,
  submissionsProductAddOnFilter: '',
  submissionsSearchQuery: '',
  submissionsSortingColumn: 'Updated',
  submissionsSortingDirection: 'ASC',
  submissionsSortableColumns: [
    {
      field: 'hashId',
      columnName: 'ID',
      defaultDirection: 'ASC',
    },
    {
      field: 'status',
      columnName: 'Status',
      defaultDirection: 'ASC',
    },
    {
      field: 'updatedAt',
      columnName: 'Updated',
      defaultDirection: 'ASC',
    },
  ],
  submissionsAffiliateId: undefined,
  submissionsWillIds: [],
});

export const getters = {
  submissions: (state) => state.submissions,
  submissionsLoading: (state) => state.submissionsLoading,
  submissionsPageSize: (state) => state.submissionsPageSize,
  submissionsPageIndex: (state) => state.submissionsPageIndex,
  submissionsStatusFilter: (state) => state.submissionsStatusFilter,
  submissionsProductAddOnFilter: (state) => state.submissionsProductAddOnFilter,
  submissionsSearchQuery: (state) => state.submissionsSearchQuery,
  submissionsSortingDirection: (state) => state.submissionsSortingDirection,
  submissionsSort: (state) => [
    state.submissionsSortingColumn,
    state.submissionsSortingDirection,
  ],
  submissionsSortableColumns: (state) =>
    Object.values(state.submissionsSortableColumns).map(
      (field) => field.columnName
    ),
  submissionsSortableFields: (state) =>
    state.submissionsSortableColumns.reduce((result, column) => {
      result[column.columnName] = column.field;
      return result;
    }, {}),
  submissionsWillIds: (state) => state.submissionsWillIds,
  submissionsAffiliateId: (state) => state.submissionsAffiliateId,
};

export const mutations = {
  SET_SUBMISSIONS_PAGE_INDEX(state, value) {
    state.submissionsPageIndex = value < 0 ? 0 : value;
  },
  SET_SUBMISSIONS_LOADING(state, value) {
    state.submissionsLoading = value;
  },
  SET_SUBMISSIONS_STATUS_FILTER(state, value) {
    state.submissionsStatusFilter = value;
  },
  SET_SUBMISSIONS_PRODUCT_ADD_ON_FILTER(state, value) {
    state.submissionsProductAddOnFilter = value;
  },
  SET_SUBMISSIONS_SEARCH_QUERY(state, value) {
    state.submissionsSearchQuery = value;
  },
  SET_SUBMISSIONS_SORTING_COLUMN(state, value) {
    state.submissionsSortingColumn = value;
  },
  SET_SUBMISSIONS_SORTING_DIRECTION(state, value) {
    state.submissionsSortingDirection = value;
  },
  SET_SUBMISSIONS_AFFILIATE_ID(state, value) {
    state.submissionsAffiliateId = value;
  },
  SET_SUBMISSIONS_WILL_IDS(state, value) {
    state.submissionsWillIds = value;
  },
  SET_SUBMISSIONS(state, value) {
    state.submissions = value;
  },
};

export const actions = {
  async setSubmissionsPageIndex({ commit, dispatch }, value) {
    commit('SET_SUBMISSIONS_PAGE_INDEX', value);
    await dispatch('fetchSubmissions');
  },
  async nextSubmissionsPage({ dispatch, state }) {
    await dispatch('setSubmissionsPageIndex', state.submissionsPageIndex + 1);
  },
  async prevSubmissionsPage({ dispatch, state }) {
    await dispatch('setSubmissionsPageIndex', state.submissionsPageIndex - 1);
  },
  async setSubmissionsStatusFilter({ dispatch, commit }, value) {
    commit('SET_SUBMISSIONS_STATUS_FILTER', value);
    await dispatch('setSubmissionsPageIndex', 0);
  },
  async setSubmissionsProductAddOnFilter({ dispatch, commit }, value) {
    commit('SET_SUBMISSIONS_PRODUCT_ADD_ON_FILTER', value);
    await dispatch('setSubmissionsPageIndex', 0);
  },
  async setSubmissionsSearchQuery({ dispatch, commit }, value) {
    commit('SET_SUBMISSIONS_SEARCH_QUERY', value);
    await dispatch('setSubmissionsPageIndex', 0);
  },
  async setSubmissionsSort({ state, commit, dispatch }, value) {
    if (state.submissionsSortingColumn === value) {
      commit(
        'SET_SUBMISSIONS_SORTING_DIRECTION',
        state.submissionsSortingDirection === 'ASC' ? 'DESC' : 'ASC'
      );
    } else {
      commit('SET_SUBMISSIONS_SORTING_COLUMN', value);
    }
    await dispatch('fetchSubmissions');
  },
  setSubmissionsWillIds({ commit }, value) {
    commit('SET_SUBMISSIONS_WILL_IDS', value);
  },
  async setSubmissionsAffiliateId({ dispatch, commit }, value) {
    commit('SET_SUBMISSIONS_AFFILIATE_ID', value);
    await dispatch('setSubmissionsPageIndex', 0);
  },
  async fetchSubmissions({ state, commit }) {
    commit('SET_SUBMISSIONS_LOADING', true);

    const {
      data: { getWills },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_WILLS_QUERY,
      variables: {
        limit: state.submissionsPageSize,
        offset: state.submissionsPageIndex * state.submissionsPageSize,
        searchQuery: state.submissionsSearchQuery.length
          ? state.submissionsSearchQuery
          : null,
        customOrder: {
          field: state.submissionsSortableColumns.find(
            (column) => column.columnName === state.submissionsSortingColumn
          ).field,
          direction: state.submissionsSortingDirection,
        },
        filters: getWillsFiltersVariables(state),
      },
    });

    const enrichedWills = [];
    for (const will of getWills) {
      const enrichedWill = { ...will };
      if (enrichedWill.user?.person) {
        const enrichedPerson = await enrichDirectoryPersonData(
          enrichedWill.user.person
        );
        enrichedWill.user.person = enrichedPerson;
      }
      enrichedWills.push(enrichedWill);
    }

    commit('SET_SUBMISSIONS_LOADING', false);
    commit('SET_SUBMISSIONS', enrichedWills);
  },
  // Return undefined when no more 'next' values exist
  async nextWillId({ state, dispatch }, currentWillId) {
    let willIds = state.submissionsWillIds;
    const index = willIds.indexOf(currentWillId);

    if (index < 0) {
      return;
    }
    if (index < willIds.length - 2) {
      return willIds[index + 1];
    }

    const currentWillLength = willIds.length;
    for (let i = 0; i < 10 && willIds.length === currentWillLength; i++) {
      await dispatch('nextSubmissionsPage');

      const nextWillIds = state.submissions.map((will) => will.id);
      if (nextWillIds.length === 0) {
        break;
      }
      willIds = [...new Set([...willIds, ...nextWillIds])];
    }

    if (willIds.length > currentWillLength) {
      dispatch('setSubmissionsWillIds', willIds);
      return willIds[index + 1];
    }

    await dispatch('prevSubmissionsPage');
  },
  // Return undefined when no more 'previous' values exist
  async previousWillId({ state, dispatch }, currentWillId) {
    let willIds = state.submissionsWillIds;
    const index = willIds.indexOf(currentWillId);

    if (index < 0) {
      return;
    }
    if (index > 0) {
      return willIds[index - 1];
    }
    const currentWillLength = willIds.length;
    for (
      let i = state.submissionsPageIndex;
      i >= 0 && willIds.length === currentWillLength;
      i--
    ) {
      await dispatch('prevSubmissionsPage');

      const previousWillIds = state.submissions.map((will) => will.id);
      if (previousWillIds.length === 0) {
        break;
      }
      willIds = [...new Set([...previousWillIds, ...willIds])];
    }
    const newIndex = willIds.indexOf(currentWillId);
    if (willIds.length > currentWillLength && newIndex > 0) {
      dispatch('setSubmissionsWillIds', willIds);
      return willIds[newIndex - 1];
    }
  },
};
