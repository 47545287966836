import gql from 'graphql-tag';
import Pet from '../fragments/Pet';

export default gql`
  mutation addPetGuardian(
    $petId: ID!
    $guardianDirectoryPersonId: ID!
    $willId: ID!
  ) {
    addPetGuardian(
      petId: $petId
      guardianDirectoryPersonId: $guardianDirectoryPersonId
      willId: $willId
    ) {
      ...Pet
    }
  }
  ${Pet}
`;
