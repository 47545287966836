import gql from 'graphql-tag';
import Partnership from '../fragments/Partnership';

export default gql`
  query GetPartnerships(
    $limit: Int
    $offset: Int
    $customOrder: PartnershipsCustomOrder
    $searchQuery: String
    $filters: PartnershipsFilters
  ) {
    getPartnerships(
      limit: $limit
      offset: $offset
      customOrder: $customOrder
      searchQuery: $searchQuery
      filters: $filters
    ) {
      ...Partnership
      activeCouponCodes
      activeCouponsCount
    }
  }
  ${Partnership}
`;
