import GET_ACCOUNT_INTERESTS_BY_USER_ID from '@/graphql/queries/GetAccountInterestsByUserId';
import CREATE_ACCOUNT_INTEREST from '@/graphql/mutations/CreateAccountInterest';

export default {
  async getAccountInterests({ commit }, userId) {
    const {
      data: { getAccountInterestsByUserId },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_ACCOUNT_INTERESTS_BY_USER_ID,
      variables: {
        userId,
      },
    });
    commit('setAccountInterests', getAccountInterestsByUserId);
  },
  async createInterest(
    { commit },
    { userId, product, country, region, brand }
  ) {
    const {
      data: { createAccountInterest },
    } = await this.app.apolloProvider.defaultClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: CREATE_ACCOUNT_INTEREST,
      variables: {
        userId,
        country,
        region,
        product,
        ...(brand && { brand }),
      },
    });
    commit('addAccountInterest', createAccountInterest);
  },
};
