import gql from 'graphql-tag';
import Institution from '../fragments/Institution';

export default gql`
  query getInstitutionsByType($type: String!) {
    getInstitutionsByType(type: $type) {
      ...Institution
    }
  }
  ${Institution}
`;
