const upperFirst = (str) => {
  if (!str) return '';
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

const upperCase = (str) => {
  if (!str) return '';
  return str.toUpperCase();
};

export default (ctx, inject) => {
  inject('upperFirst', upperFirst);
  inject('upperCase', upperCase);
};
