import gql from 'graphql-tag';
import Asset from '../fragments/Asset';

export default gql`
  mutation removeAsset($id: ID!, $willId: ID!) {
    removeAsset(id: $id, willId: $willId) {
      ...Asset
    }
  }
  ${Asset}
`;
