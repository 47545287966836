const USER_ROLES = [
  {
    text: 'Super Admin',
    value: 'SUPER_ADMIN',
  },
  {
    text: 'Partnerships Admin',
    value: 'PARTNERSHIPS_ADMIN',
  },
  {
    text: 'Admin',
    value: 'ADMIN',
  },
  {
    text: 'Moderator',
    value: 'MODERATOR',
  },
  {
    text: 'Partnership',
    value: 'PARTNERSHIP',
  },
  {
    text: 'Consumer',
    value: 'CONSUMER',
  },
];

export { USER_ROLES };
