import gql from 'graphql-tag';

export default gql`
  fragment DirectoryPerson on DirectoryPerson {
    id
    ownerId
    firstName
    middleName
    lastName
    altFirstName
    altMiddleName
    altLastName
    dateOfBirth
    countryOfBirth
    emails {
      type
      value
      verified
    }
    phoneNumbers {
      type
      value
      prefix
    }
    ageEighteenOrAbove
    residentialAddress {
      id
      streetAddress
      extendedAddress
      locality
      postcode
      region
      country
    }
    relationships {
      type
      fromPersonId
      toPersonId
    }
  }
`;
