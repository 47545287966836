export default () => ({
  status: null,
  poaId: null,
  data: {
    meta: {
      financial: {},
      medical: {},
    },
  },
  attorneys: {
    financial: {
      primary: [],
      secondary: [],
    },
    medical: {
      primary: [],
      secondary: [],
    },
  },
  inclusions: [],
});
