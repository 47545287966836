export const WILL_MODULE_PATH_MAPPING = {
  aboutYourself: '/will/about-yourself',
  guardians: '/will/guardians',
  estate: '/will/estate',
  gifts: '/will/gifts',
  assets: '/will/assets',
  executors: '/will/executors',
  funeral: '/will/funeral',
  review: '/will/review',
};

export const POA_MODULE_PATH_MAPPING = {
  aboutYourself: '/poa/about-yourself',
  financial: '/poa/financial',
  medical: '/poa/medical',
};

export default () => ({
  modules: [
    {
      id: 'about-yourself',
      key: 'aboutYourself',
      name: 'About Yourself',
      flagKey: 'ABOUT_YOURSELF',
      path: WILL_MODULE_PATH_MAPPING.aboutYourself,
      displayName: 'modules.will.aboutYourself.displayName',
      description: 'modules.will.aboutYourself.description',
      duration: [2, 4],
      totalSteps: 5,
      steps: ['name', 'date-of-birth', 'address', 'partner', 'phone'],
    },
    {
      id: 'guardians',
      key: 'guardians',
      name: 'Guardians',
      flagKey: 'FAMILY',
      path: WILL_MODULE_PATH_MAPPING.guardians,
      displayName: 'modules.will.guardians.displayName',
      description: 'modules.will.guardians.description',
      duration: [2],
      totalSteps: 6,
      steps: [
        'your-children',
        'primary',
        'backup',
        'your-pets',
        'pet-guardians',
        'pet-care-fund',
      ],
    },
    {
      id: 'estate',
      key: 'estate',
      name: 'Estate',
      flagKey: 'ESTATE',
      path: WILL_MODULE_PATH_MAPPING.estate,
      displayName: 'modules.will.estate.displayName',
      description: 'modules.will.estate.description',
      duration: [4],
      totalSteps: 8,
      steps: [
        '',
        'beneficiaries',
        'distribution',
        'backup-beneficiaries',
        'backup-distribution',
      ],
    },
    {
      id: 'gifts',
      key: 'gifts',
      name: 'Gifts',
      flagKey: 'GIFTS',
      path: WILL_MODULE_PATH_MAPPING.gifts,
      displayName: 'modules.will.gifts.displayName',
      description: 'modules.will.gifts.description',
      duration: [1, 5],
      totalSteps: 2,
      steps: [''],
    },
    {
      id: 'assets',
      key: 'assets',
      name: 'Assets',
      flagKey: 'ASSETS',
      path: WILL_MODULE_PATH_MAPPING.assets,
      displayName: 'modules.will.assets.displayName',
      description: 'modules.will.assets.description',
      duration: [2, 3],
      totalSteps: 4,
      steps: ['', 'asset-notes', 'liabilities', 'liability-notes'],
    },
    {
      id: 'executors',
      key: 'executors',
      name: 'Executors',
      flagKey: 'EXECUTORS',
      path: WILL_MODULE_PATH_MAPPING.executors,
      displayName: 'modules.will.executors.displayName',
      description: 'modules.will.executors.description',
      duration: [4],
      totalSteps: 4,
      steps: ['', 'options', 'primary', 'backup'],
    },
    {
      id: 'funeral',
      key: 'funeral',
      name: 'Funeral',
      flagKey: 'FUNERAL',
      path: WILL_MODULE_PATH_MAPPING.funeral,
      displayName: 'modules.will.funeral.displayName',
      description: 'modules.will.funeral.description',
      duration: [2, 3],
      totalSteps: 3,
      steps: ['', 'location', 'other-requests'],
    },
    {
      id: 'review',
      key: 'review',
      name: 'Review',
      path: WILL_MODULE_PATH_MAPPING.review,
      displayName: 'modules.will.review.displayName',
      description: 'modules.will.review.description',
      duration: [4],
      totalSteps: 2,
      steps: [],
    },
  ],
  poaModules: [
    {
      id: 'about-yourself',
      key: 'aboutYourself',
      name: 'About Yourself',
      path: POA_MODULE_PATH_MAPPING.aboutYourself,
      displayName: 'modules.poa.aboutYourself.displayName',
      description: 'modules.poa.aboutYourself.description',
      duration: [1, 2],
      totalSteps: 1,
      steps: [''],
    },
    {
      id: 'financial',
      key: 'financial',
      name: 'Financial',
      path: POA_MODULE_PATH_MAPPING.financial,
      displayName: 'modules.poa.financial.displayName',
      description: 'modules.poa.financial.description',
      duration: [2, 5],
      totalSteps: 2,
      steps: ['', 'primary', 'substitutes', 'spending', 'limitations'],
    },
    {
      id: 'medical',
      key: 'medical',
      name: 'Medical',
      path: POA_MODULE_PATH_MAPPING.medical,
      displayName: 'modules.poa.medical.displayName',
      description: 'modules.poa.medical.description',
      duration: [2, 5],
      totalSteps: 2,
      steps: ['', 'primary', 'substitutes', 'spending', 'limitations'],
    },
  ],
});
