import { isModeratorOrHigher } from '@/utilities';

export default function ({ app, redirect, store, route }) {
  const hasToken = !!app.$apolloHelpers.getToken();
  if (hasToken) {
    const { path } = route;
    if (!isModeratorOrHigher(store.state.role)) {
      return redirect('/log-in');
    } else if (!path.includes('/sw/admin')) {
      return redirect('/admin/submissions');
    }
  } else {
    return redirect('/log-in');
  }
}
