import { is24HoursFromNow } from '@/utilities';

export default {
  publicInviteCode: (state) => state.publicInviteCode,
  activeInvites: (state) =>
    state.activeInvites.map((invite) => ({
      ...invite,
      discountProductCodes: invite.discountProducts.map(
        (discountProduct) => discountProduct.product.code
      ),
    })),
  invites: (state) =>
    state.invites.map((invite) => ({
      ...invite,
      is24HoursFromNow: is24HoursFromNow(invite.remindedAt),
      discountProductCodes: invite.discountProducts.map(
        (discountProduct) => discountProduct.product.code
      ),
    })),
  invitedEmails: (state) => {
    return state.invites.map((invite) => invite.email);
  },
};
