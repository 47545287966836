import GET_PARTNERSHIP_QUERY from '@/graphql/queries/GetPartnership';
import UPDATE_PARTNERSHIP_MUTATION from '@/graphql/mutations/UpdatePartnership';
import { recursiveRemoveKey } from '@/utilities';
import { error } from '@/mixins/apollo';

export default {
  name: 'MixinsApolloPartnerships',
  methods: {
    getPartnershipMissingFields(partnership) {
      const missingFields = partnership.affiliateEnabled
        ? [
            !partnership.name ? 'Name' : null,
            !partnership.slug ? 'Slug' : null,
            !partnership.logo ? 'Logo' : null,
          ]
        : [
            !partnership.name ? 'Name' : null,
            !partnership.slug ? 'Slug' : null,
            !partnership.abn ? 'ABN' : null,
            !partnership.address ? 'Address' : null,
            !partnership.country ? 'Country' : null,
            !partnership.logo ? 'Logo' : null,
            !partnership.categories.length ? 'Categories' : null,
          ];

      return missingFields.filter((field) => field);
    },
    async updatePartnershipPartialData(partnershipId, props) {
      const { data } = await this.$apollo
        .query({
          query: GET_PARTNERSHIP_QUERY,
          variables: {
            id: partnershipId,
          },
        })
        .catch(error);

      const partnership = data.getPartnership;
      const mergedProps = {
        id: partnership.id,
        name: partnership.name,
        abn: partnership.abn,
        address: partnership.address,
        categories: partnership.categories,
        logo: partnership.logo,
        landingPages: partnership.landingPages,
        altNames: partnership.altNames,
        utmPrefixes: partnership.utmPrefixes,
        membershipFee: partnership.membershipFee,
        description: partnership.description,
        featuredFive: partnership.featuredFive,
        region: partnership.region,
        parentPartnershipId: partnership.parentPartnershipId,
        relatedPartnershipIds: partnership.relatedPartnershipIds,
        active: partnership.active,
        archived: partnership.archived,
        contacts: partnership.contacts,
        ...props,
      };

      recursiveRemoveKey(mergedProps, '__typename');
      recursiveRemoveKey(mergedProps, 'createdAt');
      recursiveRemoveKey(mergedProps, 'updatedAt');

      await this.$apollo
        .mutate({
          mutation: UPDATE_PARTNERSHIP_MUTATION,
          variables: {
            partnership: mergedProps,
          },
        })
        .catch(error);
    },
  },
};
