import gql from 'graphql-tag';
import Beneficiary from '../fragments/Beneficiary';

export default gql`
  mutation updateBeneficiary(
    $id: ID!
    $distribution: String
    $meta: [RequiredMetaInput!]!
    $willId: ID!
    $userId: ID!
    $isBackupEstateSplitEvenly: Boolean
  ) {
    updateBeneficiary(
      id: $id
      distribution: $distribution
      meta: $meta
      willId: $willId
      userId: $userId
      isBackupEstateSplitEvenly: $isBackupEstateSplitEvenly
    ) {
      ...Beneficiary
    }
  }
  ${Beneficiary}
`;
