import gql from 'graphql-tag';

export default gql`
  query VaultItems($ownerId: ID!, $keys: [VaultItemKey!]!, $ids: [ID!]!) {
    vaultItems(ownerId: $ownerId, keys: $keys, ids: $ids) {
      id
      ownerId
      key
      type
      name
      notes
      value
      helpRequired
    }
  }
`;
