export const state = () => ({
  confirmPasswordModalIsOpen: false,
  submitHelperModalIsOpen: false,
  subscriptionExpiredModalIsOpen: false,
  unlockWillModalIsOpen: false,
  glossaryPanelIsOpen: false,
  charityPanelIsOpen: false,
  giftWizardIsOpen: false,
  petPanelIsOpen: false,
});

export const getters = {
  confirmPasswordModalIsOpen: (state) => state.confirmPasswordModalIsOpen,
  submitHelperModalIsOpen: (state) => state.submitHelperModalIsOpen,
  subscriptionExpiredModalIsOpen: (state) =>
    state.subscriptionExpiredModalIsOpen,
  unlockWillModalIsOpen: (state) => state.unlockWillModalIsOpen,
  glossaryPanelIsOpen: (state) => state.glossaryPanelIsOpen,
  charityPanelIsOpen: (state) => state.charityPanelIsOpen,
  giftWizardIsOpen: (state) => state.giftWizardIsOpen,
  petPanelIsOpen: (state) => state.petPanelIsOpen,
};

export const mutations = {
  SET_CONFIRM_PASSWORD_MODAL_IS_OPEN(state, value) {
    state.confirmPasswordModalIsOpen = value;
  },
  SET_SUBMIT_HELPER_MODAL_IS_OPEN(state, value) {
    state.submitHelperModalIsOpen = value;
  },
  SET_SUBSCRIPTION_EXPIRED_MODAL_IS_OPEN(state, value) {
    state.subscriptionExpiredModalIsOpen = value;
  },
  SET_UNLOCK_WILL_MODAL_IS_OPEN(state, value) {
    state.unlockWillModalIsOpen = value;
  },
  SET_GLOSSARY_PANEL_IS_OPEN(state, value) {
    state.glossaryPanelIsOpen = value;
  },
  SET_CHARITY_PANEL_IS_OPEN(state, value) {
    state.charityPanelIsOpen = value;
  },
  SET_GIFT_WIZARD_IS_OPEN(state, value) {
    state.giftWizardIsOpen = value;
  },
  SET_PET_PANEL_IS_OPEN(state, value) {
    state.petPanelIsOpen = value;
  },
};

export const actions = {
  showConfirmPasswordModal({ commit }) {
    commit('SET_CONFIRM_PASSWORD_MODAL_IS_OPEN', true);
  },
  hideConfirmPasswordModal({ commit }) {
    commit('SET_CONFIRM_PASSWORD_MODAL_IS_OPEN', false);
  },
  showSubmitHelperModal({ commit }) {
    commit('SET_SUBMIT_HELPER_MODAL_IS_OPEN', true);
  },
  hideSubmitHelperModal({ commit }) {
    commit('SET_SUBMIT_HELPER_MODAL_IS_OPEN', false);
  },
  showSubscriptionExpiredModal({ commit }) {
    commit('SET_SUBSCRIPTION_EXPIRED_MODAL_IS_OPEN', true);
  },
  hideSubscriptionExpiredModal({ commit }) {
    commit('SET_SUBSCRIPTION_EXPIRED_MODAL_IS_OPEN', false);
  },
  showUnlockWillModal({ commit }) {
    commit('SET_UNLOCK_WILL_MODAL_IS_OPEN', true);
  },
  hideUnlockWillModal({ commit }) {
    commit('SET_UNLOCK_WILL_MODAL_IS_OPEN', false);
  },
  showGlossaryPanel({ commit }) {
    commit('SET_GLOSSARY_PANEL_IS_OPEN', true);
  },
  hideGlossaryPanel({ commit }) {
    commit('SET_GLOSSARY_PANEL_IS_OPEN', false);
  },
  showCharityPanel({ commit }) {
    commit('SET_CHARITY_PANEL_IS_OPEN', true);
  },
  hideCharityPanel({ commit }) {
    commit('SET_CHARITY_PANEL_IS_OPEN', false);
  },
  showGiftWizard({ commit }) {
    commit('SET_GIFT_WIZARD_IS_OPEN', true);
  },
  hideGiftWizard({ commit }) {
    commit('SET_GIFT_WIZARD_IS_OPEN', false);
  },
  showPetPanel({ commit }) {
    commit('SET_PET_PANEL_IS_OPEN', true);
  },
  hidePetPanel({ commit }) {
    commit('SET_PET_PANEL_IS_OPEN', false);
  },
  resetUI({ commit }) {
    commit('SET_CONFIRM_PASSWORD_MODAL_IS_OPEN', false);
    commit('SET_SUBMIT_HELPER_MODAL_IS_OPEN', false);
    commit('SET_SUBSCRIPTION_EXPIRED_MODAL_IS_OPEN', false);
    commit('SET_UNLOCK_WILL_MODAL_IS_OPEN', false);
    commit('SET_GLOSSARY_PANEL_IS_OPEN', false);
    commit('SET_CHARITY_PANEL_IS_OPEN', false);
    commit('SET_GIFT_WIZARD_IS_OPEN', false);
    commit('SET_PET_PANEL_IS_OPEN', false);
  },
};
