import gql from 'graphql-tag';
import Liability from '../fragments/Liability';

export default gql`
  query getLiabilities($willId: ID!) {
    getLiabilities(willId: $willId) {
      ...Liability
    }
  }
  ${Liability}
`;
