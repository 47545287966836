import camelCase from 'lodash/camelCase';

export default {
  setWhereabouts({ rootState, commit }, route) {
    const slug = rootState.application.affiliate.slug;
    const path = route.path;

    let activeProduct = null;
    if (
      path.includes(`/${slug}/onboarding/user`) ||
      path.includes(`/${slug}/onboarding/recommendation`)
    ) {
      activeProduct = 'onboarding';
    } else if (path.includes(`/${slug}/will`)) {
      activeProduct = 'will';
    } else if (
      path.includes(`/${slug}/onboarding/poa`) ||
      path.includes(`/${slug}/poa`)
    ) {
      activeProduct = 'poa';
    } else if (
      path.includes(`/${slug}/onboarding/end-of-life`) ||
      path.includes(`/${slug}/end-of-life`)
    ) {
      activeProduct = 'end-of-life';
    }

    let activeSection = null;
    if (activeProduct && path.includes(activeProduct)) {
      const parts = path.split(activeProduct)[1]?.split('/');
      activeSection = parts && parts.length > 1 ? parts[1].split('/')[0] : null;
    }
    activeProduct = activeProduct ? camelCase(activeProduct) : null;
    activeSection = activeSection ? camelCase(activeSection) : null;
    commit('setActiveProduct', activeProduct);
    commit('setActiveSection', activeSection);
  },
  setLogoVariant({ commit }, value) {
    commit('logoVariant', value);
  },
  setNavigationVariant({ commit }, value) {
    commit('navigationVariant', value);
  },
  setShouldShowCharityConsent({ commit }, { page, modal }) {
    commit('shouldShowCharityConsentPage', page);
    commit('shouldShowCharityConsentModal', modal);
  },
  resetUI({ commit }) {
    commit('logoVariant', 'safewill');
    commit('navigationVariant', 'full');
  },
};
