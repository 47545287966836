import gql from 'graphql-tag';

export default gql`
  fragment CartProduct on CartProduct {
    id
    code
    singlePurchase
    supplier
    category
  }
`;
