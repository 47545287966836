export default {
  modules: (state) => state.modules,
  poaModules: (state) => state.poaModules,
  totalSteps: (state) =>
    state.modules.reduce((total, module) => total + module.totalSteps, 0),
  moduleFlagKeys: (state) => {
    const flagKeys = {};
    state.modules.forEach((module) => {
      flagKeys[module.key] = module.flagKey;
    });
    return flagKeys;
  },
};
