import gql from 'graphql-tag';
import Partnership from '../fragments/Partnership';

export default gql`
  mutation UpdatePartnership($partnership: PartnershipModification!) {
    updatePartnership(partnership: $partnership) {
      ...Partnership
      contacts {
        id
        partnershipId
        hasDashboardAccess
        isSubscribedToEmails
        userId
      }
    }
  }
  ${Partnership}
`;
