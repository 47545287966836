import gql from 'graphql-tag';
import Will from '../fragments/Will';

export default gql`
  mutation submitWill($id: ID!, $skipApproval: Boolean) {
    submitWill(id: $id, skipApproval: $skipApproval) {
      ...Will
    }
  }
  ${Will}
`;
