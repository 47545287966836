import CREATE_TOKENISED_DIRECTORY_PERSON_MUTATION from '@/graphql/mutations/CreateTokenisedDirectoryPerson';
import UPDATE_DIRECTORY_PERSON_MUTATION from '@/graphql/mutations/UpdateDirectoryPerson';
import { SensitiveModel } from '@/plugins/sensitive/SensitiveModel';

const directoryPersonDefaults = {
  resourceCollection: 'DIRECTORY_PERSON',
  privacyTargetCollection: 'DIRECTORY_PERSON',
  createMutation: CREATE_TOKENISED_DIRECTORY_PERSON_MUTATION,
  updateMutation: UPDATE_DIRECTORY_PERSON_MUTATION,
  mutationVariables: (resourceId, data) => ({
    ...data,
    input: {
      ...data.input,
      id: resourceId,
    },
  }),
};

export const DirectoryPerson = new SensitiveModel({
  ...directoryPersonDefaults,
  privacyJsonMapping: {
    'input.partnershipConsent': 'partnership_consent',
  },
  revealJsonMapping: {
    firstName: 'first_name',
    middleName: 'middle_name',
    lastName: 'last_name',
    altFirstName: 'alt_first_name',
    altMiddleName: 'alt_middle_name',
    altLastName: 'alt_last_name',
    countryOfBirth: 'country_of_birth',
    religion: 'religion',
    sex: 'sex',
  },
  jsonMapping: {
    'input.firstName': 'first_name',
    'input.middleName': 'middle_name',
    'input.lastName': 'last_name',
    'input.altFirstName': 'alt_first_name',
    'input.altMiddleName': 'alt_middle_name',
    'input.altLastName': 'alt_last_name',
    'input.countryOfBirth': 'country_of_birth',
    'input.religion': 'religion',
    'input.sex': 'sex',
  },
});
