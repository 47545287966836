import { WILL_MODULE_PATH_MAPPING } from '../modules/state';

export default {
  will: (state) => state.will,
  willIsComplete: (state) => state.will.complete,
  willFirstIncompleteSectionPath: (state) => {
    const incompleteSectionKey = Object.keys(state.will?.modules ?? {}).find(
      (key) => state.will.modules[key].decimal < 1
    );
    return WILL_MODULE_PATH_MAPPING[incompleteSectionKey] ?? '/will';
  },
  poa: (state) => state.poa,
};
