import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { getName } from 'i18n-iso-countries';
import { IDENTIFIER_TYPE, RELATIONSHIP_TYPE } from '@/utilities/constants';
import {
  isDateLegacyFormat,
  convertLegacyDateToIsoFormat,
  convertIsoDateToLegacyDate,
  getPersonFullName,
  getPersonFullAltName,
  recursiveRemoveKey,
} from '@/utilities';

export function separateContactsByRole(ownerPersonId, directoryPersons) {
  const userDetails = directoryPersons.find(
    (person) => person.id === ownerPersonId
  );
  const partnerPersonId = userDetails.relationships.find(
    ({ type }) => type === RELATIONSHIP_TYPE.PARTNER
  )?.toPersonId;
  const partnerPerson = directoryPersons.find(
    (person) => person.id === partnerPersonId
  );

  const childrenPersonIds = userDetails.relationships
    .filter(({ type }) => type === RELATIONSHIP_TYPE.CHILD)
    .map((r) => r.toPersonId);
  const childrenPersons = directoryPersons.filter((person) =>
    childrenPersonIds.includes(person.id)
  );

  return {
    userDetails,
    partnerPerson,
    childrenPersons,
  };
}

export function getPersonDefaultEmailAddress(directoryPerson) {
  return directoryPerson.emails?.find((email) => email.type === 'DEFAULT')
    ?.value;
}

export function formatPhoneNumber(prefix, value) {
  const cleanedValue = value.replace(/\D/g, '');
  if (cleanedValue.length > 0) {
    return `${prefix ?? ''}${cleanedValue}`;
  } else {
    return '';
  }
}

export function getPersonDefaultPhoneNumber(directoryPerson) {
  const phone = directoryPerson.phoneNumbers?.find(
    (phone) => phone.type === 'DEFAULT'
  );

  if (!phone) {
    return null;
  }

  return {
    value: phone.value,
    prefix: phone.prefix,
    formatted: formatPhoneNumber(phone.prefix, phone.value),
  };
}

export function isPersonOver18(directoryPerson) {
  const personAge = getPersonAge(directoryPerson);
  return directoryPerson.ageEighteenOrAbove || personAge >= 18;
}

export function dobToAge(dateOfBirth, currentDate = new Date()) {
  if (isDateLegacyFormat(dateOfBirth)) {
    dateOfBirth = convertLegacyDateToIsoFormat(dateOfBirth);
  }
  const dob = new Date(dateOfBirth);
  const today = currentDate;
  const birthday = new Date(today.getFullYear(), dob.getMonth(), dob.getDate());
  const hadBirthday = today >= birthday;
  const age = today.getFullYear() - dob.getFullYear();
  return hadBirthday ? age : age - 1;
}

export function getPersonAge(directoryPerson) {
  if (!directoryPerson.dateOfBirth) return 0;
  return dobToAge(directoryPerson.dateOfBirth);
}

export function getCountryName(countryCode) {
  if (!countryCode) return '';
  return getName(countryCode, 'en');
}

export function getPersonFullAddress(residentialAddress) {
  if (!residentialAddress) return '';
  return [
    residentialAddress.streetAddress
      ? `${residentialAddress.streetAddress},`
      : '',
    residentialAddress.locality,
    residentialAddress.region,
    residentialAddress.postcode,
    getCountryName(residentialAddress.country),
  ]
    .filter((item) => item)
    .join(' ');
}

export function getPersonRelationshipWithUser(userDetails, personId) {
  return userDetails.relationships?.find(
    (relationship) => relationship.toPersonId === personId
  )?.type;
}

export function isValidAddress(residentialAddress) {
  if (!residentialAddress) return false;
  const { streetAddress, locality, postcode, region, country } =
    residentialAddress ?? {};
  return !!(streetAddress && locality && postcode && region && country);
}

export function getPersonIdentifierValues(directoryPerson) {
  if (isValidAddress(directoryPerson.residentialAddress)) {
    return {
      identifier: IDENTIFIER_TYPE.ADDRESS,
      identifierName: 'Address',
      identifierValue: getPersonFullAddress(directoryPerson.residentialAddress),
    };
  } else if (directoryPerson.dateOfBirth) {
    return {
      identifier: IDENTIFIER_TYPE.DOB,
      identifierName: 'Date of Birth',
      identifierValue: convertIsoDateToLegacyDate(directoryPerson.dateOfBirth),
    };
  } else {
    return {
      identifier: IDENTIFIER_TYPE.EMAIL,
      identifierName: 'Email',
      identifierValue: getPersonDefaultEmailAddress(directoryPerson),
    };
  }
}

export async function enrichDirectoryPersonData(directoryPerson) {
  if (!directoryPerson?.id) return null;

  const revealedDirectoryPerson =
    await Vue.prototype.$sensitive.DirectoryPerson.reveal(
      directoryPerson.id,
      cloneDeep(directoryPerson)
    );

  if (directoryPerson.residentialAddress?.id) {
    const revealedAddress =
      await Vue.prototype.$sensitive.DirectoryAddress.reveal(
        directoryPerson.residentialAddress.id,
        cloneDeep(directoryPerson.residentialAddress)
      );
    revealedDirectoryPerson.residentialAddress = revealedAddress;
  }

  const identifierValues = getPersonIdentifierValues(revealedDirectoryPerson);
  const enrichedData = {
    ...revealedDirectoryPerson,
    fullName: getPersonFullName(revealedDirectoryPerson),
    altFullName: getPersonFullAltName(revealedDirectoryPerson),
    defaultEmailAddress: getPersonDefaultEmailAddress(revealedDirectoryPerson),
    defaultPhoneNumber: getPersonDefaultPhoneNumber(revealedDirectoryPerson),
    age: getPersonAge(revealedDirectoryPerson),
    over18: isPersonOver18(revealedDirectoryPerson),
    residentialAddress: {
      ...revealedDirectoryPerson.residentialAddress,
      fullAddress: getPersonFullAddress(
        revealedDirectoryPerson.residentialAddress
      ),
      countryName: getCountryName(
        revealedDirectoryPerson.residentialAddress?.country
      ),
    },
    ...identifierValues,
  };
  recursiveRemoveKey(enrichedData, '__typename');

  return enrichedData;
}

export function directoryPersonToApiInput(directoryPerson) {
  if (!directoryPerson) return null;
  const {
    id,
    firstName,
    middleName,
    lastName,
    altFirstName,
    altMiddleName,
    altLastName,
    ownerId,
    dateOfBirth,
    countryOfBirth,
    ageEighteenOrAbove,
    emails,
    defaultPhoneNumber,
    phoneNumbers,
    residentialAddress,
    defaultEmailAddress,
    relationships,
  } = directoryPerson;
  const result = {
    ...(id ? { id } : {}),
    firstName,
    middleName,
    lastName,
    altFirstName,
    altMiddleName,
    altLastName,
    ownerId,
    dateOfBirth,
    countryOfBirth,
    relationships,
    phoneNumbers,
  };
  if (dateOfBirth) {
    result.dateOfBirth = dateOfBirth;
  } else {
    result.ageEighteenOrAbove = ageEighteenOrAbove;
  }

  const nonDefaultPhoneNumbers =
    phoneNumbers?.filter((phoneNumber) => phoneNumber.type !== 'DEFAULT') || [];

  if (defaultPhoneNumber) {
    result.phoneNumbers = [
      {
        type: 'DEFAULT',
        value: defaultPhoneNumber.value,
        prefix: defaultPhoneNumber.prefix,
      },
      ...nonDefaultPhoneNumbers,
    ];
  } else if (defaultPhoneNumber !== undefined) {
    result.phoneNumbers = [...nonDefaultPhoneNumbers];
  }

  const nonDefaultEmails =
    emails?.filter((email) => email.type !== 'DEFAULT') || [];

  if (defaultEmailAddress) {
    const oldDefaultEmailAddresses =
      getPersonDefaultEmailAddress(directoryPerson);
    if (oldDefaultEmailAddresses === defaultEmailAddress) {
      result.emails = emails;
    } else {
      result.emails = [
        {
          type: 'DEFAULT',
          value: defaultEmailAddress,
          verified: false,
        },
        ...nonDefaultEmails,
      ];
    }
  } else if (defaultEmailAddress !== undefined) {
    result.emails = [...nonDefaultEmails];
  }

  if (residentialAddress?.streetAddress) {
    result.residentialAddress = {
      id: residentialAddress?.id,
      streetAddress: residentialAddress.streetAddress,
      extendedAddress: residentialAddress.extendedAddress,
      locality: residentialAddress.locality,
      region: residentialAddress.region,
      postcode: residentialAddress.postcode,
      country: residentialAddress.country,
    };
  } else if (directoryPerson.relationshipWithUser) {
    result.residentialAddress = {
      id: residentialAddress?.id,
      streetAddress: null,
      extendedAddress: null,
      locality: null,
      region: null,
      postcode: null,
      country: null,
    };
  }

  return result;
}

export function isSameRelationship(relationship1, relationship2) {
  return (
    relationship1?.fromPersonId === relationship2?.fromPersonId &&
    relationship1?.toPersonId === relationship2?.toPersonId &&
    relationship1?.type === relationship2?.type
  );
}
