import gql from 'graphql-tag';
import Asset from '../fragments/Asset';

export default gql`
  mutation addAsset($willId: ID!, $meta: [IAssetMeta!]!) {
    addAsset(willId: $willId, meta: $meta) {
      ...Asset
    }
  }
  ${Asset}
`;
