import gql from 'graphql-tag';
import User from '../fragments/User';

export default gql`
  mutation updateUser(
    $userId: ID
    $affiliateUserId: String
    $couponCode: String
  ) {
    updateUser(
      userId: $userId
      userData: { affiliateUserId: $affiliateUserId, couponCode: $couponCode }
    ) {
      user {
        ...User
      }
    }
  }
  ${User}
`;
