import gql from 'graphql-tag';
import Invite from '../fragments/Invite';

export default gql`
  query getActiveInvitesForRecipient($userId: ID!, $type: InviteType) {
    getActiveInvitesForRecipient(userId: $userId, type: $type) {
      ...Invite
    }
  }
  ${Invite}
`;
