import gql from 'graphql-tag';
import BeneficiaryMeta from './BeneficiaryMeta';
import Charity from './Charity';
import Cause from './Cause';
import DirectoryPerson from './DirectoryPerson';

export default gql`
  fragment Beneficiary on Beneficiary {
    id
    meta {
      ...BeneficiaryMeta
    }
    directoryPerson {
      ...DirectoryPerson
    }
    charity {
      ...Charity
    }
    cause {
      ...Cause
    }
    distribution
    isBackupEstateSplitEvenly
    type
    backup {
      id
      meta {
        ...BeneficiaryMeta
      }
      directoryPerson {
        ...DirectoryPerson
      }
      charity {
        ...Charity
      }
      cause {
        ...Cause
      }
      distribution
      type
    }
  }
  ${BeneficiaryMeta}
  ${Charity}
  ${Cause}
  ${DirectoryPerson}
`;
