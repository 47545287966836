import VAULT_ITEM_QUERY from '@/graphql/queries/VaultItems';

export default {
  async getVaultItems({ commit, rootGetters }, { keys, ids, ownerId }) {
    const userId = ownerId || rootGetters?.userId;
    if (!userId) {
      commit('setVaultItems', []);
      return [];
    }
    const { data: vaultItems } =
      await this.app.apolloProvider.defaultClient.query({
        query: VAULT_ITEM_QUERY,
        variables: {
          ownerId: userId,
          keys: keys || [],
          ids: ids || [],
        },
      });

    const items = vaultItems?.vaultItems ?? [];
    commit('setVaultItems', items);
    return items;
  },
  async getOnboardingNeedsAssessmentVaultItems({ dispatch, commit }, { ids }) {
    const items = await dispatch('getVaultItems', { ids });
    commit('setOnboardingNeedsAssessmentVaultItems', items);
  },
  async getWillHelpAssessmentVaultItems(
    { dispatch, commit },
    { ids, ownerId }
  ) {
    const items = await dispatch('getVaultItems', { ids, ownerId });
    commit('setWillHelpAssessmentVaultItems', items);
  },
  async saveHelpRequiredVaultItem(
    { dispatch },
    { tool, vaultItemKey, vaultItemType, helpRequired }
  ) {
    if (!tool) {
      return;
    }
    const vaultItems = await dispatch('getVaultItems', {
      ids: tool.vaultItemIds || [],
    });
    const vaultItem = vaultItems.find(
      (item) =>
        item.key === vaultItemKey && item.type === (vaultItemType || null)
    );
    const newTool = await dispatch(
      'tool/upsertVaultItemsByTool',
      {
        toolId: tool.id,
        vaultItems: [{ key: vaultItemKey, type: vaultItemType, helpRequired }],
        deletedVaultItemIds: vaultItem?.id ? [vaultItem.id] : undefined,
      },
      {
        root: true,
      }
    );
    await dispatch('getWillHelpAssessmentVaultItems', {
      ids: newTool.vaultItemIds || [],
    });
  },
};
