import GET_FILE_DOWNLOAD_URL from '@/graphql/queries/GetFileDownloadUrl';

export async function downloadFile(ctx, fileId, userId) {
  if (!fileId) {
    console.trace('No file ID supplied.');
    return;
  }

  const { data } = await ctx.app.apolloProvider.defaultClient.query({
    fetchPolicy: 'no-cache',
    query: GET_FILE_DOWNLOAD_URL,
    variables: {
      userId: userId ?? ctx.store.getters?.userId,
      fileId,
    },
  });

  window.location.assign(data.getFileDownloadUrl);
}

export default (ctx, inject) => {
  inject(
    'downloadFile',
    async (fileId, userId) => await downloadFile(ctx, fileId, userId)
  );
};
