import gql from 'graphql-tag';
import Charity from '../fragments/Charity';

export default gql`
  mutation addCharity(
    $willId: ID!
    $meta: [RequiredMetaInput!]!
    $partnershipId: ID
  ) {
    addCharity(willId: $willId, meta: $meta, partnershipId: $partnershipId) {
      ...Charity
    }
  }
  ${Charity}
`;
