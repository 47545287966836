import gql from 'graphql-tag';

export default gql`
  query getPrivacyVaultTarget(
    $resourceId: ID!
    $resourceCollection: ResourceTargetCollection!
    $userId: ID
  ) {
    getPrivacyVaultTarget(
      privacyVaultAccessPayload: {
        operation: READ
        resourceTarget: {
          targetId: $resourceId
          targetCollection: $resourceCollection
        }
      }
      userId: $userId
    ) {
      privacyVaultTarget {
        targetCollection
        targetId
      }
    }
  }
`;
