import { getField } from 'vuex-map-fields';
import { RELATIONSHIP_TYPE } from '@/utilities/constants';
import { enrichDirectoryPersonData } from '@/utilities/directory-person';

function getContactsWithRelationships(contacts, user, relationships) {
  return contacts.filter(({ id }) => {
    return user.relationships.some(
      ({ toPersonId, type }) =>
        toPersonId === id && relationships.includes(type)
    );
  });
}

async function getEnrichedContact(contacts, directoryPerson) {
  if (!directoryPerson) return;

  const personContact = contacts.find(
    (contact) => contact.id === directoryPerson.id
  );

  return personContact || (await enrichDirectoryPersonData(directoryPerson));
}

export default {
  getField,
  userDetails(state) {
    return state.user;
  },
  userIsAustralian(state) {
    return (
      !state.user.residentialAddress?.country ||
      state.user.residentialAddress.country === 'AU'
    );
  },
  userState(state) {
    return state.user?.residentialAddress?.region?.toLowerCase();
  },
  contacts(state) {
    return state.contacts;
  },
  contactsWithoutRelationships({ contacts, user }) {
    return contacts.filter(({ id }) => {
      return !user.relationships.some(({ toPersonId }) => toPersonId === id);
    });
  },
  contactsWithRelationship({ contacts, user }) {
    return (relationships) =>
      getContactsWithRelationships(contacts, user, relationships);
  },
  userPartner({ contacts, user }) {
    const partners = getContactsWithRelationships(
      contacts,
      user,
      RELATIONSHIP_TYPE.PARTNER
    );
    return partners.length ? partners[0] : null;
  },
  userHasPartner(_, { userPartner }) {
    return !!userPartner;
  },
  userChildren({ contacts, user }) {
    return getContactsWithRelationships(
      contacts,
      user,
      RELATIONSHIP_TYPE.CHILD
    );
  },
  isUserChild:
    ({ user }) =>
    (contact) => {
      return user.relationships.some(
        ({ toPersonId, type }) =>
          toPersonId === contact.id && type === RELATIONSHIP_TYPE.CHILD
      );
    },
  userHasChildren(_, { userChildren }) {
    return userChildren.length > 0;
  },
  getContactById: (state) => async (directoryPerson) =>
    await getEnrichedContact(state.contacts, directoryPerson),
};
