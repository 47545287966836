import { recursiveRemoveKey } from '@/utilities';
export default {
  setTools: (state, tools) => {
    recursiveRemoveKey(tools, '__typename');
    state.tools = tools;
  },
  addTool: (state, tool) => {
    recursiveRemoveKey(tool, '__typename');
    state.tools.push(tool);
  },
  updateTool: (state, updatedTool) => {
    const index = state.tools.findIndex((tool) => tool.id === updatedTool.id);
    recursiveRemoveKey(updatedTool, '__typename');
    if (index !== -1) {
      state.tools.splice(index, 1, updatedTool);
    }
  },
};
