import cloneDeep from 'lodash/cloneDeep';

export default {
  setData: (state, data) => {
    state.data = data;
  },
  setGifts: (state, data) => {
    state.gifts = [...data];
  },
  setAssets: (state, data) => {
    state.assets = [...data];
  },
  setLiabilities: (state, data) => {
    state.liabilities = [...data];
  },
  setExecutors: (state, data) => {
    state.executors = [...data];
  },
  setBeneficiaries: (state, data) => {
    state.beneficiaries = cloneDeep(data);
  },
  setPets: (state, data) => {
    state.pets = [...data];
  },
  setGuardians: (state, data) => {
    state.guardians = [...data];
  },
};
