export default {
  setWillProgress(state, data) {
    state.will = {
      ...data,
    };
  },
  setPoaProgress(state, data) {
    state.poa = {
      ...data,
    };
  },
};
