import gql from 'graphql-tag';

export default gql`
  query getOrders($userId: ID!) {
    getOrders(userId: $userId) {
      id
      valueInCents
      paymentMethod
      externalPaymentId
      discountId
      currency
      taxValue
      status
      utm {
        utm_source
        utm_medium
        utm_campaign
        utm_term
        utm_content
      }
      createdAt
      orderItems {
        id
        valueInCents
        currency
        product
        cancelled
        createdAt
      }
      couponCode
      inviteCode
    }
  }
`;
