import gql from 'graphql-tag';
import Tool from '../fragments/Tool';

export default gql`
  mutation upsertVaultItemsByTool($input: UpsertVaultItemsByToolInput!) {
    upsertVaultItemsByTool(input: $input) {
      ...Tool
    }
  }
  ${Tool}
`;
