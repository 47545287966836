export const state = () => ({
  invitedByPartner: false,
  paymentMethod: 'stripe',
});

export const getters = {
  invitedByPartner: (state) => state.invitedByPartner,
  paymentMethod: (state) => state.paymentMethod,
};

export const mutations = {
  setInvitedByPartner(state, value) {
    state.invitedByPartner = value;
  },
  setPaymentMethod(state, value) {
    state.paymentMethod = value;
  },
};

export const actions = {
  setInvitedByPartner({ commit }, value) {
    commit('setInvitedByPartner', value);
  },
  setPaymentMethod({ commit }, value) {
    commit('setPaymentMethod', value);
  },
  changePaymentMethod({ commit }, value) {
    commit('setPaymentMethod', value);
  },
  resetCheckoutState({ commit }) {
    commit('setInvitedByPartner', false);
    commit('setPaymentMethod', 'stripe');
  },
};
