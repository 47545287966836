import gql from 'graphql-tag';
import CartProduct from '../fragments/CartProduct';

export default gql`
  query Products($userId: ID!) {
    products(userId: $userId) {
      ...CartProduct
    }
  }
  ${CartProduct}
`;
