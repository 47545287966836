import gql from 'graphql-tag';
import WillMeta from './WillMeta';
import Coupon from './Coupon';
import Invite from './Invite';

export default gql`
  fragment Will on Will {
    id
    hashId
    sourceWillId
    createdAt
    updatedAt
    email
    userId
    status
    archived
    fileId
    wordFileId
    willFileId
    transferAffiliateId
    transferredAt
    isPrimaryEstateSplitEvenly
    meta {
      ...WillMeta
    }
    coupon {
      ...Coupon
    }
    invite {
      ...Invite
    }
  }
  ${WillMeta}
  ${Coupon}
  ${Invite}
`;
