import gql from 'graphql-tag';
import EndOfLifeCategory from '../fragments/EndOfLifeCategory';

export default gql`
  query getAllEndOfLifeCategories {
    getAllEndOfLifeCategories {
      endOfLifeCategories {
        ...EndOfLifeCategory
      }
    }
  }
  ${EndOfLifeCategory}
`;
