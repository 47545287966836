import { getAlpha2Code, getNames, registerLocale } from 'i18n-iso-countries';

export default (_ctx, inject) => {
  registerLocale(require('i18n-iso-countries/langs/en.json'));
  const countriesName = Object.values(getNames('en')).sort();
  const countriesOptions = countriesName.sort().map((countryName) => ({
    text: countryName,
    value: getAlpha2Code(countryName, 'en'),
  }));
  const localeCountriesOptions = countriesOptions.filter(({ value }) =>
    ['AU', 'NZ'].includes(value)
  );

  inject('countries', countriesName);
  inject('countriesOptions', countriesOptions);
  inject('localeCountriesOptions', localeCountriesOptions);
};
