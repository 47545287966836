import gql from 'graphql-tag';

export default gql`
  fragment ExternalIdentifier on ExternalIdentifier {
    id
    ownerId
    key
    value
    type
  }
`;
