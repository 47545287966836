import gql from 'graphql-tag';
import Coupon from '../fragments/Coupon';

export default gql`
  query getCoupon($id: ID!) {
    getCoupon(id: $id) {
      ...Coupon
    }
  }
  ${Coupon}
`;
