import gql from 'graphql-tag';
import Gift from '../fragments/Gift';

export default gql`
  query getGifts($willId: ID!) {
    getGifts(willId: $willId) {
      ...Gift
    }
  }
  ${Gift}
`;
