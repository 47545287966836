import gql from 'graphql-tag';

export default gql`
  mutation updateNotifyCharityOfWill($userId: ID!, $value: Boolean!) {
    updateNotifyCharityOfWill(userId: $userId, value: $value) {
      success
      message
    }
  }
`;
