export const state = () => ({
  usersPageIndex: 0,
  usersSearchQuery: '',
  userRoleFilter: null,
});

export const getters = {
  usersPageIndex: (state) => state.usersPageIndex,
  usersSearchQuery: (state) => state.usersSearchQuery,
  userRoleFilter: (state) => state.userRoleFilter,
};

export const mutations = {
  SET_USERS_PAGE_INDEX(state, value) {
    state.usersPageIndex = value;
  },
  SET_USERS_SEARCH_QUERY(state, value) {
    state.usersSearchQuery = value;
  },
  SET_USER_ROLE_FILTER(state, value) {
    state.userRoleFilter = value;
  },
};

export const actions = {
  nextUsersPage({ commit, state }) {
    commit('SET_USERS_PAGE_INDEX', state.usersPageIndex + 1);
  },
  prevUsersPage({ commit, state }) {
    if (state.usersPageIndex > 0) {
      commit('SET_USERS_PAGE_INDEX', state.usersPageIndex - 1);
    }
  },
  resetUsersPageIndex({ commit }) {
    commit('SET_USERS_PAGE_INDEX', 0);
  },
  setUsersSearchQuery({ commit }, value) {
    commit('SET_USERS_SEARCH_QUERY', value);
  },
  setUserRoleFilter({ commit }, value) {
    commit('SET_USER_ROLE_FILTER', value);
  },
};
