import gql from 'graphql-tag';

export default gql`
  query getSubscription($userId: ID!) {
    getSubscription(userId: $userId) {
      id
      stripeSubscriptionId
      autoRenew
      paymentMethodExpiresAt
      expiresAt
      createdAt
    }
  }
`;
