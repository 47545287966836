export default {
  subscription: (state) => state.subscription,
  isValid: (state) => {
    if (!state.subscription) {
      return true;
    }
    const now = new Date();
    const expiryDate = new Date(Number(state.subscription.expiresAt));
    return now < expiryDate;
  },
};
