import Vue from 'vue';
import isFunction from 'lodash/isFunction';

export function autoSaveDirective(el, binding, vnode, oldVnode) {
  const { save } = binding.value;
  const hasValueChanged = vnode.data.model.value !== oldVnode.data.model.value;

  if (isFunction(save) && hasValueChanged) {
    save(async () => {
      let valid;

      if (vnode.componentInstance.$refs.provider) {
        const response =
          await vnode.componentInstance.$refs.provider.validate();
        valid = response.valid;
      } else {
        valid = el.closest('form').checkValidity();
      }

      return valid;
    });
  }
}

// Directive to trigger auto-save on Form Control value change
Vue.directive('autoSave', {
  update: autoSaveDirective,
});
