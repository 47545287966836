import gql from 'graphql-tag';

export default gql`
  fragment EndOfLifeCategory on EndOfLifeCategory {
    id
    parentCategoryId
    slug
    name
    description
    explanation
    createdAt
    updatedAt
    categoryOrder
  }
`;
