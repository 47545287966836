import gql from 'graphql-tag';
import AdminUser from './AdminUser';

export default gql`
  fragment AdminWill on AdminWill {
    id
    hashId
    createdAt
    updatedAt
    status
    archived
    user {
      ...AdminUser
    }
  }

  ${AdminUser}
`;
