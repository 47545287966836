import { mapActions, mapGetters } from 'vuex';
import REMOVE_GIFT_MUTATION from '@/graphql/mutations/RemoveGift';

export default {
  name: 'MixinsApolloGifts',
  data() {
    return {
      removingGiftIds: [],
    };
  },
  computed: {
    ...mapGetters(['willId']),
    ...mapGetters('will', ['gifts']),
  },
  methods: {
    ...mapActions('will', ['getGiftsData', 'setGifts']),
    getGiftFromEntity(entity) {
      return this.gifts.find(
        (gift) =>
          (gift.charity && gift.charity.id === entity.id) ||
          gift?.recipientDirectoryPerson?.id === entity.id
      );
    },
    isGiftRecipient(entity) {
      return !!this.getGiftFromEntity(entity);
    },
    async removeAllGifts() {
      await Promise.all(this.gifts.map(this.removeGift));
      await this.setGifts([]);
    },
    async removeGift(gift) {
      try {
        if (this.removingGiftIds.includes(gift.id)) {
          return;
        }

        this.removingGiftIds.push(gift.id);

        await this.$apollo.mutate({
          mutation: REMOVE_GIFT_MUTATION,
          variables: {
            id: gift.id,
            willId: this.willId,
          },
        });

        this.removingGiftIds = this.removingGiftIds.filter(
          (id) => id !== gift.id
        );
        await this.setGifts(this.gifts.filter((m) => m.id !== gift.id));
      } catch (error) {
        console.error(error);
        await this.getGiftsData();
      }
    },
  },
};
