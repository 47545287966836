import gql from 'graphql-tag';

export default gql`
  query checkPoaPurchased($userId: ID!, $poaId: ID) {
    poa(id: $poaId) {
      id
      status
    }
    getPurchasedProducts(userId: $userId) {
      products
    }
  }
`;
