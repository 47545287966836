import gql from 'graphql-tag';
import ExecutorMeta from './ExecutorMeta';
import DirectoryPerson from './DirectoryPerson';

export default gql`
  fragment Executor on Executor {
    id
    meta {
      ...ExecutorMeta
    }
    type
    directoryPerson {
      ...DirectoryPerson
    }
  }
  ${ExecutorMeta}
  ${DirectoryPerson}
`;
