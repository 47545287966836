import { getPathWithoutLocale } from '@/utilities/index';
import GET_PURCHASED_PRODUCTS_QUERY from '@/graphql/queries/GetPurchasedProducts';

const forbiddenPathsForNzl = [
  '/poa',
  '/executors/professional-terms',
  '/executors/options',
];

export default async function ({
  redirect,
  store,
  route,
  localePath,
  i18n,
  switchLocalePath,
  app,
}) {
  const affiliate = store.state.application.affiliate;

  const country =
    store.state['directory-person']?.user?.residentialAddress?.country || 'AU';

  // we get path to check if it includes affiliate slug
  // checking fullPath may cause issues as affiliate slug can be in the query params
  const { path, fullPath } = route;
  const affiliateSlug = affiliate.slug;

  // check if there is a mismatch in user's country and the current url locale
  if (country.toLowerCase() !== i18n.locale) {
    i18n.locale = country.toLowerCase();
  }

  if (
    !path.includes('/log-out') &&
    affiliate.slug !== 'sw' &&
    (await hasPurchasedWill(app, store))
  ) {
    store.dispatch('application/affiliate/resetAffiliate');
    return redirect(localePath('/sw'));
  }

  // TODO: Improve this to handle if dashboard is disabled
  if (
    isAllowedAffiliateRedirect(fullPath, affiliate.features) &&
    isAllowedLocaleRedirect(path, i18n.locale)
  ) {
    const pathWithoutLocale = getPathWithoutLocale(fullPath, i18n.localeCodes);
    // making sure the affiliate slug is not in a query param
    if (!path.includes(affiliateSlug)) {
      const redirectPath = `/${affiliateSlug}${pathWithoutLocale}`;
      return redirect(localePath(redirectPath));
    }
    return switchLocalePath(fullPath);
  } else {
    const redirectPath = `/${affiliateSlug}/`;
    return redirect(localePath(redirectPath));
  }
}

async function hasPurchasedWill(app, store) {
  if (!store.state.auth.loggedIn) {
    return false;
  }
  const getPurchasedProducts = await app.apolloProvider.defaultClient.query({
    fetchPolicy: 'no-cache',
    query: GET_PURCHASED_PRODUCTS_QUERY,
    variables: {
      userId: store.state.userId,
    },
  });
  return getPurchasedProducts?.data?.getPurchasedProducts?.products?.includes(
    'WILL'
  );
}

function isAllowedAffiliateRedirect(path, features) {
  if (!path) {
    return false;
  }
  if (path.includes('/will')) {
    return features.willEnabled;
  }
  if (path.includes('/power-of-attorney') || path.includes('/poa')) {
    return features.powerOfAttorneyEnabled;
  }
  if (path.includes('/end-of-life')) {
    return features.endOfLifeEnabled;
  }
  if (path.includes('/gifts') && !path.includes('/will/gifts')) {
    return features.giftEnabled;
  }
  return true;
}

function isAllowedLocaleRedirect(path, locale) {
  if (locale === 'nz') {
    const pathIsForbidden = forbiddenPathsForNzl.some((forbiddenPath) => {
      if (forbiddenPath === '/poa' && path.includes('/onboarding')) {
        return false;
      }
      return path.includes(forbiddenPath);
    });
    return !pathIsForbidden;
  }
  return true;
}
