import { v4 as uuid } from 'uuid';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import set from 'lodash/set';
import get from 'lodash/get';
import unset from 'lodash/unset';
import { isNullish } from '@/utilities';

function objectDeepKeys(obj) {
  const keys = [];

  for (const key in obj) {
    if (isNullish(obj[key])) {
      continue;
    }

    if (obj[key] instanceof Object) {
      keys.push(...objectDeepKeys(obj[key]).map((k) => `${key}.${k}`));
      continue;
    }

    keys.push(key);
  }

  return keys;
}

export class SensitiveModel {
  static $apollo;
  static $vault;
  static $sentry;

  resourceCollection;
  privacyTargetCollection;
  createMutation;
  updateMutation;
  jsonMapping;
  revealJsonMapping;
  privacyJsonMapping;
  metaMapping;
  mutationVariables;

  static initialise($apollo, $vault, $sentry) {
    SensitiveModel.$apollo = $apollo;
    SensitiveModel.$vault = $vault;
    SensitiveModel.$sentry = $sentry;
  }

  constructor({
    resourceCollection,
    creationResolver = (data) => Object.values(data)[0],
    mutationVariables = (resourceId, tokenisedData) => ({
      ...tokenisedData,
      id: resourceId,
    }),
    privacyTargetCollection = resourceCollection,
    createMutation,
    updateMutation,
    jsonMapping,
    revealJsonMapping = jsonMapping,
    privacyJsonMapping = {},
    metaMapping = [],
  }) {
    this.resourceCollection = resourceCollection;
    this.creationResolver = creationResolver;
    this.mutationVariables = mutationVariables;
    this.privacyTargetCollection = privacyTargetCollection;
    this.createMutation = createMutation;
    this.updateMutation = updateMutation;
    this.jsonMapping = { ...jsonMapping, ...privacyJsonMapping };
    this.revealJsonMapping = revealJsonMapping;
    this.privacyJsonMapping = privacyJsonMapping;
    this.metaMapping = metaMapping;
  }

  #mapMetaToObject(data) {
    const mappedData = merge({}, data);
    this.metaMapping.forEach((key) => {
      set(
        mappedData,
        key,
        Object.fromEntries(get(data, key).map(({ key, value }) => [key, value]))
      );
    });
    return mappedData;
  }

  #mapObjectToMeta(metaData) {
    const mappedData = merge({}, metaData);
    this.metaMapping.forEach((metaKey) => {
      set(
        mappedData,
        metaKey,
        Object.entries(get(metaData, metaKey)).map(([key, value]) => ({
          key,
          value,
        }))
      );
    });
    return mappedData;
  }

  // Remove sensitive data from creation object
  // Needs much more for nested objects, other Mappers, arrays etc
  #strip(data) {
    const mappedData = this.#mapMetaToObject(data);
    const safe = merge({}, mappedData);
    const sensitive = {};

    Object.keys(this.jsonMapping).forEach((sensitiveKey) => {
      set(sensitive, sensitiveKey, get(mappedData, sensitiveKey));
      unset(safe, sensitiveKey);
    });

    return {
      safe: this.#mapObjectToMeta(safe),
      sensitive,
    };
  }

  // Add tokenised values back into data
  #merge(data, tokens) {
    return mergeWith(merge({}, data), tokens, (objValue, srcValue) => {
      if (typeof objValue === 'string' && srcValue === undefined) {
        return '';
      }
    });
  }

  #mapToPrivacyFields(sensitive) {
    return Object.entries(this.jsonMapping).reduce(
      (privacyVaultFields, [apiField, privacyField]) => {
        set(privacyVaultFields, privacyField, get(sensitive, apiField));
        return privacyVaultFields;
      },
      {}
    );
  }

  #mapToApiFields(tokens, jsonMapping = this.jsonMapping) {
    return this.#mapObjectToMeta(
      Object.entries(jsonMapping).reduce(
        (apiFields, [apiField, privacyField]) => {
          set(apiFields, apiField, get(tokens, privacyField));
          return apiFields;
        },
        {}
      )
    );
  }

  #stripPrivacyOnlyFields(data) {
    const safe = merge({}, data);

    Object.keys(this.privacyJsonMapping).forEach((privacyKey) => {
      unset(safe, privacyKey);
    });

    return safe;
  }

  #addSentryBreadcrumb(message, data = {}) {
    SensitiveModel.$sentry.addBreadcrumb({
      type: 'debug',
      category: 'sensitive-model',
      level: 'info',
      message,
      data: {
        resourceCollection: this.resourceCollection,
        ...data,
      },
    });
  }

  async create(ownerId, data) {
    try {
      this.#addSentryBreadcrumb('Creating sensitive model');
      return await this.#upsert(ownerId, uuid(), data, false);
    } catch (error) {
      SensitiveModel.$sentry.captureException(error);
      throw error;
    } finally {
      this.#addSentryBreadcrumb('Created sensitive model');
    }
  }

  async #upsert(ownerId, resourceId, data, isUpdate = true) {
    const mutation = isUpdate ? this.updateMutation : this.createMutation;

    const { sensitive } = this.#strip(data);
    let tokens = {};

    this.#addSentryBreadcrumb('Upsert sensitive model', {
      keys: objectDeepKeys(sensitive),
    });

    if (Object.keys(sensitive).length > 0) {
      let targetId;
      ({
        privacyVaultTarget: { targetId },
        tokens,
      } = await SensitiveModel.$vault.save({
        privacyVaultTargetCollection: this.privacyTargetCollection,
        ownerId,
        resourceTargetId: resourceId,
        record: this.#mapToPrivacyFields(sensitive),
      }));

      this.#addSentryBreadcrumb('Saved sensitive model PII');

      await SensitiveModel.$vault.setPrivacyVaultTarget({
        resourceTarget: {
          resourceId,
          resourceCollection: this.resourceCollection,
        },
        privacyVaultTarget: {
          targetId,
          targetCollection: this.privacyTargetCollection,
        },
      });
    } else {
      // This should only get called if there is no sensitive data to save
      // as a result of the strip function with jsonMapping = {}
      this.#addSentryBreadcrumb('No sensitive data to save');
    }

    const tokenisedData = this.#merge(
      this.#stripPrivacyOnlyFields(data),
      this.#mapToApiFields(tokens)
    );
    const variables = this.mutationVariables(resourceId, tokenisedData);

    if (!mutation) {
      return variables;
    }

    const response = await SensitiveModel.$apollo.mutate({
      mutation,
      variables,
    });

    return this.creationResolver(response.data);
  }

  async update(ownerId, resourceId, data) {
    try {
      this.#addSentryBreadcrumb('Updating sensitive model');
      return await this.#upsert(ownerId, resourceId, data, true);
    } catch (error) {
      SensitiveModel.$sentry.captureException(error);
      throw error;
    } finally {
      this.#addSentryBreadcrumb('Updated sensitive model');
    }
  }

  async reveal(resourceId, data) {
    let tokens;

    if (data && SensitiveModel.$vault.hasTokenisedData(data)) {
      tokens = await SensitiveModel.$vault.revealByResourceTarget({
        resourceTarget: {
          resourceId,
          resourceCollection: this.resourceCollection,
        },
      });
    }

    return tokens
      ? this.#merge(data, this.#mapToApiFields(tokens, this.revealJsonMapping))
      : data;
  }

  setMasqueradeUserId(masqueradeUserId) {
    SensitiveModel.$vault.setMasqueradeUserId(masqueradeUserId);
  }
}
