/* c8 ignore start */
const securityLink = {
  au: 'https://safewill.com/security',
  nz: 'https://safewill.com/nz/security',
};

const privacyLink = {
  au: 'https://safewill.com/privacy',
  nz: 'https://safewill.com/nz/privacy',
};

const WILL_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  APPROVED: 'APPROVED',
  FLAGGED: 'FLAGGED',
  ARCHIVED: 'ARCHIVED',
};

const IDENTIFIER_TYPE = {
  EMAIL: 'email',
  DOB: 'dob',
  ADDRESS: 'address',
  PHONE: 'phone',
};

const RELATIONSHIP_TYPE = {
  PARTNER: 'PARTNER',
  CHILD: 'CHILD',
  STEPCHILD: 'STEPCHILD',
  SIBLING: 'SIBLING',
  PARENT: 'PARENT',
  EXTENDED_FAMILY: 'EXTENDED_FAMILY',
  FRIEND: 'FRIEND',
  FORMER_PARTNER: 'FORMER_PARTNER',
  PROFESSIONAL: 'PROFESSIONAL',
  UNKNOWN: 'UNKNOWN',
};

const COUNTRY = {
  AU: 'AU',
  NZ: 'NZ',
};

const AU_STATE = {
  ACT: 'ACT',
  NSW: 'NSW',
  NT: 'NT',
  QLD: 'QLD',
  SA: 'SA',
  TAS: 'TAS',
  VIC: 'VIC',
  WA: 'WA',
};

const AU_STATES = Object.values(AU_STATE);

const AU_STATE_NAME = {
  ACT: 'Australian Capital Territory',
  NSW: 'New South Wales',
  NT: 'Northern Territory',
  QLD: 'Queensland',
  SA: 'South Australia',
  TAS: 'Tasmania',
  VIC: 'Victoria',
  WA: 'Western Australia',
};

const AU_STATE_OPTIONS = Object.entries(AU_STATE_NAME).map(([key, value]) => ({
  text: value,
  value: key,
}));

const PLAN_FLAG_STATUS = {
  ACTIVE: 'ACTIVE',
  RESOLVED: 'RESOLVED',
  IGNORED: 'IGNORED',
  ACTIONED: 'ACTIONED',
};

const PLAN_FLAG_ACTION = {
  RESOLVE: 'RESOLVE',
  IGNORE: 'IGNORE',
  ADD_TO_CART: 'ADD_TO_CART',
};

const PLAN_TYPE = {
  WILL: 'WILL',
};

const VAULT_ITEM_KEY = {
  SITUATION_FAMILY_CHILD: 'SITUATION_FAMILY_CHILD',
  SITUATION_FAMILY_PARTNER: 'SITUATION_FAMILY_PARTNER',
  SITUATION_FAMILY_OTHER: 'SITUATION_FAMILY_OTHER',
  DECISION_EXCLUSION_DEATH: 'DECISION_EXCLUSION_DEATH',
  SITUATION_FINANCIAL_ASSETS_SIZE: 'SITUATION_FINANCIAL_ASSETS_SIZE',
  SITUATION_FINANCIAL_ASSETS_LOCATION: 'SITUATION_FINANCIAL_ASSETS_LOCATION',
  SITUATION_FINANCIAL_ASSET_TYPE: 'SITUATION_FINANCIAL_ASSET_TYPE',
  DECISION_MAKER_GUARDIANSHIP_CHILD: 'DECISION_MAKER_GUARDIANSHIP_CHILD',
  DECISION_INSTRUCTION_DEATH: 'DECISION_INSTRUCTION_DEATH',
  DECISION_MAKER_DEATH: 'DECISION_MAKER_DEATH',
};

const VAULT_ITEM_TYPE = {
  HAS_CHILD_WITH_PREVIOUS_PARTNER: 'HAS_CHILD_WITH_PREVIOUS_PARTNER',
  HAS_UPCOMING_CHANGE_OF_MARITAL_STATUS:
    'HAS_UPCOMING_CHANGE_OF_MARITAL_STATUS',
  ESTATE_BENEFICIARY: 'ESTATE_BENEFICIARY',
  HAS_FAMILY_MEMBER_WITH_DISABILITY: 'HAS_FAMILY_MEMBER_WITH_DISABILITY',
  HAS_SIGNIFICANT_EXCLUDING_PRIMARY_RESIDENCE:
    'HAS_SIGNIFICANT_EXCLUDING_PRIMARY_RESIDENCE',
  HAS_INTERNATIONAL: 'HAS_INTERNATIONAL',
  IS_BUSINESS_OWNER: 'IS_BUSINESS_OWNER',
  IS_SELF_MANAGED_PENSION_FUND_OWNER: 'IS_SELF_MANAGED_PENSION_FUND_OWNER',
  HAS_FAMILY_TRUST_INTEREST: 'HAS_FAMILY_TRUST_INTEREST',
  ESTATE_BENEFICIARY_BACKUP: 'ESTATE_BENEFICIARY_BACKUP',
  ESTATE_BENEFICIARY_GIFT: 'ESTATE_BENEFICIARY_GIFT',
};

const TOOL_TYPE = {
  ONBOARDING_NEEDS_ASSESSMENT: 'ONBOARDING_NEEDS_ASSESSMENT',
  WILL_HELP_ASSESSMENT: 'WILL_HELP_ASSESSMENT',
};
const TOOL_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
};

const MODULE_TO_HELP_ASSESSMENT_VAULT_ITEMS = {
  FAMILY: [
    {
      KEY: VAULT_ITEM_KEY.DECISION_MAKER_GUARDIANSHIP_CHILD,
    },
  ],
  ESTATE: [
    {
      KEY: VAULT_ITEM_KEY.DECISION_INSTRUCTION_DEATH,
      TYPE: VAULT_ITEM_TYPE.ESTATE_BENEFICIARY,
    },
    {
      KEY: VAULT_ITEM_KEY.DECISION_INSTRUCTION_DEATH,
      TYPE: VAULT_ITEM_TYPE.ESTATE_BENEFICIARY_BACKUP,
    },
  ],
  GIFTS: [
    {
      KEY: VAULT_ITEM_KEY.DECISION_INSTRUCTION_DEATH,
      TYPE: VAULT_ITEM_TYPE.ESTATE_BENEFICIARY_GIFT,
    },
  ],
  EXECUTORS: [
    {
      KEY: VAULT_ITEM_KEY.DECISION_MAKER_DEATH,
    },
  ],
};

const ONBOARDING_STEP = {
  NAME: 'name',
  DOB: 'dob',
  COUNTRY: 'country',
  FAMILY: 'family',
  ASSETS: 'assets',
  FINISH: 'finish',
  INTRODUCTION: 'introduction',
};

const DISCOUNT_OPTIONS = ['PERCENTAGE', 'FIXED'];

const GET_STARTED_VARIATION = {
  CONTROL: 'control',
  VARIATION_1: 'variation1',
  VARIATION_2A: 'variation2a',
  VARIATION_2B: 'variation2b',
  VARIATION_3A: 'variation3a',
  VARIATION_3B: 'variation3b',
};

const WILL_TIERS_SLICE_TWO_ONE_VARIATION = {
  CONTROL: 'control',
  VARIATION_ONE: 'variation-one',
  VARIATION_TWO: 'variation-two',
};

const countryCallingCodes = [
  {
    flags: '🇦🇺',
    code: '+61',
    countries: ['Australia'],
  },
  {
    flags: '🇳🇿',
    code: '+64',
    countries: ['New Zealand'],
  },
  {
    flags: '🇺🇸 🇨🇦',
    code: '+1',
    countries: ['Canada', 'USA'],
  },
  {
    flags: '🇷🇺 🇰🇿',
    code: '+7',
    countries: ['Russia', 'Kazakhstan'],
  },
  {
    flags: '🇪🇬',
    code: '+20',
    countries: ['Egypt'],
  },
  {
    flags: '🇿🇦',
    code: '+27',
    countries: ['South Africa'],
  },
  {
    flags: '🇬🇷',
    code: '+30',
    countries: ['Greece'],
  },
  {
    flags: '🇳🇱',
    code: '+31',
    countries: ['Netherlands'],
  },
  {
    flags: '🇧🇪',
    code: '+32',
    countries: ['Belgium'],
  },
  {
    flags: '🇫🇷',
    code: '+33',
    countries: ['France'],
  },
  {
    flags: '🇪🇸',
    code: '+34',
    countries: ['Spain'],
  },
  {
    flags: '🇭🇺',
    code: '+36',
    countries: ['Hungary'],
  },
  {
    flags: '🇮🇹 🇻🇦',
    code: '+39',
    countries: ['Italy', 'Holy See'],
  },
  {
    flags: '🇷🇴',
    code: '+40',
    countries: ['Romania'],
  },
  {
    flags: '🇨🇭',
    code: '+41',
    countries: ['Switzerland'],
  },
  {
    flags: '🇦🇹',
    code: '+43',
    countries: ['Austria'],
  },
  {
    flags: '🇬🇧',
    code: '+44',
    countries: ['United Kingdom'],
  },
  {
    flags: '🇩🇰',
    code: '+45',
    countries: ['Denmark'],
  },
  {
    flags: '🇸🇪',
    code: '+46',
    countries: ['Sweden'],
  },
  {
    flags: '🇳🇴',
    code: '+47',
    countries: ['Norway', 'Bouvet Island'],
  },
  {
    flags: '🇵🇱',
    code: '+48',
    countries: ['Poland'],
  },
  {
    flags: '🇩🇪',
    code: '+49',
    countries: ['Germany'],
  },
  {
    flags: '🇵🇪',
    code: '+51',
    countries: ['Peru'],
  },
  {
    flags: '🇲🇽',
    code: '+52',
    countries: ['Mexico'],
  },
  {
    flags: '🇨🇺',
    code: '+53',
    countries: ['Cuba'],
  },
  {
    flags: '🇦🇷',
    code: '+54',
    countries: ['Argentina'],
  },
  {
    flags: '🇧🇷',
    code: '+55',
    countries: ['Brazil'],
  },
  {
    flags: '🇨🇱',
    code: '+56',
    countries: ['Chile'],
  },
  {
    flags: '🇨🇴',
    code: '+57',
    countries: ['Colombia'],
  },
  {
    flags: '🇻🇪',
    code: '+58',
    countries: ['Venezuela'],
  },
  {
    flags: '🇲🇾',
    code: '+60',
    countries: ['Malaysia'],
  },
  {
    flags: '🇮🇩',
    code: '+62',
    countries: ['Indonesia'],
  },
  {
    flags: '🇵🇭',
    code: '+63',
    countries: ['Philippines'],
  },
  {
    flags: '🇸🇬',
    code: '+65',
    countries: ['Singapore'],
  },
  {
    flags: '🇹🇭',
    code: '+66',
    countries: ['Thailand'],
  },
  {
    flags: '🇯🇵',
    code: '+81',
    countries: ['Japan'],
  },
  {
    flags: '🇰🇷',
    code: '+82',
    countries: ['South Korea'],
  },
  {
    flags: '🇻🇳',
    code: '+84',
    countries: ['Vietnam'],
  },
  {
    flags: '🇨🇳',
    code: '+86',
    countries: ['China'],
  },
  {
    flags: '🇹🇷',
    code: '+90',
    countries: ['Turkey'],
  },
  {
    flags: '🇮🇳',
    code: '+91',
    countries: ['India'],
  },
  {
    flags: '🇵🇰',
    code: '+92',
    countries: ['Pakistan'],
  },
  {
    flags: '🇦🇫',
    code: '+93',
    countries: ['Afghanistan'],
  },
  {
    flags: '🇱🇰',
    code: '+94',
    countries: ['Sri Lanka'],
  },
  {
    flags: '🇲🇲',
    code: '+95',
    countries: ['Myanmar'],
  },
  {
    flags: '🇮🇷',
    code: '+98',
    countries: ['Iran'],
  },
  {
    flags: '🇸🇸',
    code: '+211',
    countries: ['South Sudan'],
  },
  {
    flags: '🇲🇦 🇪🇭',
    code: '+212',
    countries: ['Morocco', 'Western Sahara'],
  },
  {
    flags: '🇩🇿',
    code: '+213',
    countries: ['Algeria'],
  },
  {
    flags: '🇹🇳',
    code: '+216',
    countries: ['Tunisia'],
  },
  {
    flags: '🇱🇾',
    code: '+218',
    countries: ['Libya'],
  },
  {
    flags: '🇬🇲',
    code: '+220',
    countries: ['Gambia'],
  },
  {
    flags: '🇸🇳',
    code: '+221',
    countries: ['Senegal'],
  },
  {
    flags: '🇲🇷',
    code: '+222',
    countries: ['Mauritania'],
  },
  {
    flags: '🇲🇱',
    code: '+223',
    countries: ['Mali'],
  },
  {
    flags: '🇬🇳',
    code: '+224',
    countries: ['Guinea'],
  },
  {
    flags: '🇨🇮',
    code: '+225	',
    countries: [`Côte d'Ivoire`],
  },
  {
    flags: '🇧🇫',
    code: '+226',
    countries: ['Burkina Faso'],
  },
  {
    flags: '🇳🇪',
    code: '+227',
    countries: ['Niger'],
  },
  {
    flags: '🇹🇬',
    code: '+228',
    countries: ['Togo'],
  },
  {
    flags: '🇧🇯',
    code: '+229',
    countries: ['Benin'],
  },
  {
    flags: '🇲🇺',
    code: '+230',
    countries: ['Mauritius'],
  },
  {
    flags: '🇱🇷',
    code: '+231',
    countries: ['Liberia'],
  },
  {
    flags: '🇸🇱',
    code: '+232',
    countries: ['Sierra Leone'],
  },
  {
    flags: '🇬🇭',
    code: '+233',
    countries: ['Ghana'],
  },
  {
    flags: '🇳🇬',
    code: '+234',
    countries: ['Nigeria'],
  },
  {
    flags: '🇹🇩',
    code: '+235',
    countries: ['Chad'],
  },
  {
    flags: '🇨🇫',
    code: '+236',
    countries: ['Central African Republic'],
  },
  {
    flags: '🇨🇲',
    code: '+237',
    countries: ['Cameroon'],
  },
  {
    flags: '🇨🇻',
    code: '+238',
    countries: ['Cabo Verde'],
  },
  {
    flags: '🇸🇹',
    code: '+239',
    countries: ['Sao Tome & Principe'],
  },
  {
    flags: '🇬🇶',
    code: '+240',
    countries: ['Equatorial Guinea'],
  },
  {
    flags: '🇬🇦',
    code: '+241',
    countries: ['Gabon'],
  },
  {
    flags: '🇨🇬',
    code: '+242',
    countries: ['Republic of the Congo'],
  },

  {
    flags: '🇨🇩',
    code: '+243',
    countries: ['Democratic Republic of the Congo'],
  },
  {
    flags: '🇦🇴',
    code: '+244',
    countries: ['Angola'],
  },
  {
    flags: '🇬🇼',
    code: '+245',
    countries: ['Guinea-Bissau'],
  },

  {
    flags: '🇮🇴',
    code: '+246',
    countries: ['British Indian Ocean Territories'],
  },
  {
    flags: '🇸🇨',
    code: '+248',
    countries: ['Seychelles'],
  },
  {
    flags: '🇸🇩',
    code: '+249',
    countries: ['Sudan'],
  },
  {
    flags: '🇷🇼',
    code: '+250',
    countries: ['Rwanda'],
  },
  {
    flags: '🇪🇹',
    code: '+251',
    countries: ['Ethiopia'],
  },
  {
    flags: '🇸🇴',
    code: '+252',
    countries: ['Somalia'],
  },
  {
    flags: '🇩🇯',
    code: '+253',
    countries: ['Djibouti'],
  },
  {
    flags: '🇰🇪',
    code: '+254',
    countries: ['Kenya'],
  },
  {
    flags: '🇹🇿',
    code: '+255',
    countries: ['United Republic of Tanzania'],
  },
  {
    flags: '🇺🇬',
    code: '+256',
    countries: ['Uganda'],
  },
  {
    flags: '🇧🇮',
    code: '+257',
    countries: ['Burundi'],
  },
  {
    flags: '🇲🇿',
    code: '+258',
    countries: ['Mozambique'],
  },
  {
    flags: '🇿🇲',
    code: '+260',
    countries: ['Zambia'],
  },
  {
    flags: '🇲🇬',
    code: '+261',
    countries: ['Madagascar'],
  },
  {
    flags: '🇾🇹 🇷🇪',
    code: '+262',
    countries: ['Mayotte', 'Réunion'],
  },
  {
    flags: '🇿🇼',
    code: '+263',
    countries: ['Zimbabwe'],
  },
  {
    flags: '🇳🇦',
    code: '+264',
    countries: ['Namibia'],
  },
  {
    flags: '🇲🇼',
    code: '+265',
    countries: ['Malawi'],
  },
  {
    flags: '🇱🇸',
    code: '+266',
    countries: ['Lesotho'],
  },
  {
    flags: '🇧🇼',
    code: '+267',
    countries: ['Botswana'],
  },
  {
    flags: '🇸🇿',
    code: '+268',
    countries: ['Eswatini'],
  },
  {
    flags: '🇰🇲',
    code: '+269',
    countries: ['Comoros'],
  },

  {
    flags: '🇸🇭',
    code: '+290',
    countries: ['Saint Helena, Ascension & Tristan da Cunha'],
  },
  {
    flags: '🇪🇷',
    code: '+291',
    countries: ['Eritrea'],
  },
  {
    flags: '🇦🇼',
    code: '+297',
    countries: ['Aruba'],
  },
  {
    flags: '🇫🇴',
    code: '+298',
    countries: ['Faroe Islands'],
  },
  {
    flags: '🇬🇱',
    code: '+299',
    countries: ['Greenland'],
  },
  {
    flags: '🇬🇮',
    code: '+350',
    countries: ['Gibraltar'],
  },
  {
    flags: '🇵🇹',
    code: '+351',
    countries: ['Portugal'],
  },
  {
    flags: '🇱🇺',
    code: '+352',
    countries: ['Luxembourg'],
  },
  {
    flags: '🇮🇪',
    code: '+353',
    countries: ['Ireland'],
  },
  {
    flags: '🇮🇸',
    code: '+354',
    countries: ['Iceland'],
  },
  {
    flags: '🇦🇱',
    code: '+355',
    countries: ['Albania'],
  },
  {
    flags: '🇲🇹',
    code: '+356',
    countries: ['Malta'],
  },
  {
    flags: '🇨🇾',
    code: '+357',
    countries: ['Cyprus'],
  },
  {
    flags: '🇫🇮 🇦🇽',
    code: '+358',
    countries: ['Finland', 'Åland Islands'],
  },
  {
    flags: '🇧🇬',
    code: '+359',
    countries: ['Bulgaria'],
  },
  {
    flags: '🇱🇹',
    code: '+370',
    countries: ['Lithuania'],
  },
  {
    flags: '🇱🇻',
    code: '+371',
    countries: ['Latvia'],
  },
  {
    flags: '🇪🇪',
    code: '+372',
    countries: ['Estonia'],
  },
  {
    flags: '🇲🇩',
    code: '+373',
    countries: ['Republic of Moldova'],
  },
  {
    flags: '🇦🇲',
    code: '+374',
    countries: ['Armenia'],
  },
  {
    flags: '🇧🇾',
    code: '+375',
    countries: ['Belarus'],
  },
  {
    flags: '🇦🇩',
    code: '+376',
    countries: ['Andorra'],
  },
  {
    flags: '🇲🇨',
    code: '+377',
    countries: ['Monaco'],
  },
  {
    flags: '🇸🇲',
    code: '+378',
    countries: ['Republic of San Marino'],
  },
  {
    flags: '🇺🇦',
    code: '+380',
    countries: ['Ukraine'],
  },
  {
    flags: '🇷🇸',
    code: '+381',
    countries: ['Serbia'],
  },
  {
    flags: '🇲🇪',
    code: '+382',
    countries: ['Montenegro'],
  },
  {
    flags: '🇽🇰',
    code: '+383',
    countries: ['Republic of Kosovo'],
  },
  {
    flags: '🇭🇷',
    code: '+385',
    countries: ['Croatia'],
  },
  {
    flags: '🇸🇮',
    code: '+386',
    countries: ['Slovenia'],
  },
  {
    flags: '🇧🇦',
    code: '+387',
    countries: ['Bosnia & Herzegovina'],
  },
  {
    flags: '🇲🇰',
    code: '+389',
    countries: ['North Macedonia'],
  },
  {
    flags: '🇨🇿',
    code: '+420',
    countries: ['Czechia'],
  },
  {
    flags: '🇸🇰',
    code: '+421',
    countries: ['Slovakia'],
  },
  {
    flags: '🇱🇮',
    code: '+423',
    countries: ['Liechtenstein'],
  },

  {
    flags: '🇫🇰 🇬🇸',
    code: '+500',
    countries: [
      'Falkland Islands',
      'South Georgia & the South Sandwich Islands',
    ],
  },
  {
    flags: '🇧🇿',
    code: '+501',
    countries: ['Belize'],
  },
  {
    flags: '🇬🇹',
    code: '+502',
    countries: ['Guatemala'],
  },
  {
    flags: '🇸🇻',
    code: '+503',
    countries: ['El Salvador'],
  },
  {
    flags: '🇭🇳',
    code: '+504',
    countries: ['Honduras'],
  },
  {
    flags: '🇳🇮',
    code: '+505',
    countries: ['Nicaragua'],
  },
  {
    flags: '🇨🇷',
    code: '+506',
    countries: ['Costa Rica'],
  },
  {
    flags: '🇵🇦',
    code: '+507',
    countries: ['Panama'],
  },
  {
    flags: '🇵🇲',
    code: '+508',
    countries: ['Saint Pierre & Miquelon'],
  },
  {
    flags: '🇭🇹',
    code: '+509',
    countries: ['Haiti'],
  },

  {
    flags: '🇬🇵 🇧🇱 🇲🇫',
    code: '+590',
    countries: ['Guadeloupe', 'Saint Martin', 'Saint Barthélemy'],
  },
  {
    flags: '🇧🇴',
    code: '+591',
    countries: ['Bolivia'],
  },
  {
    flags: '🇬🇾',
    code: '+592',
    countries: ['Guyana'],
  },
  {
    flags: '🇪🇨',
    code: '+593',
    countries: ['Ecuador'],
  },
  {
    flags: '🇬🇫',
    code: '+594',
    countries: ['French Guiana'],
  },
  {
    flags: '🇵🇾',
    code: '+595',
    countries: ['Paraguay'],
  },
  {
    flags: '🇲🇶',
    code: '+596',
    countries: ['Martinique'],
  },
  {
    flags: '🇸🇷',
    code: '+597',
    countries: ['Suriname'],
  },
  {
    flags: '🇺🇾',
    code: '+598',
    countries: ['Uruguay'],
  },

  {
    flags: '🇨🇼 🇧🇶',
    code: '+599',
    countries: ['Curaçao', 'Netherlands Antilles'],
  },
  {
    flags: '🇹🇱',
    code: '+670',
    countries: ['Timor-Leste'],
  },

  {
    flags: '🇦🇶 🇹🇫 🇭🇲 🇳🇫',
    code: '+672',
    countries: [
      'Antarctica',
      'Norfolk Island',
      'Territory of Heard Island & McDonald Islands',
      'French Southern & Antarctic Lands',
    ],
  },
  {
    flags: '🇧🇳',
    code: '+673',
    countries: ['Brunei Darussalam'],
  },
  {
    flags: '🇳🇷',
    code: '+674',
    countries: ['Nauru'],
  },
  {
    flags: '🇵🇬',
    code: '+675',
    countries: ['Papua New Guinea'],
  },
  {
    flags: '🇹🇴',
    code: '+676',
    countries: ['Tonga'],
  },
  {
    flags: '🇸🇧',
    code: '+677',
    countries: ['Solomon Islands'],
  },
  {
    flags: '🇻🇺',
    code: '+678',
    countries: ['Vanuatu'],
  },
  {
    flags: '🇫🇯',
    code: '+679',
    countries: ['Fiji'],
  },
  {
    flags: '🇵🇼',
    code: '+680',
    countries: ['Palau'],
  },
  {
    flags: '🇼🇫',
    code: '+681',
    countries: ['Wallis & Futuna'],
  },
  {
    flags: '🇨🇰',
    code: '+682',
    countries: ['Cook Islands'],
  },
  {
    flags: '🇳🇺',
    code: '+683',
    countries: ['Niue'],
  },
  {
    flags: '🇼🇸',
    code: '+685',
    countries: ['Samoa'],
  },
  {
    flags: '🇰🇮',
    code: '+686',
    countries: ['Kiribati'],
  },
  {
    flags: '🇳🇨',
    code: '+687',
    countries: ['New Caledonia'],
  },
  {
    flags: '🇹🇻',
    code: '+688',
    countries: ['Tuvalu'],
  },
  {
    flags: '🇵🇫',
    code: '+689',
    countries: ['French Polynesia'],
  },
  {
    flags: '🇹🇰',
    code: '+690',
    countries: ['Tokelau'],
  },
  {
    flags: '🇫🇲',
    code: '+691',
    countries: ['Micronesia'],
  },

  {
    flags: '🇲🇭',
    code: '+692',
    countries: ['Republic of the Marshall Islands'],
  },
  {
    flags: '🇩🇲',
    code: '+767',
    countries: ['Dominica'],
  },
  {
    flags: '🇰🇵',
    code: '+850',
    countries: ['North Korea'],
  },
  {
    flags: '🇭🇰',
    code: '+852',
    countries: ['Hong Kong'],
  },
  {
    flags: '🇲🇴',
    code: '+853',
    countries: ['Macao'],
  },
  {
    flags: '🇰🇭',
    code: '+855',
    countries: ['Cambodia'],
  },

  {
    flags: '🇱🇦',
    code: '+856	',
    countries: [`Lao People's Democratic Republic`],
  },
  {
    flags: '🇹🇹',
    code: '+868',
    countries: ['Trinidad & Tobago'],
  },
  {
    flags: '🇯🇲',
    code: '+876',
    countries: ['Jamaica'],
  },
  {
    flags: '🇧🇩',
    code: '+880',
    countries: ['Bangladesh'],
  },
  {
    flags: '🇹🇼',
    code: '+886',
    countries: ['Taiwan, Province of China'],
  },
  {
    flags: '🇲🇻',
    code: '+960',
    countries: ['Maldives'],
  },
  {
    flags: '🇱🇧',
    code: '+961',
    countries: ['Lebanon'],
  },
  {
    flags: '🇯🇴',
    code: '+962',
    countries: ['Jordan'],
  },
  {
    flags: '🇸🇾',
    code: '+963',
    countries: ['Syrian Arab Republic'],
  },
  {
    flags: '🇮🇶',
    code: '+964',
    countries: ['Iraq'],
  },
  {
    flags: '🇰🇼',
    code: '+965',
    countries: ['Kuwait'],
  },
  {
    flags: '🇸🇦',
    code: '+966',
    countries: ['Saudi Arabia'],
  },
  {
    flags: '🇾🇪',
    code: '+967',
    countries: ['Yemen'],
  },
  {
    flags: '🇴🇲',
    code: '+968',
    countries: ['Oman'],
  },
  {
    flags: '🇵🇸',
    code: '+970',
    countries: ['Palestine'],
  },
  {
    flags: '🇦🇪',
    code: '+971',
    countries: ['United Arab Emirates'],
  },
  {
    flags: '🇮🇱',
    code: '+972',
    countries: ['Israel'],
  },
  {
    flags: '🇧🇭',
    code: '+973',
    countries: ['Bahrain'],
  },
  {
    flags: '🇶🇦',
    code: '+974',
    countries: ['Qatar'],
  },
  {
    flags: '🇧🇹',
    code: '+975',
    countries: ['Bhutan'],
  },
  {
    flags: '🇲🇳',
    code: '+976',
    countries: ['Mongolia'],
  },
  {
    flags: '🇳🇵',
    code: '+977',
    countries: ['Nepal'],
  },
  {
    flags: '🇹🇯',
    code: '+992',
    countries: ['Tajikistan'],
  },
  {
    flags: '🇹🇲',
    code: '+993',
    countries: ['Turkmenistan'],
  },
  {
    flags: '🇦🇿',
    code: '+994',
    countries: ['Azerbaijan'],
  },
  {
    flags: '🇬🇪',
    code: '+995',
    countries: ['Georgia'],
  },
  {
    flags: '🇰🇬',
    code: '+996',
    countries: ['Kyrgyzstan'],
  },
  {
    flags: '🇺🇿',
    code: '+998',
    countries: ['Uzbekistan'],
  },
  {
    flags: '🇧🇸',
    code: '+1242',
    countries: ['Bahamas'],
  },
  {
    flags: '🇧🇧',
    code: '+1246',
    countries: ['Barbados'],
  },
  {
    flags: '🇦🇮',
    code: '+1264',
    countries: ['Anguilla'],
  },
  {
    flags: '🇦🇬',
    code: '+1268',
    countries: ['Antigua & Barbuda'],
  },
  {
    flags: '🇻🇬',
    code: '+1284',
    countries: ['Virgin Islands (British)'],
  },
  {
    flags: '🇻🇮',
    code: '+1340',
    countries: ['Virgin Islands (U.S.)'],
  },
  {
    flags: '🇰🇾',
    code: '+1345',
    countries: ['Cayman Islands'],
  },
  {
    flags: '🇧🇲',
    code: '+1441',
    countries: ['Bermuda'],
  },
  {
    flags: '🇬🇩',
    code: '+1473',
    countries: ['Grenada'],
  },
  {
    flags: '🇹🇨',
    code: '+1649',
    countries: ['Turks & Caicos Islands'],
  },
  {
    flags: '🇲🇸',
    code: '+1664',
    countries: ['Montserrat'],
  },
  {
    flags: '🇲🇵',
    code: '+1670',
    countries: ['Northern Mariana Islands'],
  },
  {
    flags: '🇦🇸',
    code: '+1684',
    countries: ['American Samoa'],
  },
  {
    flags: '🇸🇽',
    code: '+1721',
    countries: ['Sint Maarten'],
  },
  {
    flags: '🇱🇨',
    code: '+1758',
    countries: ['Saint Lucia'],
  },
  {
    flags: '🇻🇨',
    code: '+1784',
    countries: ['Saint Vincent & the Grenadines'],
  },
  {
    flags: '🇰🇳',
    code: '+1869',
    countries: ['Saint Kitts & Nevis'],
  },
  {
    flags: '🇸🇯',
    code: '+4779',
    countries: ['Svalbard & Jan Mayen'],
  },
  {
    flags: '🇧🇶',
    code: '+5997',
    countries: ['Bonaire, Sint Eustatius & Saba'],
  },
  {
    flags: '🇨🇨',
    code: '+61891',
    countries: ['Cocos Islands'],
  },
];

const WILL_BASE_PRICE = 16000;

export {
  securityLink,
  privacyLink,
  IDENTIFIER_TYPE,
  RELATIONSHIP_TYPE,
  COUNTRY,
  AU_STATE,
  AU_STATES,
  AU_STATE_OPTIONS,
  PLAN_FLAG_STATUS,
  PLAN_FLAG_ACTION,
  PLAN_TYPE,
  countryCallingCodes,
  WILL_BASE_PRICE,
  VAULT_ITEM_KEY,
  VAULT_ITEM_TYPE,
  WILL_STATUS,
  TOOL_TYPE,
  TOOL_STATUS,
  ONBOARDING_STEP,
  MODULE_TO_HELP_ASSESSMENT_VAULT_ITEMS,
  DISCOUNT_OPTIONS,
  GET_STARTED_VARIATION,
  WILL_TIERS_SLICE_TWO_ONE_VARIATION,
};
/* c8 ignore stop */
