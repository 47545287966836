import gql from 'graphql-tag';
import AdminWill from '../fragments/AdminWill';

export default gql`
  query getWills(
    $limit: Int
    $offset: Int
    $customOrder: CustomOrder
    $searchQuery: String
    $filters: GetWillsFilters
  ) {
    getWills(
      limit: $limit
      offset: $offset
      customOrder: $customOrder
      searchQuery: $searchQuery
      filters: $filters
    ) {
      ...AdminWill
    }
  }
  ${AdminWill}
`;
