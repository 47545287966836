/* eslint-disable camelcase */
import { configure, extend } from 'vee-validate';
import {
  required,
  min,
  max,
  max_value,
  min_value,
  integer,
} from 'vee-validate/dist/rules';
import {
  isBeneficiarySplitValid,
  isValidEmail,
  isDateOver18,
  isDateInFuture,
  isDateIsoFormat,
  isIsoDateValid,
  isValidBusinessNumber,
  isNumeric,
  isStrictBoolean,
} from '@/utilities';

const setupValidation = () => {
  configure({
    useConstraintAttrs: false,
  });

  extend('integer', {
    ...integer,
    message: 'forms.errors.integer',
  });

  extend('includes', {
    message: 'forms.errors.includes',
    validate(value, allowedValues) {
      if (isStrictBoolean(value)) {
        value = value.toString();
      }
      return allowedValues.includes(value);
    },
  });

  extend('min', {
    ...min,
    message(_field, args) {
      return `forms.errors.min_length:${args.length}`;
    },
  });

  extend('max', {
    ...max,
    message(_field, args) {
      return `forms.errors.max_length:${args.length}`;
    },
  });

  extend('required', {
    ...required,
    message: 'forms.errors.required',
  });

  extend('max_value', {
    ...max_value,
    message(_field, args) {
      return `forms.errors.max_value:${args.max}`;
    },
  });

  extend('min_value', {
    ...min_value,
    message(_field, args) {
      return `forms.errors.min_value:${args.min}`;
    },
  });

  extend('numeric', {
    message: 'forms.errors.numeric',
    validate(value) {
      return Promise.resolve({
        valid: isNumeric(value),
      });
    },
  });

  extend('phone', {
    message: 'forms.errors.phone',
    validate(value) {
      const pattern = /^(?:\+?(6[14]))?(?:\((?=.*\)))?(0?\d)\)?(\d{7,9})$/gm;
      return Promise.resolve({
        valid: pattern.test(value.replace(/[\s-]+/g, '')),
      });
    },
  });

  extend('phoneInternational', {
    message: 'forms.errors.phoneInternational',
    validate(value) {
      const pattern = /^[0-9 -]+$/;
      return Promise.resolve({
        valid: pattern.test(value) && value.replace(/[\s-]+/g, '').length <= 11,
      });
    },
  });

  extend('email', {
    message: 'forms.errors.email',
    validate(value) {
      const userLengthValid = value.split('@')[0]?.length <= 64;
      const domainLengthValid = value.split('@')[1]?.length <= 256;
      const formatValid = isValidEmail(value);
      return Promise.resolve({
        valid: userLengthValid && domainLengthValid && formatValid,
      });
    },
  });

  extend('date', {
    message: 'forms.errors.date',
    validate(value) {
      const pattern =
        /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/gm;
      return Promise.resolve({ valid: pattern.test(value) });
    },
  });

  extend('isoDate', {
    message: 'forms.errors.date',
    validate(value) {
      return Promise.resolve({ valid: isDateIsoFormat(value) });
    },
  });

  extend('validIsoDate', {
    message: 'forms.errors.date',
    validate(value) {
      return Promise.resolve({ valid: isIsoDateValid(value) });
    },
  });

  extend('postcode', {
    message: 'forms.errors.postcode',
    validate(value) {
      const pattern = /^\d{4}$/gm;
      return Promise.resolve({ valid: pattern.test(value) });
    },
  });

  extend('over18', {
    message: 'forms.errors.over18',
    validate(value) {
      return Promise.resolve({ valid: isDateOver18(value) });
    },
  });

  extend('after1900', {
    message: 'forms.errors.after1900',
    validate(value) {
      return Promise.resolve({
        valid: new Date(value).getUTCFullYear() > 1899,
      });
    },
  });

  extend('beforeTomorrow', {
    message: 'forms.errors.beforeTomorrow',
    validate(value) {
      return Promise.resolve({ valid: !isDateInFuture(value) });
    },
  });

  extend('beneficiarySplit', {
    message: '',
    validate(value) {
      return Promise.resolve({ valid: isBeneficiarySplitValid(value) });
    },
  });

  extend('abn', {
    message: 'forms.errors.abn',
    validate(value) {
      return Promise.resolve({ valid: isValidBusinessNumber(value) });
    },
  });

  extend('currency', {
    message: 'forms.errors.currency',
    validate(value) {
      const decimal = (value + '').split('.')[1] || '';
      return Promise.resolve({ valid: decimal.length <= 2 });
    },
  });
};

export default setupValidation;
