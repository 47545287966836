import gql from 'graphql-tag';

export default gql`
  fragment Coupon on Coupon {
    id
    code
    description
    active
    archived
    used
    createdAt
    partnershipId
    singleUse
    partnership {
      id
      name
    }
    discountProducts {
      discountId
      productId
      discountType
      discountFactor
    }
  }
`;
