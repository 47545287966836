export const contactNameOrDefault = (contact, fallback) => {
  if (!contact) {
    return fallback;
  }

  return (
    `${contact.firstName || ''} ${contact.lastName || ''}`.trim() || fallback
  );
};

export default (_ctx, inject) => {
  inject('contactNameOrDefault', contactNameOrDefault);
};
