import gql from 'graphql-tag';

export default gql`
  fragment Invite on Invite {
    id
    type
    code
    email
    redeemable
    createdAt
    updatedAt
    remindedAt
    discountProducts {
      id
      discountId
      productId
      discountType
      discountFactor
      product {
        code
      }
    }
  }
`;
