export const state = () => ({
  endOfLifeCategoriesPageIndex: 0,
});

export const getters = {
  endOfLifeCategoriesPageIndex: (state) => state.endOfLifeCategoriesPageIndex,
};

export const mutations = {
  SET_END_OF_LIFE_CATEGORIES_PAGE_INDEX(state, value) {
    state.endOfLifeCategoriesPageIndex = value;
  },
};

export const actions = {
  nextEndOfLifeCategoriesPage({ commit, state }) {
    commit(
      'SET_END_OF_LIFE_CATEGORIES_PAGE_INDEX',
      state.endOfLifeCategoriesPageIndex + 1
    );
  },
  prevEndOfLifeCategoriesPage({ commit, state }) {
    if (state.endOfLifeCategoriesPageIndex > 0) {
      commit(
        'SET_END_OF_LIFE_CATEGORIES_PAGE_INDEX',
        state.endOfLifeCategoriesPageIndex - 1
      );
    }
  },
};
