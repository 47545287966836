import gql from 'graphql-tag';
import PlanFlag from '../fragments/PlanFlag';

export default gql`
  mutation createPlanFlag($input: PlanPlanFlagInput!) {
    createPlanFlag(input: $input) {
      ...PlanPlanFlag
    }
  }
  ${PlanFlag}
`;
