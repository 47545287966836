import gql from 'graphql-tag';
import Will from '../fragments/Will';

export default gql`
  query getWill($id: ID) {
    getWill(id: $id) {
      will {
        ...Will
      }
    }
  }
  ${Will}
`;
