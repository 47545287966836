import gql from 'graphql-tag';
import Pet from '../fragments/Pet';

export default gql`
  mutation removePetGuardian($petId: ID!, $willId: ID!) {
    removePetGuardian(petId: $petId, willId: $willId) {
      ...Pet
    }
  }
  ${Pet}
`;
