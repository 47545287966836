import gql from 'graphql-tag';
import User from '../fragments/User';

export default gql`
  mutation loginUser {
    loginUser {
      success
      action
      identityProvider
      user {
        ...User
      }
    }
  }
  ${User}
`;
