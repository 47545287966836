import gql from 'graphql-tag';
import DirectoryPerson from '../fragments/DirectoryPerson';

export default gql`
  query getDirectoryPersonsByOwnerId($ownerId: ID!, $excludeSelf: Boolean) {
    getDirectoryPersonsByOwnerId(ownerId: $ownerId, excludeSelf: $excludeSelf) {
      ...DirectoryPerson
    }
  }
  ${DirectoryPerson}
`;
