import { updateField } from 'vuex-map-fields';

export default {
  setCurrentStepKey(state, key) {
    state.currentStepKey = key;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setPayload(state, payload) {
    state.payload = payload;
  },
  setSelectedVaultItemsIds(state, ids) {
    const newIdsSet = new Set(ids);
    const oldIdsSet = new Set(state.selectedVaultItemsIds);

    const newItems = [];
    const oldItems = [];
    let hasClearAnotherItemsInNewItems = false;
    let hasClearAnotherItemsInOldItems = false;
    let clearAnotherItemInNewItems = null;

    state.steps[state.currentStepKey].vaultItems.forEach((item) => {
      if (newIdsSet.has(item.id)) {
        newItems.push(item);
        if (item.clearAnotherItems) {
          hasClearAnotherItemsInNewItems = true;
          clearAnotherItemInNewItems = item;
        }
      }
      if (oldIdsSet.has(item.id)) {
        oldItems.push(item);
        if (item.clearAnotherItems) {
          hasClearAnotherItemsInOldItems = true;
        }
      }
    });

    if (newItems.length > 1 && hasClearAnotherItemsInOldItems) {
      state.selectedVaultItemsIds = newItems
        .filter((item) => !item.clearAnotherItems)
        .map((item) => item.id);
    } else if (newItems.length > 1 && hasClearAnotherItemsInNewItems) {
      state.selectedVaultItemsIds = [clearAnotherItemInNewItems.id];
    } else {
      state.selectedVaultItemsIds = ids;
    }
  },
  updateField,
};
