import Vue from 'vue';
import ToastService from 'primevue/toastservice';

const TOAST_DEFAULT_LIFETIME = 10000;

export default (ctx) => {
  ToastService.install(Vue);

  const toastPlugin = {
    install(Vue) {
      Vue.prototype.$toast = {
        ...Vue.prototype.$toast,
        addDefault({
          sticky = false,
          severity = 'info',
          isTranslation = false,
          title = null,
          message = null,
        }) {
          Vue.prototype.$toast.add({
            id: `notification__${new Date().getTime()}`,
            severity,
            summary: title && isTranslation ? ctx.app.i18n.t(title) : title,
            detail:
              message && isTranslation ? ctx.app.i18n.t(message) : message,
            life: sticky ? null : TOAST_DEFAULT_LIFETIME,
          });
        },
      };
    },
  };

  Vue.use(toastPlugin);
};
