import GET_INSTITUTIONS_BY_TYPE from '@/graphql/queries/GetInstitutionsByType';

export const state = () => ({
  institutionsByType: {},
});

export const getters = {
  institutionsByType: (state) => state.institutionsByType,
};

export const mutations = {
  SET_INSTITUTIONS_BY_TYPE(state, value) {
    state.institutionsByType = { ...state.institutionsByType, ...value };
  },
};

export const actions = {
  async getInstitutionsByType({ commit }, institutionsType) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_INSTITUTIONS_BY_TYPE,
      variables: {
        type: institutionsType,
      },
    });
    commit('SET_INSTITUTIONS_BY_TYPE', {
      [institutionsType]: data.getInstitutionsByType,
    });
  },
};
