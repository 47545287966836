import GET_PLAN_FLAGS_BY_PLAN_ID from '@/graphql/queries/GetPlanFlagsByPlanId';
import CREATE_PLAN_FLAG from '@/graphql/mutations/CreatePlanFlag';
import GET_FLAGS_BY_PLAN_TYPE_AND_USER_ID from '@/graphql/queries/GetFlagsByPlanTypeAndUserId';
import DELETE_PLAN_FLAG from '@/graphql/mutations/DeletePlanFlag';
import ACTION_PLAN_FLAG from '@/graphql/mutations/ActionPlanFlag';

export default {
  async getFlags({ commit }, { planType, userId }) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_FLAGS_BY_PLAN_TYPE_AND_USER_ID,
      fetchPolicy: 'no-cache',
      variables: {
        planType,
        userId,
      },
    });
    const flags = data.getFlagsByPlanTypeAndUserId.map((flag) => {
      return {
        ...flag,
        internalName: this.app.i18n.t(`flags.${flag.slug}.internalName`),
      };
    });
    commit('setFlags', flags);
  },
  async getPlanFlags({ commit }, { planId }) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_PLAN_FLAGS_BY_PLAN_ID,
      fetchPolicy: 'no-cache',
      variables: {
        planId,
      },
    });
    const planFlags = data.getPlanFlagsByPlanId.map((planFlag) => {
      return {
        ...planFlag,
        title: this.app.i18n.t(`flags.${planFlag.flag.slug}.title`),
        description: this.app.i18n.t(`flags.${planFlag.flag.slug}.description`),
        flag: {
          ...planFlag.flag,
          internalName: this.app.i18n.t(
            `flags.${planFlag.flag.slug}.internalName`
          ),
        },
      };
    });
    commit('setPlanFlags', planFlags);
  },
  async createPlanFlag({ commit }, { flagId, planId, segment }) {
    const { data } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: CREATE_PLAN_FLAG,
      variables: {
        input: {
          flagId,
          planId,
          planSegment: segment,
        },
      },
    });
    const newPlanFlag = data.createPlanFlag;
    commit('appendPlanFlag', {
      ...newPlanFlag,
      title: this.app.i18n.t(`flags.${newPlanFlag.flag.slug}.title`),
      description: this.app.i18n.t(
        `flags.${newPlanFlag.flag.slug}.description`
      ),
      flag: {
        ...newPlanFlag.flag,
        internalName: this.app.i18n.t(
          `flags.${newPlanFlag.flag.slug}.internalName`
        ),
      },
    });
  },
  async deletePlanFlag({ commit }, id) {
    const { data } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: DELETE_PLAN_FLAG,
      variables: {
        id,
      },
    });

    if (data.deletePlanFlag) {
      commit('removePlanFlag', id);
    }
  },
  async actionPlanFlag({ commit }, { id, action }) {
    const { data } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: ACTION_PLAN_FLAG,
      variables: {
        id,
        action,
      },
    });
    if (data.actionPlanFlag) {
      const newFlag = data.actionPlanFlag;
      newFlag.title = newFlag.flag?.slug
        ? this.app.i18n.t(`flags.${newFlag.flag.slug}.title`)
        : '';
      newFlag.description = newFlag.flag?.slug
        ? this.app.i18n.t(`flags.${newFlag.flag.slug}.description`)
        : '';
      commit('updatePlanFlag', newFlag);
    }
  },
};
