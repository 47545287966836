import gql from 'graphql-tag';

export default gql`
  fragment PartnershipContact on PartnershipContact {
    id
    partnershipId
    hasDashboardAccess
    isSubscribedToEmails
    userId
    email
  }
`;
