import { getField, updateField } from 'vuex-map-fields';
import { filterNullish, metaArrayToObject } from '@/utilities';
import GET_COUPONS from '@/graphql/queries/GetCoupons';
import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships';
import GET_COUPON from '@/graphql/queries/GetCoupon';
import UPDATE_COUPON from '@/graphql/mutations/UpdateCoupon';
import ARCHIVE_COUPON from '@/graphql/mutations/ArchiveCoupon';

export const state = () => ({
  couponsFields: {
    code: '',
    type: 'PERCENTAGE',
    description: '',
    factor: 0,
    active: false,
    products: [],
  },
  couponsSearchQuery: '',
  couponsPageIndex: 0,
  couponsPageSize: 10,
  couponsFilters: {
    active: true,
  },
  coupons: [],
  couponsPartnershipOptions: [],
});

export const getters = {
  getField,
  couponsFields: (state) => state.couponsFields,
  couponsPageIndex: (state) => state.couponsPageIndex,
  couponsSearchQuery: (state) => state.couponsSearchQuery,
  couponsFilters: (state) => state.couponsFilters,
  coupons: (state) => state.coupons,
  couponsPartnershipOptions: (state) => state.couponsPartnershipOptions,
  couponsPageSize: (state) => state.couponsPageSize,
};

export const mutations = {
  updateField,
  SET_COUPONS_FIELDS(state, value) {
    state.couponsFields = value;
  },
  SET_COUPONS_PAGE_INDEX(state, value) {
    state.couponsPageIndex = value;
  },
  SET_COUPONS_SEARCH_QUERY(state, value) {
    state.couponsSearchQuery = value;
  },
  SET_COUPONS_FILTERS(state, value) {
    state.couponsFilters = value;
  },
  SET_COUPONS(state, value) {
    state.coupons = value;
  },
  SET_COUPONS_PARTNERSHIP_OPTIONS(state, value) {
    state.couponsPartnershipOptions = value;
  },
};

export const actions = {
  async nextCouponsPage({ commit, state, dispatch }) {
    commit('SET_COUPONS_PAGE_INDEX', state.couponsPageIndex + 1);
    await dispatch('refetchCoupons');
  },
  async previousCouponsPage({ commit, state, dispatch }) {
    if (state.couponsPageIndex > 0) {
      commit('SET_COUPONS_PAGE_INDEX', state.couponsPageIndex - 1);
      await dispatch('refetchCoupons');
    }
  },
  resetCouponsPageIndex({ commit }) {
    commit('SET_COUPONS_PAGE_INDEX', 0);
  },
  setCouponsSearchQuery({ commit }, value) {
    commit('SET_COUPONS_SEARCH_QUERY', value);
  },
  setCouponsFilters({ commit }, value) {
    commit('SET_COUPONS_FILTERS', value);
  },
  async refetchCoupons({ commit, state }) {
    const {
      data: { getCoupons },
    } = await this.app.apolloProvider.defaultClient.query({
      query: GET_COUPONS,
      fetchPolicy: 'no-cache',
      variables: {
        limit: state.couponsPageSize,
        offset: state.couponsPageSize * state.couponsPageIndex,
        searchQuery: state.couponsSearchQuery,
        filters: filterNullish(state.couponsFilters),
      },
    });

    if (getCoupons) {
      getCoupons.forEach((coupon) => {
        coupon.meta = metaArrayToObject(coupon.meta);
      });
      commit('SET_COUPONS', getCoupons);
    }
  },
  async refetchCouponsPartnershipOptions({ commit }) {
    const {
      data: { getPartnerships },
    } = await this.app.apolloProvider.defaultClient.query({
      query: GET_PARTNERSHIPS_QUERY,
    });

    if (getPartnerships) {
      commit('SET_COUPONS_PARTNERSHIP_OPTIONS', [
        {
          value: null,
          text: 'All',
        },
        ...getPartnerships.map((partnership) => ({
          value: partnership.id,
          text: partnership.name,
        })),
      ]);
    }
  },
  async getCouponById(_, id) {
    const {
      data: { getCoupon },
    } = await this.app.apolloProvider.defaultClient.query({
      query: GET_COUPON,
      variables: {
        id,
      },
    });

    return getCoupon;
  },
  async updateCoupon(_, coupon) {
    await this.app.apolloProvider.defaultClient.mutate({
      mutation: UPDATE_COUPON,
      variables: {
        ...coupon,
      },
    });
  },
  async archiveCoupon(_, id) {
    await this.app.apolloProvider.defaultClient.mutate({
      mutation: ARCHIVE_COUPON,
      variables: {
        id,
      },
    });
  },
  async toggleCouponActiveStatus({ dispatch }, coupon) {
    await dispatch('updateCoupon', {
      ...coupon,
      active: !coupon.active,
    });
    await dispatch('refetchCoupons');
  },
};
