import gql from 'graphql-tag';
import DirectoryPerson from '../fragments/DirectoryPerson';

export default gql`
  query getAttorneys($poaId: ID!) {
    poa(id: $poaId) {
      id
      attorneys {
        id
        role
        power
        meta {
          id
          key
          value
        }
        directoryPerson {
          ...DirectoryPerson
        }
      }
    }
  }
  ${DirectoryPerson}
`;
