import GET_INSTITUTIONS from '@/graphql/queries/GetInstitutions';
import GET_INSTITUTIONS_TYPES from '@/graphql/queries/GetInstitutionTypes';

export const state = () => ({
  institutions: [],
  institutionsLoading: false,
  institutionsPageSize: 10,
  institutionsPageIndex: 0,
  institutionsSearchQuery: '',
  institutionsSortDirection: 'ASC',
  institutionsSortField: 'CREATED_AT',
  institutionsTypes: [],
});

export const getters = {
  institutions: (state) => state.institutions,
  institutionsLoading: (state) => state.institutionsLoading,
  institutionsActive: (state) =>
    state.institutions.filter((institution) => !institution.archived),
  institutionsPageIndex: (state) => state.institutionsPageIndex,
  institutionsPageSize: (state) => state.institutionsPageSize,
  institutionsSearchQuery: (state) => state.institutionsSearchQuery,
  institutionsSortDirection: (state) => state.institutionsSortDirection,
  institutionsSortField: (state) => state.institutionsSortField,
  institutionsTypes: (state) => [...state.institutionsTypes, 'Other'],
};

export const mutations = {
  SET_INSTITUTIONS(state, value) {
    state.institutions = value;
  },
  SET_INSTITUTIONS_LOADING(state, value) {
    state.institutionsLoading = value;
  },
  SET_INSTITUTIONS_TYPES(state, value) {
    state.institutionsTypes = value;
  },
  SET_INSTITUTIONS_SEARCH_QUERY(state, value) {
    state.institutionsSearchQuery = value;
  },
  SET_INSTITUTIONS_SORT_FIELD(state, value) {
    state.institutionsSortField = value;
  },
  SET_INSTITUTIONS_SEARCH_DIRECTION(state, value) {
    state.institutionsSortDirection = value;
  },
  SET_INSTITUTIONS_PAGE_INDEX(state, value) {
    state.institutionsPageIndex = value;
  },
};

export const actions = {
  async setInstutionsSort({ state, commit, dispatch }, value) {
    if (state.institutionsSortField !== value) {
      commit('SET_INSTITUTIONS_SORT_FIELD', value);
    } else {
      commit(
        'SET_INSTITUTIONS_SEARCH_DIRECTION',
        ['ASC', 'DESC'].find(
          (direction) => direction !== state.institutionsSortDirection
        )
      );
    }
    await dispatch('getInstitutions');
  },
  async setInstitutionsSearchQuery({ commit, dispatch }, value) {
    commit('SET_INSTITUTIONS_SEARCH_QUERY', value);
    commit('SET_INSTITUTIONS_PAGE_INDEX', value);
    await dispatch('getInstitutions');
  },
  async nextInstitutionsPage({ state, commit, dispatch }) {
    commit('SET_INSTITUTIONS_PAGE_INDEX', state.institutionsPageIndex + 1);
    await dispatch('getInstitutions');
  },
  async previousInstitutionsPage({ state, commit, dispatch }) {
    if (state.institutionsPageIndex > 0) {
      commit('SET_INSTITUTIONS_PAGE_INDEX', state.institutionsPageIndex - 1);
      await dispatch('getInstitutions');
    }
  },
  resetInstitutionsPageIndex({ commit }) {
    commit('SET_INSTITUTIONS_PAGE_INDEX', 0);
  },
  async getInstitutions({ state, commit }) {
    commit('SET_INSTITUTIONS_LOADING', true);
    const { data } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_INSTITUTIONS,
      variables: {
        searchQuery: state.institutionsSearchQuery,
        limit: state.institutionsPageSize,
        offset: state.institutionsPageIndex * state.institutionsPageSize,
        customOrder: {
          direction: state.institutionsSortDirection,
          field: state.institutionsSortField,
        },
      },
    });
    commit('SET_INSTITUTIONS_LOADING', false);
    commit('SET_INSTITUTIONS', data.getInstitutions);
  },
  async refetchInstitutions({ dispatch }) {
    await dispatch('getInstitutions');
    await dispatch('getInstitutionsTypes');
  },
  async getInstitutionsTypes({ commit }) {
    commit('SET_INSTITUTIONS_LOADING', true);
    const { data } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_INSTITUTIONS_TYPES,
    });
    commit('SET_INSTITUTIONS_LOADING', false);
    commit('SET_INSTITUTIONS_TYPES', data.getInstitutionTypes);
  },
};
