import { mapGetters, mapActions } from 'vuex';
import GET_SUBSCRIPTION_QUERY from '@/graphql/queries/GetSubscription';
import { error, user } from '@/mixins/apollo';

export default {
  name: 'MixinsApolloSubscription',
  mixins: [user],
  apollo: {
    subscription: {
      query: GET_SUBSCRIPTION_QUERY,
      variables() {
        return {
          userId: this.userId,
        };
      },
      update: (data) => data.getSubscription,
      result({ data }) {
        this.setSubscription(data.getSubscription);
      },
      skip() {
        return !this.token || !this.userId;
      },
      error,
    },
  },
  data() {
    return {
      subscription: null,
    };
  },
  computed: {
    ...mapGetters('cart', ['cartItems', 'cartFinalPrice']),
    formattedSubscriptionExpiryDate() {
      if (!this.subscription.expiresAt) return '';
      return this.$formatDate(this.subscription.expiresAt, 'dS mmmm yyyy');
    },
    formattedSubscriptionPaymentMethodExpiryDate() {
      if (!this.subscription.paymentMethodExpiresAt) return '';
      return this.$formatDate(
        this.subscription.paymentMethodExpiresAt,
        'dS mmmm yyyy'
      );
    },
    formattedSubscriptionCreatedAtDate() {
      if (!this.subscription.createdAt) return '';
      return this.$formatDate(this.subscription.createdAt, 'dS mmmm yyyy');
    },
    hasExpiredSubscription() {
      return !this.hasValidSubscription;
    },
    requiredUnlockProduct() {
      if (!this.subscription || !this.hasExpiredSubscription) {
        return;
      }
      const currentPeriodEnd = new Date(Number(this.subscription.expiresAt));
      const recentSubscriptionPeriodEnd = new Date(currentPeriodEnd).setMonth(
        currentPeriodEnd.getMonth() + 1
      );
      if (Date.now() <= recentSubscriptionPeriodEnd) {
        return 'RECENT_UNLOCK';
      } else {
        return 'UNLOCK';
      }
    },
    hasValidSubscription() {
      if (!this.subscription) {
        return true;
      }
      const now = new Date();
      const expiryDate = new Date(Number(this.subscription.expiresAt));
      return now < expiryDate;
    },
    hasValidPaymentMethod() {
      if (!this.subscription) {
        return true;
      }
      const now = new Date();
      const expiryDate = new Date(
        Number(this.subscription.paymentMethodExpiresAt)
      );
      return now < expiryDate;
    },
    hasAutoRenew() {
      return this.subscription?.autoRenew;
    },
    formattedExpiryDate() {
      if (!this.subscription?.expiresAt) return '';
      return this.$formatDate(this.subscription.expiresAt, 'dS mmmm yyyy');
    },
  },
  methods: {
    ...mapActions('cart', ['addToCart']),
    ...mapActions('subscription', ['setSubscription']),
    async unlockAndCheckout() {
      await this.addToCart({ codes: [this.requiredUnlockProduct] });

      const items = this.cartItems.map((item) => {
        return {
          item_id: item.product.code,
          price: this.cartItemFinalPrice(item.product.code) / 100,
          quantity: 1,
        };
      });

      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'begin_checkout',
        props: {
          checkout_trigger: 'Unlock',
          currency: this.$t('common.text.currency'),
          value: this.cartFinalPrice / 100,
          items,
        },
      });

      await this.$router.push({
        path: this.localePath('/checkout/cart'),
      });
    },
  },
};
