import gql from 'graphql-tag';

export default gql`
  mutation UpdatePoaMeta($poaId: ID, $meta: [PoaMetaInput!]!) {
    updatePoa(input: { id: $poaId, meta: $meta }) {
      id
      status
      meta {
        id
        key
        value
        category
      }
    }
  }
`;
