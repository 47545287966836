export default {
  setVaultItems(state, payload) {
    state.vaultItems = payload;
  },
  setOnboardingNeedsAssessmentVaultItems(state, payload) {
    state.onboardingNeedsAssessmentVaultItems = payload;
  },
  setWillHelpAssessmentVaultItems(state, payload) {
    state.willHelpAssessmentVaultItems = payload;
  },
};
