import gql from 'graphql-tag';
import CartItem from '../fragments/CartItem';

export default gql`
  mutation createCartItems($userId: ID!, $products: [Product!]!) {
    createCartItems(userId: $userId, products: $products) {
      ...CartItem
    }
  }
  ${CartItem}
`;
