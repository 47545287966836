export const state = () => ({
  partnershipsPageIndex: 0,
  partnershipsPageSize: 10,
  partnershipsFilters: {
    active: true,
    featuredFive: null,
    bequestEnabled: null,
    affiliateEnabled: null,
  },
  partnershipsSearchQuery: '',
  partnershipsSortColumn: 'Created At',
  partnershipsSortDirection: 'DESC',
  partnershipsSortableColumns: [
    {
      field: 'name',
      columnName: 'Name',
      defaultDirection: 'ASC',
    },
    {
      field: 'createdAt',
      columnName: 'Created At',
      defaultDirection: 'DESC',
    },
    {
      field: 'updatedAt',
      columnName: 'Updated At',
      defaultDirection: 'DESC',
    },
  ],
});

export const getters = {
  partnershipsPageIndex: (state) => state.partnershipsPageIndex,
  partnershipsPageSize: (state) => state.partnershipsPageSize,
  partnershipsSearchQuery: (state) => state.partnershipsSearchQuery,
  partnershipsFilters: (state) => state.partnershipsFilters,
  partnershipsSort: (state) => [
    state.partnershipsSortColumn,
    state.partnershipsSortDirection,
  ],
  partnershipsSortableColumns: (state) =>
    state.partnershipsSortableColumns.map(({ columnName }) => columnName),
  partnershipsSortOrder: (state) => ({
    field: state.partnershipsSortableColumns.find(
      ({ columnName }) => columnName === state.partnershipsSortColumn
    ).field,
    direction: state.partnershipsSortDirection,
  }),
};

export const mutations = {
  SET_PARTNERSHIPS_PAGE_INDEX(state, value) {
    state.partnershipsPageIndex = value;
  },
  SET_PARTNERSHIPS_SEARCH_QUERY(state, value) {
    state.partnershipsSearchQuery = value;
  },
  SET_PARTNERSHIPS_SORT_COLUMN(state, value) {
    state.partnershipsSortColumn = value;
  },
  SET_PARTNERSHIPS_SORT_DIRECTION(state, value) {
    state.partnershipsSortDirection = value;
  },
  SET_PARTNERSHIPS_FILTERS(state, value) {
    state.partnershipsFilters = {
      ...state.partnershipsFilters,
      ...value,
    };
  },
};

export const actions = {
  setPartnershipsSearchQuery({ commit, dispatch }, value) {
    commit('SET_PARTNERSHIPS_SEARCH_QUERY', value);
    dispatch('resetPartnershipsPage');
  },
  setPartnershipsFilters({ commit, dispatch }, value) {
    commit('SET_PARTNERSHIPS_FILTERS', value);
    dispatch('resetPartnershipsPage');
  },
  setPartnershipsSort({ commit, dispatch, state }, value) {
    if (state.partnershipsSortColumn === value) {
      commit(
        'SET_PARTNERSHIPS_SORT_DIRECTION',
        state.partnershipsSortDirection === 'ASC' ? 'DESC' : 'ASC'
      );
    } else {
      commit('SET_PARTNERSHIPS_SORT_COLUMN', value);
    }
    dispatch('resetPartnershipsPage');
  },
  nextPartnershipsPage({ commit, state }) {
    commit('SET_PARTNERSHIPS_PAGE_INDEX', state.partnershipsPageIndex + 1);
  },
  prevPartnershipsPage({ commit, state }) {
    if (state.partnershipsPageIndex > 0) {
      commit('SET_PARTNERSHIPS_PAGE_INDEX', state.partnershipsPageIndex - 1);
    }
  },
  resetPartnershipsPage({ commit }) {
    commit('SET_PARTNERSHIPS_PAGE_INDEX', 0);
  },
};
