import gql from 'graphql-tag';
import Liability from '../fragments/Liability';

export default gql`
  mutation addLiability($willId: ID!, $meta: [MetaInput!]!) {
    addLiability(willId: $willId, meta: $meta) {
      ...Liability
    }
  }
  ${Liability}
`;
