import { getField, updateField } from 'vuex-map-fields';
import { metaArrayToObject } from '@/utilities';

function defaults({ amount, ...o } = { amount: '' }) {
  const parsedAmount = parseInt(amount);
  return {
    description: '',
    type: '',
    note: '',
    amount: isNaN(parsedAmount) ? 0 : parsedAmount,
    ...o,
  };
}

export const state = () => ({
  id: null,
  fields: defaults(),
  recipientDirectoryPerson: null,
  charity: null,
});

export const getters = {
  id: (state) => state.id,
  fields: (state) => state.fields,
  recipientDirectoryPerson: (state) => state.recipientDirectoryPerson,
  charity: (state) => state.charity,
  getField,
};

export const mutations = {
  id(state, value) {
    state.id = value;
  },
  fields(state, value) {
    state.fields = value;
  },
  recipientDirectoryPerson(state, value) {
    state.recipientDirectoryPerson = value;
  },
  charity(state, value) {
    state.charity = value;
  },
  updateField,
};

export const actions = {
  addGift({ commit, dispatch }) {
    commit('id', null);
    commit('fields', defaults());
    commit('recipientDirectoryPerson', null);
    commit('charity', null);
    dispatch('application/ui/showGiftWizard', null, { root: true });
  },
  editGift({ commit, dispatch }, gift) {
    commit('id', gift.id);
    commit('fields', defaults(metaArrayToObject(gift.meta)));
    if (gift.recipientDirectoryPerson) {
      commit('recipientDirectoryPerson', gift.recipientDirectoryPerson);
      commit('charity', null);
    } else if (gift.charity) {
      commit('recipientDirectoryPerson', null);
      commit('charity', gift.charity);
    }
    dispatch('application/ui/showGiftWizard', null, { root: true });
  },
};
