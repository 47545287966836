import gql from 'graphql-tag';
import CharityMeta from './CharityMeta';

export default gql`
  fragment Charity on Charity {
    id
    meta {
      ...CharityMeta
    }
  }
  ${CharityMeta}
`;
