import Vue from 'vue';
import VueSegmentAnalytics from 'vue-segment-analytics';

export default ({ app, store }) => {
  Vue.use(VueSegmentAnalytics, {
    id: process.env.SEGMENT_APP_ID,
    router: app.router,
  });

  const debugAnalytics = {};
  const methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
  ];

  methods.forEach(
    (method) =>
      (debugAnalytics[method] = (...args) =>
        console.info(`analytics.${method}`, ...args))
  );

  Object.defineProperty(Vue, '$analytics', {
    get() {
      if (process.env.NODE_ENV === 'development') {
        return debugAnalytics;
      }

      return window.analytics && !store.state.admin.masquerade.isMasquerading
        ? window.analytics
        : debugAnalytics;
    },
  });

  Object.defineProperty(Vue.prototype, '$analytics', {
    get() {
      if (process.env.NODE_ENV === 'development') {
        return debugAnalytics;
      }

      return window.analytics && !store.state.admin.masquerade.isMasquerading
        ? window.analytics
        : debugAnalytics;
    },
  });
};
