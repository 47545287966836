import gql from 'graphql-tag';

export default gql`
  query getPoaStatus($id: ID) {
    poa(id: $id) {
      id
      status
    }
  }
`;
