import { isModeratorOrHigher } from '@/utilities';

export default function ({ app, redirect, store, route }) {
  const hasToken = !!app.$apolloHelpers.getToken();
  if (hasToken) {
    const { path } = route;
    if (isModeratorOrHigher(store.state.role) && !path.includes('/sw/admin')) {
      return redirect('/admin/submissions');
    }
  }
}
