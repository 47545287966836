import gql from 'graphql-tag';
import Will from '../fragments/Will';

export default gql`
  mutation versionWill($id: ID!) {
    versionWill(id: $id) {
      ...Will
    }
  }
  ${Will}
`;
