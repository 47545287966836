import { PLAN_FLAG_STATUS } from '@/utilities/constants';

export default {
  flags: (state) => {
    return state.flags;
  },
  planFlags: (state) => {
    return state.planFlags;
  },
  hasActivePlanFlags: (state) => {
    return state.planFlags.some(
      (flag) => flag.status === PLAN_FLAG_STATUS.ACTIVE
    );
  },
  activePlanFlagsCount: (state) => {
    return state.planFlags.filter(
      (flag) => flag.status === PLAN_FLAG_STATUS.ACTIVE
    ).length;
  },
  isPlanModuleFlagged: (state) => (module) => {
    return state.planFlags.some(
      (flag) =>
        flag.status === PLAN_FLAG_STATUS.ACTIVE &&
        flag.planSegment.module === module
    );
  },
};
