export default {
  data: (state) => state.data,
  gifts: (state) => state.gifts,
  pets: (state) => state.pets,
  assets: (state) => state.assets,
  liabilities: (state) => state.liabilities,
  executors: (state) => state.executors,
  beneficiaries: (state) => state.beneficiaries,
  guardians: (state) => state.guardians,
  willFileId: (state) => state.data.willFileId || null,
};
