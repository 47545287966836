import gql from 'graphql-tag';
import Guardian from '../fragments/Guardian';

export default gql`
  mutation addGuardian(
    $willId: ID!
    $meta: [MetaInput]
    $directoryPersonId: ID!
    $type: String!
  ) {
    addGuardian(
      willId: $willId
      meta: $meta
      directoryPersonId: $directoryPersonId
      type: $type
    ) {
      ...Guardian
    }
  }
  ${Guardian}
`;
