import gql from 'graphql-tag';
import Flag from '../fragments/Flag';

export default gql`
  query getFlagsByPlanTypeAndUserId($planType: PlanType!, $userId: ID!) {
    getFlagsByPlanTypeAndUserId(planType: $planType, userId: $userId) {
      ...PlanFlag
    }
  }
  ${Flag}
`;
