import gql from 'graphql-tag';

export default gql`
  fragment PlanFlag on PlanFlag {
    id
    slug
    planType
    planSegments {
      module
      section
    }
    userSegment {
      country
      affiliateId
    }
    additionalActions {
      action
      value
    }
  }
`;
