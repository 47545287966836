import gql from 'graphql-tag';
import Beneficiary from '../fragments/Beneficiary';

export default gql`
  query getBeneficiaries($willId: ID!) {
    getBeneficiaries(willId: $willId) {
      ...Beneficiary
    }
  }
  ${Beneficiary}
`;
