import gql from 'graphql-tag';
import Invite from '../fragments/Invite';

export default gql`
  query getPublicInvite($userId: ID!) {
    getPublicInvite(userId: $userId) {
      ...Invite
    }
  }
  ${Invite}
`;
