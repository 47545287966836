import gql from 'graphql-tag';
import Charity from '../fragments/Charity';

export default gql`
  mutation removeCharity($id: ID!, $willId: ID!) {
    removeCharity(id: $id, willId: $willId) {
      ...Charity
    }
  }
  ${Charity}
`;
