import gql from 'graphql-tag';
import Guardian from '../fragments/Guardian';

export default gql`
  mutation removeGuardian($id: ID!, $willId: ID!) {
    removeGuardian(id: $id, willId: $willId) {
      ...Guardian
    }
  }
  ${Guardian}
`;
