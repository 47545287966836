export default () => ({
  data: {
    meta: {},
  },
  gifts: [],
  assets: [],
  liabilities: [],
  executors: [],
  beneficiaries: [],
  guardians: [],
  pets: [],
});
