import gql from 'graphql-tag';

export default gql`
  mutation setSubscriptionAutoRenew($userId: ID!, $autoRenew: Boolean!) {
    setSubscriptionAutoRenew(userId: $userId, autoRenew: $autoRenew) {
      id
      stripeSubscriptionId
      autoRenew
      paymentMethodExpiresAt
      expiresAt
      createdAt
    }
  }
`;
