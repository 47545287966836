import gql from 'graphql-tag';
import LiabilityMeta from './LiabilityMeta';

export default gql`
  fragment Liability on Liability {
    id
    meta {
      ...LiabilityMeta
    }
  }
  ${LiabilityMeta}
`;
