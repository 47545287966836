import gql from 'graphql-tag';
import AccountInterest from '../fragments/AccountInterest';

export default gql`
  query getAccountInterestsByUserId($userId: ID!) {
    getAccountInterestsByUserId(userId: $userId) {
      ...AccountInterest
    }
  }
  ${AccountInterest}
`;
