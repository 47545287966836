import gql from 'graphql-tag';
import Liability from '../fragments/Liability';

export default gql`
  mutation removeLiability($id: ID!, $willId: ID!) {
    removeLiability(id: $id, willId: $willId) {
      ...Liability
    }
  }
  ${Liability}
`;
