import gql from 'graphql-tag';
import GuardianMeta from './GuardianMeta';
import DirectoryPerson from './DirectoryPerson';

export default gql`
  fragment Guardian on Guardian {
    id
    meta {
      ...GuardianMeta
    }
    type
    directoryPerson {
      ...DirectoryPerson
    }
  }
  ${GuardianMeta}
  ${DirectoryPerson}
`;
