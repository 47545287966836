import gql from 'graphql-tag';

export default gql`
  fragment Tool on Tool {
    id
    type
    status
    directoryPersonIds
    vaultItemIds
  }
`;
