import gql from 'graphql-tag';
import Charity from '../fragments/Charity';

export default gql`
  query getCharities($willId: ID!) {
    getCharities(willId: $willId) {
      ...Charity
    }
  }
  ${Charity}
`;
