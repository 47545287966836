import gql from 'graphql-tag';
import PetMeta from './PetMeta';
import DirectoryPerson from './DirectoryPerson';

export default gql`
  fragment Pet on Pet {
    id
    meta {
      ...PetMeta
    }
    guardianDirectoryPerson {
      ...DirectoryPerson
    }
  }
  ${PetMeta}
  ${DirectoryPerson}
`;
