import gql from 'graphql-tag';
import Executor from '../fragments/Executor';

export default gql`
  mutation addExecutor(
    $willId: ID!
    $meta: [MetaInput]
    $directoryPersonId: ID!
    $type: String!
  ) {
    addExecutor(
      willId: $willId
      meta: $meta
      directoryPersonId: $directoryPersonId
      type: $type
    ) {
      ...Executor
    }
  }
  ${Executor}
`;
