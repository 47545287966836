import gql from 'graphql-tag';

export default gql`
  fragment PartnershipAppData on PartnershipAppData {
    id
    name
    slug
    abn
    address
    categories
    logo
    altNames
    description
    featuredFive
    region
    displayName
    parentPartnershipId
    relatedPartnershipIds
    active
    archived
    createdAt
    updatedAt
    regionalPartnerships {
      id
      name
      region
    }
    relatedPartnerships {
      id
      name
      region
    }
  }
`;
