import gql from 'graphql-tag';
import Pet from '../fragments/Pet';

export default gql`
  mutation addPet($willId: ID!, $meta: [MetaInput!]!) {
    addPet(willId: $willId, meta: $meta) {
      ...Pet
    }
  }
  ${Pet}
`;
