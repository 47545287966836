import gql from 'graphql-tag';

export default gql`
  fragment AdminUser on AdminUser {
    id
    email
    person {
      ... on DirectoryPerson {
        id
        firstName
        middleName
        lastName
      }
    }
  }
`;
