import gql from 'graphql-tag';
import Coupon from '../fragments/Coupon';

export default gql`
  mutation archiveCoupon($id: ID!) {
    archiveCoupon(id: $id) {
      ...Coupon
    }
  }
  ${Coupon}
`;
