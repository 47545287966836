import gql from 'graphql-tag';
import DirectoryPerson from '../fragments/DirectoryPerson';

export default gql`
  query getDirectoryPerson($id: ID!) {
    getDirectoryPerson(id: $id) {
      ...DirectoryPerson
    }
  }
  ${DirectoryPerson}
`;
