import gql from 'graphql-tag';

export default gql`
  fragment Partnership on Partnership {
    id
    name
    displayName
    slug
    couponTag
    abn
    address
    categories
    logo
    landingPages
    altNames
    utmPrefixes
    membershipFee
    description
    featuredFive
    region
    country
    parentPartnershipId
    relatedPartnershipIds
    regionalPartnerships {
      id
      name
      region
    }
    relatedPartnerships {
      id
      name
      region
    }
    dashboardEnabled
    bequestEnabled
    affiliateEnabled
    affiliateFeatures {
      dashboardEnabled
      willEnabled
      powerOfAttorneyEnabled
      endOfLifeEnabled
      giftEnabled
      onboardingNeedsAssessmentEnabled
      willTierTwoEnabled
      affiliateUserIdEnabled
      affiliateUserIdFieldName
      couponCodeEnabled
      couponCodeFieldName
    }
    branding {
      theme
      darker
      lighter
      landingImage
    }
    active
    archived
    getStartedTitleOne
    getStartedDescriptionOne
    getStartedTitleTwo
    getStartedDescriptionTwo
    getStartedTitleThree
    getStartedDescriptionThree
    getStartedAffiliateLogoShown
    appHeaderAffiliateLogoShown
    createdAt
    updatedAt
  }
`;
