import { isModeratorOrHigher } from '@/utilities';

export default function ({ redirect, store, app, query }) {
  if (app.$auth.loggedIn) {
    const isMasquerading = store.getters['admin/masquerade/isMasquerading'];
    if (isModeratorOrHigher(store.state.role) && !isMasquerading) {
      return redirect('/admin/submissions');
    }
    return redirect('/', query);
  }
}
