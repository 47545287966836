export default {
  setSlug(state, value) {
    state.slug = value;
  },
  setBranding(state, value) {
    state.branding = value;
  },
  setFeatures(state, value) {
    state.features = value;
  },
  setGetStartedBranding(state, value) {
    state.getStartedBranding = value;
  },
  setAppBranding(state, value) {
    state.appBranding = value;
  },
};
