import gql from 'graphql-tag';
import Beneficiary from '../fragments/Beneficiary';

export default gql`
  mutation addBeneficiary(
    $willId: ID!
    $userId: ID!
    $meta: [RequiredMetaInput!]!
    $directoryPersonId: ID
    $charityId: ID
    $causeId: ID
    $distribution: String
    $type: BeneficiaryType!
    $backupId: ID
  ) {
    addBeneficiary(
      willId: $willId
      userId: $userId
      meta: $meta
      directoryPersonId: $directoryPersonId
      charityId: $charityId
      causeId: $causeId
      distribution: $distribution
      type: $type
      backupId: $backupId
    ) {
      ...Beneficiary
    }
  }
  ${Beneficiary}
`;
