import gql from 'graphql-tag';
import PartnershipAppData from '../fragments/PartnershipAppData';

export default gql`
  query getPartnershipAppData {
    getPartnershipAppData {
      ...PartnershipAppData
    }
  }
  ${PartnershipAppData}
`;
