import gql from 'graphql-tag';
import Guardian from '../fragments/Guardian';

export default gql`
  query getGuardians($willId: ID!) {
    getGuardians(willId: $willId) {
      ...Guardian
    }
  }
  ${Guardian}
`;
