import gql from 'graphql-tag';
import Gift from '../fragments/Gift';

export default gql`
  mutation removeGift($id: ID!, $willId: ID!) {
    removeGift(id: $id, willId: $willId) {
      ...Gift
    }
  }
  ${Gift}
`;
