import gql from 'graphql-tag';
import Will from '../fragments/Will';

export default gql`
  mutation updateWill(
    $id: ID!
    $meta: [MetaInput!]!
    $transferAffiliateId: ID
  ) {
    updateWill(
      id: $id
      meta: $meta
      transferAffiliateId: $transferAffiliateId
    ) {
      success
      message
      will {
        ...Will
      }
    }
  }
  ${Will}
`;
