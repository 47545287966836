import { mapActions } from 'vuex';
import { queries } from '@/modules/apollo-queries/poa-meta';
import { error } from '@/mixins/apollo';

import GET_WILL_QUERY from '@/graphql/queries/GetWill';
import GET_POA_STATUS from '@/graphql/queries/GetPoaStatus';

export default {
  name: 'MixinsApolloPoa',
  apollo: {
    'About Yourself': {
      query: GET_WILL_QUERY,
      variables() {
        return {
          id: this.willId,
        };
      },
    },
    Financial: {
      ...queries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setData(data.poa);
      },
    },
    Medical: {
      ...queries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setData(data.poa);
      },
    },
    poaStatus: {
      query: GET_POA_STATUS,
      fetchPolicy: 'network-only',
      update: (data) => {
        return data.poa !== null ? data.poa.status : null;
      },
      variables() {
        return { id: this.poaId };
      },
      result({ data }) {
        this.setStatus(data.poa?.status);
      },
      error,
    },
  },
  methods: {
    ...mapActions('poa', ['setStatus', 'setData']),
  },
};
