import gql from 'graphql-tag';

export default gql`
  query getPartnershipBranding($slug: String!) {
    getPartnershipBranding(slug: $slug) {
      getStartedTitleOne
      getStartedDescriptionOne
      getStartedTitleTwo
      getStartedDescriptionTwo
      getStartedTitleThree
      getStartedDescriptionThree
      getStartedAffiliateLogoShown
      appHeaderAffiliateLogoShown
      logo
      branding {
        theme
        darker
        lighter
        landingImage
      }
    }
  }
`;
