export default {
  setProductPrices(state, data) {
    const productPrices = {};
    const productSlugs = {};
    data.forEach((item) => {
      productPrices[item.productCode] = item.discountedPrice;
      productSlugs[item.productCode] = item.productPriceSlug;
    });
    state.productPrices = productPrices;
    state.productSlugs = productSlugs;
  },
};
