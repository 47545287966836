import gql from 'graphql-tag';
import ExternalIdentifier from '../fragments/ExternalIdentifier';

export default gql`
  query getExternalIdentifierAnalyticIdentifiers($ownerId: ID!) {
    getExternalIdentifierAnalyticIdentifiers(ownerId: $ownerId) {
      ...ExternalIdentifier
    }
  }
  ${ExternalIdentifier}
`;
