import gql from 'graphql-tag';

export default gql`
  fragment Institution on Institution {
    id
    type
    name
    abn
    createdAt
    updatedAt
    archived
  }
`;
