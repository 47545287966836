import gql from 'graphql-tag';
import DirectoryPerson from '../fragments/DirectoryPerson';

export default gql`
  query GetPoaInclusions($poaId: ID!) {
    poa(id: $poaId) {
      id
      inclusions {
        id
        power
        directoryPerson {
          ...DirectoryPerson
        }
      }
    }
  }
  ${DirectoryPerson}
`;
