import { getField, updateField } from 'vuex-map-fields';
import { objectToMetaArray, metaArrayToObject } from '@/utilities';

import ADD_PET_MUTATION from '@/graphql/mutations/AddPet';
import ADD_PET_GUARDIAN_MUTATION from '@/graphql/mutations/AddPetGuardian';
import GET_PETS_QUERY from '@/graphql/queries/GetPets';
import REMOVE_PET_MUTATION from '@/graphql/mutations/RemovePet';
import REMOVE_PET_GUARDIAN_MUTATION from '@/graphql/mutations/RemovePetGuardian';
import UPDATE_PET_MUTATION from '@/graphql/mutations/UpdatePet';

const petDefaults = {
  name: null,
  type: null,
};

export const state = () => ({
  pet: { ...petDefaults },
  pets: [],
});

export const getters = {
  pets: (state) => state.pets,
  getField,
};

export const mutations = {
  SET_PET: (state, data) => {
    state.pet = { ...data };
  },
  SET_PETS: (state, data) => {
    state.pets = [...data];
  },
  updateField,
};

export const actions = {
  async savePet({ state, dispatch }) {
    if (state.pet.id) {
      await dispatch('updatePet');
    } else {
      await dispatch('createPet');
    }
  },
  addPet({ commit, dispatch }) {
    commit('SET_PET', petDefaults);
    dispatch('application/ui/showPetPanel', null, { root: true });
  },
  async createPet({ state, commit, rootGetters: { willId } }) {
    const variables = {
      willId,
      meta: objectToMetaArray(state.pet),
    };
    const { data } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: ADD_PET_MUTATION,
      variables,
    });
    const pets = [...state.pets, data.addPet];
    commit('SET_PETS', pets);
  },
  editPet({ state, commit, dispatch }, id) {
    const pet = state.pets.find((pet) => pet.id === id);
    commit('SET_PET', { id, ...petDefaults, ...metaArrayToObject(pet.meta) });
    dispatch('application/ui/showPetPanel', null, { root: true });
  },
  async updatePet({ state, commit, rootGetters: { willId } }) {
    const variables = {
      id: state.pet.id,
      willId,
      meta: objectToMetaArray(state.pet),
    };
    const { data } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: UPDATE_PET_MUTATION,
      variables,
    });
    const updatedPetIndex = state.pets.findIndex(
      (pet) => pet.id === data.updatePet.id
    );
    const pets = [
      ...state.pets.slice(0, updatedPetIndex),
      data.updatePet,
      ...state.pets.slice(updatedPetIndex + 1),
    ];
    commit('SET_PETS', pets);
  },
  async removePet({ state, commit, rootGetters: { willId } }, id) {
    const { data } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: REMOVE_PET_MUTATION,
      variables: {
        meta: objectToMetaArray(this.fields),
        willId,
        id,
      },
    });
    const removedPetIndex = state.pets.findIndex(
      (pet) => pet.id === data.removePet.id
    );
    const pets = [
      ...state.pets.slice(0, removedPetIndex),
      ...state.pets.slice(removedPetIndex + 1),
    ];
    commit('SET_PETS', pets);
  },
  async removeAllPets({ state, dispatch }) {
    await Promise.all(
      state.pets.map(async (pet) => {
        await dispatch('removePet', pet.id);
      })
    );
  },
  async getPets({ commit, rootGetters: { willId } }, adminWillId = null) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_PETS_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        willId: adminWillId || willId,
      },
    });
    commit('SET_PETS', data.getPets);
  },
  async addPetGuardian({ rootGetters: { willId } }, { petId, personId }) {
    await this.app.apolloProvider.defaultClient.mutate({
      mutation: ADD_PET_GUARDIAN_MUTATION,
      variables: {
        petId,
        guardianDirectoryPersonId: personId,
        willId,
      },
    });
  },
  async removePetGuardian({ rootGetters: { willId } }, petId) {
    await this.app.apolloProvider.defaultClient.mutate({
      mutation: REMOVE_PET_GUARDIAN_MUTATION,
      variables: {
        petId,
        willId,
      },
    });
  },
};
