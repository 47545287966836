export default {
  legalAdvicePrice: (state) => state.productPrices.LEGAL_ADVICE,
  legalAdviceSlug: (state) => state.productSlugs.LEGAL_ADVICE,
  legalAdviceBookingPrice: (state) => state.productPrices.LEGAL_ADVICE_BOOKING,
  legalAdviceBookingSlug: (state) => state.productSlugs.LEGAL_ADVICE_BOOKING,
  partnerWillPrice: (state) => state.productPrices.PARTNER_WILL,
  partnerWillSlug: (state) => state.productSlugs.PARTNER_WILL,
  partnerPoaPrice: (state) => state.productPrices.PARTNER_POA,
  partnerPoaSlug: (state) => state.productSlugs.PARTNER_POA,
  poaPrice: (state) => state.productPrices.POA,
  poaSlug: (state) => state.productSlugs.POA,
  poaGiftPrice: (state) => state.productPrices.POA_GIFT,
  poaGiftSlug: (state) => state.productSlugs.POA_GIFT,
  recentUnlockPrice: (state) => state.productPrices.RECENT_UNLOCK,
  recentUnlockSlug: (state) => state.productSlugs.RECENT_UNLOCK,
  subscriptionPrice: (state) => state.productPrices.SUBSCRIPTION,
  subscriptionSlug: (state) => state.productSlugs.SUBSCRIPTION,
  subscriptionRenewalPrice: (state) => state.productPrices.SUBSCRIPTION_RENEWAL,
  subscriptionRenewalSlug: (state) => state.productSlugs.SUBSCRIPTION_RENEWAL,
  unlockPrice: (state) => state.productPrices.UNLOCK,
  unlockSlug: (state) => state.productSlugs.UNLOCK,
  willPrice: (state) => state.productPrices.WILL,
  willTierTwoPrice: (state) => state.productPrices.WILL_TIER_TWO,
  willSlug: (state) => state.productSlugs.WILL,
  willGiftPrice: (state) => state.productPrices.WILL_GIFT,
  willGiftSlug: (state) => state.productSlugs.WILL_GIFT,
  productPrices: (state) => state.productPrices,
  productSlugs: (state) => state.productSlugs,
  priceOf: (state) => (code) => state.productPrices[code],
  slugOf: (state) => (code) => state.productSlugs[code],
};
