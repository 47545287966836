export default function ({ redirect, store, app, route }) {
  const willStatus = store.getters.willStatus;
  const isWillDashboard =
    route.fullPath.endsWith('/will') || route.fullPath.endsWith('/will/');

  if (!isWillDashboard && willStatus !== 'IN_PROGRESS') {
    const slug = store.getters['application/affiliate/slug'];
    return redirect(app.localePath(`/${slug}/will`));
  }
}
