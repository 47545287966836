import { recursiveRemoveKey } from '@/utilities';
export default {
  setFlags: (state, data) => {
    recursiveRemoveKey(data, '__typename');
    state.flags = data;
  },
  setPlanFlags: (state, data) => {
    recursiveRemoveKey(data, '__typename');
    state.planFlags = data;
  },
  appendPlanFlag: (state, data) => {
    recursiveRemoveKey(data, '__typename');
    state.planFlags = state.planFlags.concat(data);
  },
  removePlanFlag: (state, id) => {
    state.planFlags = state.planFlags.filter((flag) => flag.id !== id);
  },
  updatePlanFlag: (state, data) => {
    const index = state.planFlags.findIndex((flag) => flag.id === data.id);
    recursiveRemoveKey(data, '__typename');
    const newPlanFlags = [...state.planFlags];
    newPlanFlags[index] = data;
    state.planFlags = newPlanFlags;
  },
};
