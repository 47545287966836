import gql from 'graphql-tag';

export default gql`
  fragment EndOfLifeItem on EndOfLifeItem {
    id
    categoryId
    userId
    data
    fileIds
    createdAt
    updatedAt
  }
`;
